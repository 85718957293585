import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSavedPropertiesCount } from '../../actions/propertyActions';
import { logoutUser } from '../../actions/authActions';
import { Link } from 'react-router-dom';

class UserBar extends Component {
  constructor() {
    super();

    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  componentDidMount() {
    this.props.getSavedPropertiesCount();
  }

  onLogoutClick(e) {
    e.preventDefault();

    this.props.logoutUser();
  }

  render() {
    const { user, isAuthenticated } = this.props.auth;
    const { savedCount } = this.props.properties;

    let guestLinks = (
      <ul className="ml-auto user-nav-links">
        <li className="user-nav-link saved-link">
          <Link to="/saved">
            <i
              className={savedCount > 0 ? 'fas fa-heart green' : 'far fa-heart'}
            />
            <span className="badge badge-light">{savedCount}</span>
          </Link>
        </li>
        <li className="user-nav-link">
          <Link to="/login">Sign In</Link>
        </li>
      </ul>
    );

    let authLinks = (
      <ul className="ml-auto user-nav-links">
        <li className="user-nav-link saved-link">
          <Link to="/saved">
            <i
              className={savedCount > 0 ? 'fas fa-heart green' : 'far fa-heart'}
            />
            <span className="badge badge-light">{savedCount}</span>
          </Link>
        </li>
        <li className="user-nav-link">
          <Link to="/user-panel">User Panel</Link>
        </li>
        {user.admin ? (
          <li className="user-nav-link">
            <Link to="/admin-panel">Admin Panel</Link>
          </li>
        ) : null}
        <li className="user-nav-link">
          {/* <i className="fas fa-user" /> */}
          <button onClick={this.onLogoutClick}>Log Out</button>
        </li>
      </ul>
    );

    return (
      <div className="user-bar">
        {/* this is a user bar */}
        <div className="container">
          <div className="inner-content">
            {isAuthenticated ? authLinks : guestLinks}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { getSavedPropertiesCount, logoutUser }
)(UserBar);
