import React, { Component } from "react";
import { Helmet } from "react-helmet";

// import components
import ContentPage from "./ContentPage";
import signImage from "../../img/shop-logo.jpg";
import studentDesk from "../../img/student-desk.jpg";

class AboutUs extends Component {
  render() {
    const images = [
      {
        src: signImage,
        alt: "Student Digs Shop Sign",
      },
      { src: studentDesk, alt: "Student doign work at a desk" },
    ];

    return (
      <ContentPage heading="About Us" images={images}>
        <Helmet>
          <title>About Us</title>
        </Helmet>

        <div className="text-content">
          <p>
            Here at Student Digs Gloucestershire, we aim to take all the fuss
            out of private accommodation - Developing our properties with the
            modern student in mind endeavouring to provide you with comfort and
            security during your University/ College years. We are part of The
            Markey Group who are a long-established Gloucestershire family
            business that have experience in a range of property development and
            investment services across the UK. We are Gloucestershire’s largest
            independent provider of student accommodation.
          </p>

          <p>
            Not just your Landlord - Our team work together to provide a quality
            service, whilst maintaining all of our properties to high standards.
            to ensure that all of your tenancy needs are catered for including
            all of your bills as part of our ‘Inclusive Living Plan’ – taking
            care of your utilities and internet access, saving you time so that
            you can make the most of university and helping facilitate your
            student life.
          </p>

          <p>
            We own and maintain our properties ourselves, so you know exactly
            who to contact when something goes wrong. We work with a Group of
            businesses, so you know everything is taken care of. With us you can
            have the best of both worlds – the freedom and flexibility of
            private rentals but with the security knowing that we are only a
            phone call or email away. Whether you are looking for a simple
            solution to accommodation for you and your friends or a complete
            alternative to student halls for yourself, our properties can fulfil
            your needs.
          </p>
        </div>
      </ContentPage>
    );
  }
}

export default AboutUs;
