import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editUserDocs } from '../../actions/userActions';

// import components
import Modal from 'react-bootstrap/Modal';

class EditDocs extends Component {
  constructor() {
    super();

    this.state = {
      modalOpen: false,
      tenancyAgreement: false,
      standingOrderMandate: false,
      paymentSchedule: false,
      personalInformation: false,
      dps: false,
      passportPhoto: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { user } = this.props.users;

    this.setState({
      tenancyAgreement: user.completedDocs.tenancyAgreement,
      standingOrderMandate: user.completedDocs.standingOrderMandate,
      paymentSchedule: user.completedDocs.paymentSchedule,
      personalInformation: user.completedDocs.personalInformation,
      dps: user.completedDocs.dps,
      passportPhoto: user.completedDocs.passportPhoto
    });
  }

  handleOpen() {
    this.setState({ modalOpen: true });
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  onChange(e) {
    e.target.value === 'true'
      ? this.setState({ [e.target.name]: true })
      : this.setState({ [e.target.name]: false });
  }

  onSubmit(e) {
    e.preventDefault();
    const { user } = this.props.users;

    const data = {
      tenancyAgreement: this.state.tenancyAgreement,
      standingOrderMandate: this.state.standingOrderMandate,
      paymentSchedule: this.state.paymentSchedule,
      personalInformation: this.state.personalInformation,
      dps: this.state.dps,
      passportPhoto: this.state.passportPhoto
    };

    this.props.editUserDocs(user?._id, data);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.users.user !== nextProps.users.user) {
      this.setState({ modalOpen: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <button className="btn green-button" onClick={this.handleOpen}>
          Update Checklist
        </button>

        <Modal show={this.state.modalOpen} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit document checklist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="field">
                    <h3 className="heading">Tenancy Agreement</h3>
                    <select
                      name="tenancyAgreement"
                      value={this.state.tenancyAgreement}
                      onChange={this.onChange}
                    >
                      <option value="true">Complete</option>
                      <option value="false">Incomplete</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h3 className="heading">Standing Order Mandate</h3>
                    <select
                      name="standingOrderMandate"
                      value={this.state.standingOrderMandate}
                      onChange={this.onChange}
                    >
                      <option value="true">Complete</option>
                      <option value="false">Incomplete</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h3 className="heading">Payment Schedule</h3>
                    <select
                      name="paymentSchedule"
                      value={this.state.paymentSchedule}
                      onChange={this.onChange}
                    >
                      <option value="true">Complete</option>
                      <option value="false">Incomplete</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h3 className="heading">Personal Information</h3>
                    <select
                      name="personalInformation"
                      value={this.state.personalInformation}
                      onChange={this.onChange}
                    >
                      <option value="true">Complete</option>
                      <option value="false">Incomplete</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h3 className="heading">DPS</h3>
                    <select
                      name="dps"
                      value={this.state.dps}
                      onChange={this.onChange}
                    >
                      <option value="true">Complete</option>
                      <option value="false">Incomplete</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h3 className="heading">Passport Photo</h3>
                    <select
                      name="passportPhoto"
                      value={this.state.passportPhoto}
                      onChange={this.onChange}
                    >
                      <option value="true">Complete</option>
                      <option value="false">Incomplete</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn green-button" onClick={this.onSubmit}>
              Submit Changes
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users
});

export default connect(
  mapStateToProps,
  { editUserDocs }
)(EditDocs);
