import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllUsers } from '../../actions/userActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

// import components
import AdminPage from '../admin/AdminPage';
import AddUserModal from './AddUserModal';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

class ManageUsers extends Component {
  componentDidMount() {
    this.props.getAllUsers();
  }

  render() {
    const { users } = this.props.users;

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        minResizeWidth: 10
      },
      {
        Header: 'Email',
        accessor: 'email',
        minResizeWidth: 10
      },
      {
        Header: 'Last Login',
        id: 'last-login',
        className: 'text-center',
        accessor: d => moment(d.lastLogin).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'Date Created',
        id: 'date-created',
        className: 'text-center',
        accessor: d => moment(d.date).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'User ID',
        accessor: '_id',
        id: 'id-main',
        minResizeWidth: 10
      },
      {
        Header: 'Admin',
        id: 'admin',
        className: 'text-center',
        accessor: d => (d.admin ? 'Yes' : 'No'),
        minResizeWidth: 10
      },
      {
        Header: 'Manage',
        className: 'text-center',
        accessor: '_id',
        Cell: d => (
          <Link
            to={`/admin-panel/user/${d.value}`}
            className="badge badge-pill"
          >
            Manage
          </Link>
        ),
        minResizeWidth: 10
      }
    ];

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null && typeof row[id] === 'string') {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }
    };

    const exportHeaders = [
      { label: 'Date Created', key: 'date' },
      { label: 'Name', key: 'name' },
      { label: 'Email', key: 'email' },
      { label: 'Address', key: 'address' },
      { label: 'Phone', key: 'phone' },
      { label: 'ID', key: '_id' },
      { label: 'Last Login', key: 'lastLogin' },
      { label: 'Admin Access', key: 'admin' }
    ];

    return (
      <AdminPage title="Manage Users">
        <p>
          You can sort by column by just selecting the column headers. You can
          also sort by <strong>multiple columns</strong>, hold shift and select
          multiple headings. If you're looking for a specific entry you can
          search inside the white box under the column headings, please note
          this search is <strong>case sensitive.</strong>
        </p>

        <ReactTable
          data={users}
          columns={columns}
          defaultPageSize={10}
          defaultFilterMethod={filterCaseInsensitive}
          filterable
        />

        <div className="footer text-right">
          <CSVLink
            data={users}
            headers={exportHeaders}
            filename={`StudentDigs_users.csv`}
            className="btn green-button mr-3"
          >
            Export as CSV
          </CSVLink>

          <button
            className="btn green-button"
            data-toggle="modal"
            data-target="#addUserModal"
          >
            Add User
          </button>

          <AddUserModal />
        </div>
      </AdminPage>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users
});

export default connect(
  mapStateToProps,
  { getAllUsers }
)(ManageUsers);
