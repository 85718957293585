import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// import components
import AdminNavigation from './AdminNavigation';

class AdminPage extends Component {
  render() {
    return (
      <section className="admin-panel">
        <Helmet>
          <title>Admin Panel</title>
        </Helmet>

        <div className="container">
          <AdminNavigation />
          <div className="panel-content">
            <h1 className="heading">
              {this.props.title ? this.props.title : 'Page Title'}
            </h1>

            <div className="content">{this.props.children}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default AdminPage;
