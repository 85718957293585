import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PropertiesSidebar extends Component {
  render() {
    return (
      <div className="properties-sidebar sticky-top">
        <div className="inner-content">
          <div className="header">
            <h3 className="heading">Get in touch</h3>
          </div>

          <div className="content">
            <p>
              We take all the fuss out of student accommodation. We develop our
              properties with the modern student in mind. We also work with
              approachable landlords to take the hassle out of private student
              rental and provide you with a home from home.
            </p>

            <h4 className="call-us">
              Call us on <a href="tel:01452886186">01452 886186</a>
            </h4>

            <Link to="/contact" className="btn green-button">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertiesSidebar;
