import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateProperty } from '../../actions/propertyActions';
import isEmpty from '../../validation/is-empty';

class EditPropertyModal extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      addressLine1: '',
      addressCity: '',
      addressCounty: '',
      addressPostcode: '',
      slug: '',
      featured: false,
      description: '',
      features: '',
      active: true,
      updated: false,
      park: '',
      nightlife: '',
      shopping: '',
      cityCentre: '',
      busStation: '',
      trainStation: '',
      supermarket: '',
      stadium: '',
      raceCourse: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    if (e.target.name === 'featured') {
      const isTrue = e.target.value === 'true';

      this.setState({ featured: isTrue });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  componentDidMount() {
    const { property } = this.props;

    this.setState({
      name: property.name,
      addressLine1:
        !isEmpty(property.address) && property.address.line1
          ? property.address.line1
          : '',
      addressCity:
        !isEmpty(property.address) && property.address.city
          ? property.address.city
          : '',
      addressCounty:
        !isEmpty(property.address) && property.address.county
          ? property.address.county
          : '',
      addressPostcode:
        !isEmpty(property.address) && property.address.postcode
          ? property.address.postcode
          : '',
      slug: property.slug,
      featured: property.featured,
      description: property.description,
      features: property.features,
      active: property.active,
      park: property.area && property.area.park,
      nightlife: property.area && property.area.nightlife,
      shopping: property.area && property.area.shopping,
      cityCentre: property.area && property.area.cityCentre,
      busStation: property.area && property.area.busStation,
      trainStation: property.area && property.area.trainStation,
      supermarket: property.area && property.area.supermarket,
      stadium: property.area && property.area.stadium,
      raceCourse: property.area && property.area.raceCourse
    });
  }

  componentWillReceiveProps(nextProps) {
    const { property } = this.props;

    if (property.updated !== nextProps.properties.property.updated) {
      this.setState({ updated: true });
      window.$(`#propertyModal`).modal('hide');
    } else {
      this.setState({ updated: false });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { property } = this.props;
    const {
      name,
      addressLine1,
      addressCity,
      addressCounty,
      addressPostcode,
      slug,
      featured,
      description,
      features,
      active,
      park,
      nightlife,
      shopping,
      cityCentre,
      busStation,
      trainStation,
      supermarket,
      stadium,
      raceCourse
    } = this.state;

    this.setState({ updated: false });

    const propertyData = {
      name,
      addressLine1,
      addressCity,
      addressCounty,
      addressPostcode,
      slug,
      featured,
      description,
      features,
      active,
      park,
      nightlife,
      shopping,
      cityCentre,
      busStation,
      trainStation,
      supermarket,
      stadium,
      raceCourse
    };

    this.props.updateProperty(property._id, propertyData);
  }

  render() {
    return (
      <div className="edit-wrapper">
        <button
          type="button"
          className="btn green-button"
          data-toggle="modal"
          data-target="#propertyModal"
        >
          Edit Property Info
        </button>

        <div
          className="modal fade"
          id="propertyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="propertyModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="propertyModalLabel">
                  Edit Property Info
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="edit-section">
                  <h3 className="heading">General Info</h3>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Property Name</h5>
                        <input
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.onChange}
                          placeholder="10 Downing Street"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Page Slug</h5>
                        <input
                          type="text"
                          name="slug"
                          value={this.state.slug}
                          onChange={this.onChange}
                          placeholder="10-downing-street"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field">
                        <h5 className="heading">Property Description</h5>

                        <textarea
                          className="w-100"
                          type="text"
                          name="description"
                          rows="5"
                          value={this.state.description}
                          onChange={this.onChange}
                          placeholder="Modestly sized property, with a couple of rooms..."
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field">
                        <h5 className="heading">Property Features</h5>

                        <p className="description">
                          These are the property features that appear as bullet
                          points on the front end.{' '}
                          <strong>Separate each feature with a comma</strong>,
                          e.g. Close to town, Good night life, Free WiFi
                        </p>

                        <textarea
                          placeholder="Close to town, Good night life, Free WiFi"
                          className="w-100"
                          type="text"
                          name="features"
                          rows="3"
                          value={this.state.features}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="edit-section">
                  <h3 className="heading">Address Info</h3>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Line 1</h5>
                        <input
                          type="text"
                          name="addressLine1"
                          value={this.state.addressLine1}
                          onChange={this.onChange}
                          placeholder="10 Downing Street"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">City</h5>
                        <input
                          type="text"
                          name="addressCity"
                          value={this.state.addressCity}
                          onChange={this.onChange}
                          placeholder="Westminster"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">County</h5>
                        <input
                          type="text"
                          name="addressCounty"
                          value={this.state.addressCounty}
                          onChange={this.onChange}
                          placeholder="London"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Postcode</h5>
                        <input
                          type="text"
                          name="addressPostcode"
                          value={this.state.addressPostcode}
                          onChange={this.onChange}
                          placeholder="SW1A 2AA"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="edit-section">
                  <h3 className="heading">In the area</h3>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Park</h5>
                        <input
                          type="text"
                          name="park"
                          value={this.state.park}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Nightlife</h5>
                        <input
                          type="text"
                          name="nightlife"
                          value={this.state.nightlife}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Shopping</h5>
                        <input
                          type="text"
                          name="shopping"
                          value={this.state.shopping}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Bus Station</h5>
                        <input
                          type="text"
                          name="busStation"
                          value={this.state.busStation}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Train Station</h5>
                        <input
                          type="text"
                          name="trainStation"
                          value={this.state.trainStation}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Supermarket</h5>
                        <input
                          type="text"
                          name="supermarket"
                          value={this.state.supermarket}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Stadium</h5>
                        <input
                          type="text"
                          name="stadium"
                          value={this.state.stadium}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Race Course</h5>
                        <input
                          type="text"
                          name="raceCourse"
                          value={this.state.raceCourse}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">City Centre</h5>
                        <input
                          type="text"
                          name="cityCentre"
                          value={this.state.cityCentre}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="edit-section">
                  <h3 className="heading">Other Info</h3>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="field">
                        <h5 className="heading">Featured?</h5>
                        <p className="description">
                          Please note, you should only have three featured
                          properties (only the first three featured properties
                          will appear). These appear on the homepage.
                        </p>
                        <select
                          name="featured"
                          value={this.state.featured}
                          onChange={this.onChange}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field">
                        <h5 className="heading">Active?</h5>
                        <p className="description">
                          If you want to hide this property from the
                          accomodation list, set active to false.
                        </p>
                        <select
                          name="active"
                          value={this.state.active}
                          onChange={this.onChange}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.onSubmit}
                  className="btn green-button"
                >
                  Update Property
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { updateProperty }
)(EditPropertyModal);
