import {
  GET_ALL_TENANTS,
  TENANCY_LOADING,
  GET_ACTIVE_TENANCIES
} from '../actions/types';

const initialState = {
  tenants: [],
  tenancies: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TENANTS:
      return {
        ...state,
        tenants: action.payload,
        loading: false
      };
    case GET_ACTIVE_TENANCIES:
      return {
        ...state,
        tenancies: action.payload,
        loading: false
      };
    case TENANCY_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
