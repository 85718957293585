import axios from 'axios';

// import types
import {
  GET_ALL_USERS,
  GET_USER,
  USERS_LOADING,
  DELETE_USER,
  GET_ERRORS,
  EDIT_USER,
  EDIT_USER_DOCS,
  CLEAR_ERRORS,
  CLEAR_USER
} from './types';

// get all users
export const getAllUsers = () => dispatch => {
  dispatch(setUsersLoading());
  axios
    .get('/api/auth/users')
    .then(res => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_USERS,
        payload: []
      });
    });
};

// get a single user (admin)
export const getUser = userId => dispatch => {
  dispatch(setUsersLoading());

  axios
    .get(`/api/auth/user/${userId}`)
    .then(res => {
      dispatch({
        type: GET_USER,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_USER,
        payload: {}
      });
    });
};

// edit a user
export const editUser = (userID, data) => dispatch => {
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(`/api/auth/edit-user/${userID}`, data)
    .then(res =>
      dispatch({
        type: EDIT_USER,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// edit a users docs
export const editUserDocs = (userID, data) => dispatch => {
  axios
    .post(`/api/auth/docs/${userID}`, data)
    .then(res => {
      dispatch({
        type: EDIT_USER_DOCS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// delete a user
export const deleteUser = userID => dispatch => {
  if (window.confirm('Are you sure you want to delete this user?')) {
    axios
      .delete(`/api/auth/delete/${userID}`)
      .then(res => {
        dispatch({
          type: DELETE_USER,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

export const setUsersLoading = () => {
  return {
    type: USERS_LOADING
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER
  };
};
