import React, { Component } from 'react';

// import components
import Hero from '../common/Hero';

class ContentPage extends Component {
  render() {
    const { images, heading, children } = this.props;

    return (
      <main className="content-page">
        <Hero />

        <section className="main-content">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                {heading ? (
                  <div className="header">
                    <h1 className="heading">{heading}</h1>
                  </div>
                ) : null}

                {children}
              </div>

              {images && (
                <div className="col-md-4">
                  <div className="images-container">
                    {images
                      ? images.map((image, index) => (
                          <img src={image.src} alt={image.alt} key={index} />
                        ))
                      : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ContentPage;
