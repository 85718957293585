import axios from 'axios';

import {
  GET_UNPAID_PAYMENTS,
  GET_PAID_PAYMENTS,
  GET_PROCESSING_PAYMENTS,
  PAYMENTS_LOADING,
  GET_ALL_USERS_PAYMENTS,
  CREATE_NEW_PAYMENT,
  GET_ERRORS,
  CLEAR_ERRORS,
  EDIT_PAYMENT,
  DELETE_PAYMENT,
  GET_ALL_PAYMENTS,
  GET_SINGLE_PAYMENT,
  CLEAR_SINGLE_PAYMENT,
  GET_PAYMENTS_SUMMARY
} from './types';

// get all unpaid payments
export const getUnpaidPayments = () => dispatch => {
  dispatch(setPaymentsLoading());

  axios
    .get(`/api/payments/current/unpaid`)
    .then(res => {
      dispatch({
        type: GET_UNPAID_PAYMENTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_UNPAID_PAYMENTS,
        payload: []
      });
    });
};

// get all paid payments
export const getPaidPayments = () => dispatch => {
  dispatch(setPaymentsLoading());

  axios
    .get(`/api/payments/current/paid`)
    .then(res => {
      dispatch({
        type: GET_PAID_PAYMENTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PAID_PAYMENTS,
        payload: []
      });
    });
};

// get all processing payments
export const getProcessingPayments = () => dispatch => {
  dispatch(setPaymentsLoading());

  axios
    .get(`/api/payments/current/processing`)
    .then(res => {
      dispatch({
        type: GET_PROCESSING_PAYMENTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PROCESSING_PAYMENTS,
        payload: []
      });
    });
};

// get all users payments
export const getAllUsersPayments = userID => dispatch => {
  dispatch(setPaymentsLoading());

  axios
    .get(`/api/payments/all/${userID}`)
    .then(res => {
      dispatch({
        type: GET_ALL_USERS_PAYMENTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_USERS_PAYMENTS,
        payload: []
      });
    });
};

// get all payments
export const getAllPayments = () => dispatch => {
  dispatch(setPaymentsLoading());

  axios
    .get('/api/payments/all')
    .then(res => {
      dispatch({
        type: GET_ALL_PAYMENTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_PAYMENTS,
        payload: []
      });
    });
};

// create a new payment
export const createNewPayment = data => dispatch => {
  dispatch({ type: CLEAR_ERRORS });

  axios
    .post('/api/payments/new', data)
    .then(res => {
      dispatch({
        type: CREATE_NEW_PAYMENT,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// edit existing payment
export const editPayment = (paymentID, data) => dispatch => {
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(`/api/payments/edit/${paymentID}`, data)
    .then(res => {
      dispatch({
        type: EDIT_PAYMENT,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// delete payment
export const deletePayment = paymentID => dispatch => {
  if (window.confirm('Are you sure you want to delete this payment?')) {
    axios
      .delete(`/api/payments/delete/${paymentID}`)
      .then(res => {
        dispatch({
          type: DELETE_PAYMENT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// get single payment
export const getSinglePayment = paymentID => dispatch => {
  dispatch(setPaymentsLoading());

  axios
    .get(`/api/payments/single/${paymentID}`)
    .then(res => {
      dispatch({
        type: GET_SINGLE_PAYMENT,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_SINGLE_PAYMENT,
        payload: {}
      });
    });
};

// get payments summarry
export const getPaymentsSummary = year => dispatch => {
  dispatch(setPaymentsLoading());

  axios
    .get(`/api/payments/summary/${year}`)
    .then(res => {
      dispatch({ type: GET_PAYMENTS_SUMMARY, payload: res.data });
    })
    .catch(err => {
      dispatch({ type: GET_PAYMENTS_SUMMARY, payload: [] });
    });
};

// clear single payment
export const clearSinglePayment = () => {
  return {
    type: CLEAR_SINGLE_PAYMENT
  };
};

export const setPaymentsLoading = () => {
  return {
    type: PAYMENTS_LOADING
  };
};
