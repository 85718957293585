import React from "react";
import isEmpty from "../../validation/is-empty";
import axios from "axios";

export default function FlatImages({ flat }) {
  const [images, setImages] = React.useState([]);
  const [uploadError, setUploadError] = React.useState("");
  const [deleteError, setDeleteError] = React.useState("");
  const [uploading, setUploading] = React.useState(false);

  const onReset = () => {
    setImages(!isEmpty(flat.images) ? [...flat.images] : []);
    window.$(`#editFlatImages${flat._id}`).modal("hide");
  };

  React.useEffect(() => {
    setImages(!isEmpty(flat.images) ? flat.images : []);
  }, []);

  const captionChange = (e, index) => {
    e.preventDefault();

    const temp = images;
    temp[index].caption = e.target.value;
    setImages([...temp]);
  };

  const onUpdate = (e) => {
    e.preventDefault();

    axios
      .post(`/api/flats/flat-images/${flat._id}`, { images: images })
      .then((res) => {
        window.$(`#editFlatImages${flat._id}`).modal("hide");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUploadInputChange = (e) => {
    setUploadError("");
    if (!e.target.files) {
      return null;
    }

    const selectedFile = e.target.files[0];

    const img = new FormData();

    img.append("image", selectedFile);

    setUploading(true);

    axios
      .post(`/api/media/flat-image/${flat._id}`, img)
      .then((res) => {
        const key = res.data.image.key;
        setUploading(false);
        setImages((prev) => [...prev, { key: key }]);
      })
      .catch((err) => {
        setUploading(false);
        setUploadError(err.response.data.upload);
      });
  };

  const deleteImage = (e, key) => {
    e.preventDefault();

    axios
      .delete(`/api/media/flat-image/${flat._id}/${key}`)
      .then((res) => {
        setImages((prev) => {
          const removeIndex = prev
            .map((item) => item.key.toString())
            .indexOf(key.toString());

          if (removeIndex !== -1) {
            prev.splice(removeIndex, 1);

            return [...prev];
          } else {
            return [...prev];
          }
        });
      })
      .catch((err) => {
        setDeleteError(err.message);
      });
  };

  const array_move = (old_index, new_index) => {
    const oldImages = images;
    console.log(images);

    while (old_index < 0) {
      old_index += oldImages.length;
    }
    while (new_index < 0) {
      new_index += oldImages.length;
    }
    if (new_index >= oldImages.length) {
      var k = new_index - oldImages.length + 1;
      while (k--) {
        oldImages.push(undefined);
      }
    }
    oldImages.splice(new_index, 0, oldImages.splice(old_index, 1)[0]);
    console.log(oldImages);
    return setImages([...oldImages]); // for testing purposes
  };

  return (
    <>
      <button
        className="btn green-button ml-1"
        data-toggle="modal"
        data-target={`#editFlatImages${flat._id}`}
      >
        Edit Flat Images ({images.length})
      </button>

      <div
        className="modal fade edit-flat-modal"
        id={`editFlatImages${flat._id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editFlatImagesModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editFlatImagesModal">
                Edit flat images
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {!isEmpty(images) && images.length > 0 && (
                <>
                  <h3 className="section-heading">Current Images</h3>

                  <ul className="image-list edit-section">
                    {images.map((image, index) => {
                      if (isEmpty(image)) {
                        return null;
                      }

                      return (
                        <li key={index}>
                          <div className="move-controls">
                            <button
                              disabled={index === 0}
                              onClick={(e) => {
                                e.preventDefault();
                                array_move(index, index - 1);
                              }}
                            >
                              <i className="fa fa-chevron-up"></i>
                            </button>

                            <button
                              disabled={index === images.length - 1}
                              onClick={(e) => {
                                e.preventDefault();
                                array_move(index, index + 1);
                              }}
                            >
                              <i className="fa fa-chevron-down"></i>
                            </button>
                          </div>

                          <div className="image-wrapper">
                            <img src={`/api/media/image/${image.key}`} />
                            <button
                              className="delete-button"
                              onClick={(e) => deleteImage(e, image.key)}
                            >
                              <span className="fa-stack">
                                <i className="fas fa-circle fa-stack-2x" />
                                <i className="fas fa-times fa-stack-1x fa-inverse" />
                              </span>
                            </button>
                          </div>
                          <div className="field">
                            <label>Caption</label>

                            <input
                              type="text"
                              value={image.caption}
                              onChange={(e) => captionChange(e, index)}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}

              <h3 className="section-heading">Add Another Image</h3>

              {!uploading && (
                <input
                  type="file"
                  className="input-upload"
                  onChange={(e) => onUploadInputChange(e, true)}
                  accept="image/png, image/jpeg"
                />
              )}

              {uploading && (
                <p className="uploading-message">
                  <i className="fa fa-spin fa-circle-notch"></i> Uploading...
                </p>
              )}

              {!isEmpty(uploadError) && (
                <p className="error-message">{uploadError}</p>
              )}
            </div>

            <div className="modal-footer">
              <button className="btn red-button" onClick={onReset}>
                Cancel and Close
              </button>
              <button
                type="button"
                className="btn green-button"
                onClick={onUpdate}
              >
                Update & Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
