import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

// import components
import Spinner from '../common/Spinner';
import SaveProperty from './SaveProperty';

class RightSidebar extends Component {
  getShortDescription() {
    const { description } = this.props.property;
    let newString;

    if (description) {
      newString = description
        .split(/\s+/)
        .slice(0, 20)
        .join(' ');
    }

    return newString + '...';
  }

  componentDidMount() {
    this.attachTooltip();
  }

  componentDidUpdate() {
    this.attachTooltip();
  }

  attachTooltip() {
    window.$(this.refs.tooltips).tooltip();
    // Or for React <0.14 -
    // $(this.refs.data.getDOMNode()).tooltip();
  }

  render() {
    const { property, loading } = this.props;
    let introContent;

    if (loading) {
      introContent = (
        <div className="sidebar-section description">
          <Spinner />
        </div>
      );
    } else {
      introContent = (
        <div className="sidebar-section description">
          <h3 className="heading">
            Brief Intro {property.name ? `to ${property.name}` : null}
          </h3>
          <p>{this.getShortDescription()}</p>
          <a href="#full-description">
            Read more <span>&rarr;</span>
          </a>
        </div>
      );
    }

    return (
      <div className="right-sidebar sticky-top">
        {introContent}

        <div className="sidebar-section description">
          <h3 className="heading">Corporate Lettings</h3>
          <p className="mb-0">
            We can provide solutons for corporate lettings. Get in{' '}
            <Link to="/contact">contact</Link> with us and see what we can
            offer.
          </p>
        </div>

        <div className="sidebar-section cta">
          <button
            type="button"
            className="btn green-button"
            data-toggle="modal"
            data-target="#enquiryModal"
          >
            Request Details
          </button>
          <h3 className="call">
            Call us on <a href="tel: 01452886186">01452&nbsp;886186</a>
          </h3>
        </div>

        <div className="sidebar-section button">
          <SaveProperty />
        </div>

        <div className="sidebar-section button d-none d-md-block">
          <button onClick={() => window.print()} className="full">
            Print
          </button>
        </div>

        <div className="sidebar-section button">
          <CopyToClipboard text={window.location.href}>
            <button
              className="full"
              data-toggle="tooltip"
              ref="tooltips"
              data-placement="top"
              data-trigger="focus"
              title="Copied!"
            >
              Copy Link
            </button>
          </CopyToClipboard>
        </div>
      </div>
    );
  }
}

export default RightSidebar;
