import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      password: '',
      password2: '',
      success: false,
      loading: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true, errors: {} });

    const userID = this.props.match.params.userID;
    const token = this.props.match.params.token;

    const data = {
      password: this.state.password,
      password2: this.state.password2
    };

    axios
      .post(`/api/auth/reset-password/${userID}/${token}`, data)
      .then(res => {
        this.setState({ loading: false });
        if (res.data.success) this.setState({ success: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  }

  render() {
    const { success, loading, errors } = this.state;
    let resetContent;

    if (!success) {
      resetContent = (
        <div className="login-box">
          <div className="header">
            <h1 className="heading">Set your Student Digs password</h1>
          </div>

          <div className="content">
            {errors.resetPassword ? (
              <p className="text-danger pb-3">{errors.resetPassword}</p>
            ) : null}

            <form onSubmit={this.onSubmit}>
              <div className="field-wrapper">
                <div className="field">
                  <i className="fas fa-lock" />
                  <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    placeholder="New Password"
                  />
                </div>
                {errors.password ? (
                  <p className="text-danger">{errors.password}</p>
                ) : null}
              </div>

              <div className="field-wrapper">
                <div className="field">
                  <i className="fas fa-lock" />
                  <input
                    type="password"
                    name="password2"
                    value={this.state.password2}
                    onChange={this.onChange}
                    placeholder="Confirm Password"
                  />
                </div>
                {errors.password2 ? (
                  <p className="text-danger">{errors.password2}</p>
                ) : null}
              </div>

              <button type="submit" className="btn green-button">
                {loading ? (
                  <i className="fas fa-circle-notch fa-spin" />
                ) : (
                  'Confirm'
                )}
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      resetContent = (
        <div className="login-box">
          <div className="header mb-0">
            <h1 className="heading">You've set your new password!</h1>
            <p>
              You'll now be able to log in with your new password and the
              password reset link you used will no longer be active!
            </p>
          </div>

          <div className="content">
            <Link to="/login" className="btn green-button">
              Log In
            </Link>
          </div>
        </div>
      );
    }

    return (
      <main className="login-page">
        <Helmet>
          <title>Set Password</title>
        </Helmet>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10 mx-auto">{resetContent}</div>
          </div>
        </div>
      </main>
    );
  }
}

export default ResetPassword;
