import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllTenants } from '../../actions/tenancyActions';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

// import components
import AdminPage from '../admin/AdminPage';

class TenancyPanel extends Component {
  componentDidMount() {
    this.props.getAllTenants();
  }

  render() {
    const { tenants } = this.props.tenancy;

    const columns = [
      {
        Header: 'User',
        accessor: 'user.name',
        minResizeWidth: 10
      },
      {
        Header: 'Date Created',
        id: 'date-created',
        accessor: d => moment(d.date).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'Room',
        accessor: 'room.name',
        minResizeWidth: 10
      },
      {
        Header: 'Flat',
        accessor: 'room.flat.name',
        minResizeWidth: 10
      },
      {
        Header: 'Property',
        accessor: 'room.property.name',
        minResizeWidth: 10
      },
      {
        Header: 'Start Date',
        id: 'start-date',
        accessor: d => moment(d.startDate).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'End Date',
        id: 'end-date',
        accessor: d => moment(d.endDate).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'Value',
        accessor: 'rentalValue',
        minResizeWidth: 10,
        Cell: props => `£${props.value}`
      },
      {
        Header: 'Active?',
        accessor: 'active',
        className: 'text-center',
        minResizeWidth: 10,
        Cell: props =>
          props.value ? (
            <i className="fas fa-check text-success" />
          ) : (
            <i className="fas fa-times text-danger" />
          )
      },
      {
        Header: 'Edit User',
        className: 'text-center',
        accessor: 'user._id',
        Cell: d => (
          <Link
            to={`/admin-panel/user/${d.value}`}
            className="badge badge-pill"
          >
            Edit User
          </Link>
        ),
        minResizeWidth: 10
      }
    ];

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null && typeof row[id] === 'string') {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }
    };

    const exportHeaders = [
      { label: 'Date Created', key: 'date' },
      { label: 'Name', key: 'user.name' },
      { label: 'User Email', key: 'user.email' },
      { label: 'User ID', key: 'user._id' },
      { label: 'Property', key: 'room.property.name' },
      { label: 'Flat', key: 'room.flat.name' },
      { label: 'Room', key: 'room.name' },
      { label: 'Start Date', key: 'startDate' },
      { label: 'End Date', key: 'endDate' },
      { label: 'Rental Value', key: 'rentalValue' },
      { label: 'Active', key: 'active' }
    ];

    return (
      <AdminPage title="Manage Tenants">
        <p>
          You can sort by column by just selecting the column headers. You can
          also sort by <strong>multiple columns</strong>, hold shift and select
          multiple headings. If you're looking for a specific entry you can
          search inside the white box under the column headings, please note
          this search is <strong>case sensitive.</strong>
        </p>

        <p>
          <strong>
            To create a new tenancy or manage a tenancy you must do this from
            the property page, this page is simply to view every tenant entry.
          </strong>
        </p>

        {tenants ? (
          <ReactTable
            data={tenants}
            columns={columns}
            defaultPageSize={10}
            defaultFilterMethod={filterCaseInsensitive}
            filterable
          />
        ) : null}

        {tenants ? (
          <div className="page-footer text-right mt-3">
            <CSVLink
              data={tenants}
              headers={exportHeaders}
              filename={`StudentDigs_tenants.csv`}
              className="btn green-button"
            >
              Export as CSV
            </CSVLink>
          </div>
        ) : null}
      </AdminPage>
    );
  }
}

const mapStateToProps = state => ({
  tenancy: state.tenancy
});

export default connect(
  mapStateToProps,
  { getAllTenants }
)(TenancyPanel);
