import {
  GET_FEATURED_PROPERTIES,
  PROPERTIES_LOADING,
  GET_ALL_PROPERTIES,
  ADMIN_ALL_PROPERTIES,
  GET_PROPERTY,
  UPDATE_PROPERTY,
  ADD_ROOM,
  UPLOAD_IMAGE,
  DELETE_IMAGE,
  ADD_FLAT,
  EDIT_FLAT,
  EDIT_ROOM,
  DELETE_FLAT,
  DELETE_ROOM,
  CLEAR_PROPERTIES,
  ADD_TENANT,
  REMOVE_TENANT,
  ADD_PROPERTY,
  MOVE_FUTURE_TENANT,
  GET_SAVED_PROPERTIES,
  GET_SAVED_PROPERTIES_COUNT,
  SAVED_PROPERTIES_LOADING,
  SINGLE_PROPERTY_LOADING,
} from "../actions/types";

const initialState = {
  featured: [],
  properties: [],
  property: {},
  saved: [],
  savedCount: 0,
  loading: false,
  savedLoading: false,
  singleLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FEATURED_PROPERTIES:
      return {
        ...state,
        featured: action.payload,
        loading: false,
      };
    case GET_ALL_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        loading: false,
      };
    case ADMIN_ALL_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        loading: false,
      };
    case CLEAR_PROPERTIES:
      return {
        ...state,
        properties: [],
      };
    case ADD_PROPERTY:
      return {
        ...state,
        properties: [action.payload, ...state.properties],
      };
    case GET_PROPERTY:
      return {
        ...state,
        property: action.payload,
        singleLoading: false,
      };
    case UPDATE_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case UPLOAD_IMAGE:
      return {
        ...state,
        property: action.payload,
      };
    case DELETE_IMAGE:
      return {
        ...state,
        property: action.payload,
      };
    case ADD_ROOM:
      return {
        ...state,
        property: action.payload,
      };
    case ADD_FLAT:
      return {
        ...state,
        property: action.payload,
      };
    case EDIT_FLAT:
      return {
        ...state,
        property: action.payload,
      };
    case EDIT_ROOM:
      return {
        ...state,
        property: action.payload,
      };
    case DELETE_FLAT:
      return {
        ...state,
        property: action.payload,
      };
    case DELETE_ROOM:
      return {
        ...state,
        property: action.payload,
      };
    case ADD_TENANT:
      return {
        ...state,
        property: action.payload,
      };
    case REMOVE_TENANT:
      return {
        ...state,
        property: action.payload,
      };
    case MOVE_FUTURE_TENANT:
      return {
        ...state,
        property: action.payload,
      };
    case GET_SAVED_PROPERTIES:
      return {
        ...state,
        saved: action.payload,
        savedLoading: false,
      };
    case GET_SAVED_PROPERTIES_COUNT:
      return {
        ...state,
        savedCount: action.payload,
      };
    case PROPERTIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SAVED_PROPERTIES_LOADING:
      return {
        ...state,
        savedLoading: true,
      };
    case SINGLE_PROPERTY_LOADING:
      return {
        ...state,
        singleLoading: true,
      };
    default:
      return state;
  }
}
