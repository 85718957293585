import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllEnquiries } from "../../actions/enquiryActions";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import isEmpty from "../../validation/is-empty";

// import components
import AdminPage from "../admin/AdminPage";

class ManageEnquiries extends Component {
  componentDidMount() {
    this.props.getAllEnquiries();
  }

  render() {
    const { enquiries } = this.props.enquiries;

    enquiries.map((enquiry, index) => {
      if (isEmpty(enquiry.property)) {
        enquiries[index].property = { _id: "", name: "" };
      }

      if (isEmpty(enquiry.room)) {
        enquiries[index].room = {
          _id: "",
          name: "",
          flat: { _id: "", name: "" },
        };
      }
    });

    const columns = [
      {
        Header: "Date",
        id: "enquiry-date",
        accessor: (d) => moment(d.date).format("DD/MM/YYYY"),
        minResizeWidth: 10,
      },
      {
        Header: "Name",
        accessor: "name",
        minResizeWidth: 10,
      },
      {
        Header: "Email",
        accessor: "email",
        minResizeWidth: 10,
      },
      {
        Header: "Property",
        id: "property-name",
        accessor: (d) => (d.property ? `${d.property.name}` : "-"),
        minResizeWidth: 10,
      },
      {
        Header: "Room",
        id: "room-name",
        accessor: (d) =>
          !isEmpty(d?.room?.name)
            ? d?.room?.flat
              ? `${d?.room?.flat?.name}, ${d?.room?.name}`
              : d?.room?.name
            : "-",
        minResizeWidth: 10,
      },
      {
        Header: "Status",
        accessor: "status",
        minResizeWidth: 10,
      },
      {
        Header: "Manage",
        accessor: "_id",
        className: "text-center",
        Cell: (e) => (
          <Link
            to={`/admin-panel/enquiry/${e.value}`}
            className="badge badge-pill"
          >
            View
          </Link>
        ),
        minResizeWidth: 10,
      },
    ];

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null && typeof row[id] === "string") {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }
    };

    const exportHeaders = [
      { label: "Date Created", key: "date" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      {
        label: "Property",
        key: "property.name",
      },
      { label: "Flat", key: "room.flat.name" },
      { label: "Room", key: "room.name" },
      { label: "Message", key: "message" },
      { label: "Status", key: "status" },
    ];

    return (
      <AdminPage title="Manage Enquiries">
        <p>
          You can sort by column by just selecting the column headers. You can
          also sort by <strong>multiple columns</strong>, hold shift and select
          multiple headings. If you're looking for a specific entry you can
          search inside the white box under the column headings, please note
          this search is <strong>case sensitive.</strong>
        </p>

        {enquiries ? (
          <ReactTable
            data={enquiries}
            columns={columns}
            defaultPageSize={10}
            defaultFilterMethod={filterCaseInsensitive}
            filterable
          />
        ) : null}

        {enquiries ? (
          <div className="page-footer text-right mt-3">
            <CSVLink
              data={enquiries}
              headers={exportHeaders}
              filename={`StudentDigs_enquiries.csv`}
              className="btn green-button"
            >
              Export as CSV
            </CSVLink>
          </div>
        ) : null}
      </AdminPage>
    );
  }
}

const mapStateToProps = (state) => ({
  enquiries: state.enquiries,
});

export default connect(mapStateToProps, { getAllEnquiries })(ManageEnquiries);
