import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// import components
import Properties from '../properties/Properties';

class NotFound extends Component {
  render() {
    return (
      <div className="404 not-found-page">
        <Helmet>
          <title>404</title>
        </Helmet>

        <div className="content">
          <div className="container">
            <h1 className="heading">404</h1>
            <p>
              Sorry, we couldn't find what you were looking for. Check out our
              accommodation though, it's pretty great...
            </p>
          </div>
        </div>

        <Properties filterHidden={true} />
      </div>
    );
  }
}

export default NotFound;
