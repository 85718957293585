import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createNewPayment } from '../../actions/paymentActions';

// import components
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class CreatePayment extends Component {
  constructor() {
    super();

    this.state = {
      title: '',
      description: '',
      reference: '',
      amount: '',
      dueDate: undefined
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onDateChange(date) {
    this.setState({ dueDate: date });
  }

  onSubmit(e) {
    e.preventDefault();

    const data = {
      title: this.state.title,
      description: this.state.description,
      reference: this.state.reference,
      amount: this.state.amount,
      dueDate: this.state.dueDate,
      user: this?.props?.users?.user?._id
    };

    // submit payment
    this.props.createNewPayment(data);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.payments.all !== nextProps.payments.all) {
      window.$('#createPaymentModal').modal('hide');
      this.setState({
        title: '',
        description: '',
        reference: '',
        amount: '',
        dueDate: undefined
      });
    }
  }

  render() {
    const { errors } = this.props;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn green-button"
          data-toggle="modal"
          data-target="#createPaymentModal"
        >
          Create Payment +
        </button>

        <div
          className="modal fade enquiry-modal"
          id="createPaymentModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createPaymentModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create payment modal
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="edit-section">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Payment Title *</h5>
                        <input
                          type="text"
                          name="title"
                          value={this.state.title}
                          onChange={this.onChange}
                          placeholder="Monthly Rent"
                        />

                        {errors.title && (
                          <small className="text-danger">{errors.title}</small>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Reference *</h5>

                        <input
                          type="text"
                          name="reference"
                          value={this.state.reference}
                          onChange={this.onChange}
                          placeholder="NAME/RENT5"
                        />

                        {errors.reference && (
                          <small className="text-danger">
                            {errors.reference}
                          </small>
                        )}

                        <p className="description">
                          Every reference must be unique.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field">
                        <h5 className="heading">Description</h5>

                        <textarea
                          type="text"
                          name="description"
                          value={this.state.description}
                          onChange={this.onChange}
                          placeholder="Rent to cover June - August"
                        />

                        {errors.description && (
                          <small className="text-danger">
                            {errors.description}
                          </small>
                        )}

                        <p className="description">
                          Optional payment description
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Amount *</h5>

                        <input
                          placeholder="578"
                          type="text"
                          name="amount"
                          value={this.state.amount}
                          onChange={this.onChange}
                        />

                        {errors.amount && (
                          <small className="text-danger">{errors.amount}</small>
                        )}

                        <p className="description">
                          Amount in pounds, do <strong>not</strong> add the £
                          symbol.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Due Date</h5>

                        <DatePicker
                          selected={this.state.dueDate}
                          onChange={this.onDateChange}
                          dateFormat="dd/MM/yyyy"
                        />

                        {errors.dueDate && (
                          <small className="text-danger">
                            {errors.dueDate}
                          </small>
                        )}

                        <p className="description">
                          This can be left blank if there is no due date.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="submit"
                  onClick={this.onSubmit}
                  className="btn green-button"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  payments: state.payments,
  errors: state.errors,
  users: state.users
});

export default connect(
  mapStateToProps,
  { createNewPayment }
)(CreatePayment);
