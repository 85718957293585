import React, { Component } from 'react';
import isEmpty from '../../validation/is-empty';

// import components
import Spinner from '../common/Spinner';
import FlatList from './FlatList';
import AreaInfo from './AreaInfo';

class DescriptionTab extends Component {
  constructor() {
    super();

    this.state = {
      features: []
    };
  }

  render() {
    const { property, loading } = this.props;
    let descriptionContent,
      features = [];

    if (loading || isEmpty(property)) {
      descriptionContent = (
        <div
          className="tab-pane fade show active"
          id="description"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <Spinner />
        </div>
      );
    } else {
      features = property.features.split(', ');

      descriptionContent = (
        <div
          className="tab-pane fade show active"
          id="description"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          {property.area && <AreaInfo area={property.area} />}

          {features.length > 0 ? (
            <div className="key-features">
              <h3 className="heading">Key features</h3>

              <div className="row no-gutters">
                {features.map(feature => (
                  <div className="col-md-6 feature" key={feature}>
                    <div className="diamond-icon" />
                    <p>{feature}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          <div className="description-wrapper" id="full-description">
            <h3 className="heading">Full description</h3>

            <p>{property.description}</p>
          </div>

          <FlatList />
        </div>
      );
    }
    return descriptionContent;
  }
}

export default DescriptionTab;
