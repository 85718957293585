import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser, clearUser } from '../../actions/userActions';
import Moment from 'react-moment';

// import components
import AdminPage from '../admin/AdminPage';
import SetPassword from './SetPassword';
import UserResources from './UserResources';
import UserTenancies from './UserTenancies';
import UserPayments from './UserPayments';
import DeleteUser from './DeleteUser';
import EditUser from './EditUser';
import UserDocs from './UserDocs';
import Spinner from '../common/Spinner';

class ManageUser extends Component {
  componentDidMount() {
    this.props.getUser(this.props.match.params.userId);

    this.setState({ currentRoomLoading: true, futureRoomLoading: true });
  }

  componentWillUnmount() {
    this.props.clearUser();
  }

  render() {
    const { user } = this.props.users;
    const { loading } = this.props.users;

    return !loading ? (
      <AdminPage title={user?.name ? user?.name : 'No user found'}>
        <div className="admin-section">
          <div className="row">
            <div className="col-md-4">
              <div className="section-info">
                <h3 className="heading">User Info</h3>
                <p>
                  This is the users general information which can be easily
                  edited
                </p>
              </div>
            </div>

            <div className="col-md-8">
              <div className="section-content">
                <div className="row">
                  {user?.name ? (
                    <React.Fragment>
                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>User ID</h4>
                          <p>{user?._id}</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>Name</h4>
                          <p>{user?.name}</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>Email</h4>
                          <a href={`mailto:${user?.email}`}>{user?.email}</a>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>Address</h4>
                          <p>{user?.address ? user?.address : '-'}</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>Phone</h4>
                          <p>{user?.phone ? user?.phone : '-'}</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>Last Login</h4>
                          <p>
                            {user?.lastLogin ? (
                              <Moment format="DD/MM/YYYY">
                                {user?.lastLogin}
                              </Moment>
                            ) : (
                              '-'
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>Date Created</h4>
                          <p>
                            <Moment format="DD/MM/YYYY">
                              {user.lastLogin}
                            </Moment>
                          </p>
                        </div>
                      </div>

                      <div className="col-md-12">
                        {user?._id && <EditUser />}
                        {user?._id && <SetPassword userID={user?._id} />}
                      </div>
                    </React.Fragment>
                  ) : (
                    <p className="mb-0">No user found with this ID.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {user?._id && <UserTenancies userID={user?._id} />}
        {user?._id && <UserResources user={user} />}
        {user?._id && <UserDocs user={user} />}
        {user?._id && <UserPayments user={user} />}
        {user?._id && <DeleteUser user={user} />}
      </AdminPage>
    ) : (
      <AdminPage>
        <Spinner />
      </AdminPage>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users
});

export default connect(
  mapStateToProps,
  { getUser, clearUser }
)(ManageUser);
