import React, { Component } from 'react';
import isEmpty from '../../validation/is-empty';
import { Redirect } from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import axios from 'axios';

class PropertyFilter extends Component {
  constructor() {
    super();

    this.state = {
      location: '',
      rooms: 0,
      price: { min: 90, max: 130 },
      minPrice: 90,
      maxPrice: 130,
      submit: false,
      queryString: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    axios.get('/api/rooms/price-limits').then(res => {
      this.setState({
        price: { min: res.data.min, max: res.data.max },
        minPrice: res.data.min,
        maxPrice: res.data.max
      });
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    const { location, rooms, price } = this.state;
    let tempQuery = '?';

    if (!isEmpty(location)) {
      tempQuery.length > 1
        ? (tempQuery += '&location=' + location)
        : (tempQuery += 'location=' + location);
    }

    if (rooms !== 0) {
      tempQuery.length > 1
        ? (tempQuery += '&rooms=' + rooms)
        : (tempQuery += 'rooms=' + rooms);
    }

    tempQuery.length > 1
      ? (tempQuery += `&min=${price.min}&max=${price.max}`)
      : (tempQuery += `min=${price.min}&max=${price.max}`);

    this.setState({ submit: true, queryString: tempQuery });
  }

  render() {
    const { submit, queryString } = this.state;

    if (submit) {
      return <Redirect to={`/accommodation${queryString}`} />;
    }

    return (
      <section className="property-filter">
        <div className="container">
          <div className="filter">
            <h1 className="heading">Let's find your new digs</h1>
            <h3 className="subheading">
              Search accommodation to rent in Cheltenham or Gloucester
            </h3>

            <div className="criteria">
              <div className="row no-gutters">
                <div className="col-md field">
                  <label htmlFor="location-filter">Location</label>
                  <select
                    name="location"
                    value={this.state.location}
                    onChange={this.onChange}
                    id="location-filter"
                  >
                    <option value="">Any</option>
                    <option value="Gloucester">Gloucester</option>
                    <option value="Cheltenham">Cheltenham</option>
                  </select>
                </div>

                <div className="col-md field">
                  <label htmlFor="location-filter">Available Rooms</label>
                  <select
                    name="rooms"
                    value={this.state.rooms}
                    onChange={this.onChange}
                    id="location-filter"
                  >
                    <option value="0">Any</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                </div>

                <div className="col-md field">
                  <label htmlFor="location-filter">Price Band</label>
                  <div className="slider">
                    <InputRange
                      minValue={this.state.minPrice}
                      maxValue={this.state.maxPrice}
                      step={1}
                      formatLabel={value => `£${value}`}
                      value={this.state.price}
                      onChange={price => this.setState({ price })}
                    />
                  </div>
                </div>

                <div className="col-md field filter-submit">
                  <button className="btn green-button" onClick={this.onSubmit}>
                    Search <i className="fas fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PropertyFilter;
