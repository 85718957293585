import React, { Component } from "react";
import Slider from "react-slick";
import arrow from "./arrow.png";
import isEmpty from "../../validation/is-empty";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      activeSlide: 1,
    };

    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  nextSlide(e) {
    this.slider1.slickNext();
  }

  prevSlide(e) {
    this.slider1.slickPrev();
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }
  render() {
    const { property } = this.props;

    const mainSettings = {
      ref: (slider) => (this.slider1 = slider),
      asNavFor: this.state.nav2,
      arrows: false,
      adaptiveHeight: true,
    };

    const navSettings = {
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider),
      slidesToShow: property.images.length,
      swipeToSlide: true,
      focusOnSelect: true,
    };

    if (isEmpty(property.images)) {
      return null;
    }

    return (
      <section className="gallery">
        <div className="main-slider">
          <Slider {...mainSettings}>
            {property.images.map((image, i) => (
              <div key={image.mediaKey}>
                <img
                  src={`/api/media/image/${image.mediaKey}`}
                  alt={`${property.name} #${i}`}
                />
              </div>
            ))}
          </Slider>

          <div className="slider-navigation">
            <div className="inner-nav">
              <button className="prev-arrow" onClick={this.prevSlide}>
                <img src={arrow} alt="Prev Arrow" />
              </button>

              <p>Featured Images</p>

              <button className="next-arrow" onClick={this.nextSlide}>
                <img src={arrow} alt="Next Arrow" />
              </button>
            </div>
          </div>
        </div>

        <div className="nav-slider">
          <Slider {...navSettings}>
            {property.images.map((image, i) => (
              <div className="slide first" key={i}>
                <div
                  className="bg-image"
                  style={{
                    backgroundImage: `url(/api/media/image/${image.mediaKey})`,
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default Gallery;
