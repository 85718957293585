import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addIndividualRoomToProperty } from '../../actions/propertyActions';

// import components
import Modal from 'react-bootstrap/Modal';

class NewIndividualRoomModal extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      price: '',
      type: '',
      show: false
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleShow(e) {
    this.setState({ show: true });
  }

  handleClose(e) {
    this.setState({ show: false });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { property } = this.props.properties;

    const data = {
      name: this.state.name,
      price: this.state.price,
      type: this.state.type
    };

    this.props.addIndividualRoomToProperty(property._id, data);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.properties.property !== nextProps.properties.property) {
      this.setState({ show: false });
      this.setState({ name: '', price: '', type: '' });
    }
  }

  render() {
    return (
      <React.Fragment>
        <button className="btn green-button" onClick={this.handleShow}>
          + Add Individual Room
        </button>

        <Modal show={this.state.show} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Create a new individual room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-section">
              <h3 className="heading">General Info</h3>

              <div className="row">
                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Room Name</h5>
                    <input
                      type="text"
                      name="name"
                      placeholder="Room #1"
                      value={this.state.name}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Price</h5>
                    <input
                      type="text"
                      name="price"
                      placeholder="120"
                      value={this.state.price}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Type</h5>
                    <input
                      type="text"
                      name="type"
                      placeholder="Single"
                      value={this.state.type}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn green-button" onClick={this.onSubmit}>
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { addIndividualRoomToProperty }
)(NewIndividualRoomModal);
