import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addProperty } from '../../actions/propertyActions';

class AddPropertyModal extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      addressLine1: '',
      addressCity: '',
      addressCounty: '',
      addressPostcode: '',
      slug: '',
      featured: false,
      description: '',
      features: '',
      park: '',
      nightlife: '',
      shopping: '',
      cityCentre: '',
      busStation: '',
      trainStation: '',
      supermarket: '',
      stadium: '',
      raceCourse: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.properties.properties !== nextProps.properties.properties) {
      window.$('#addPropertyModal').modal('hide');
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const propertyData = {
      name: this.state.name,
      addressLine1: this.state.addressLine1,
      addressCity: this.state.addressCity,
      addressCounty: this.state.addressCounty,
      addressPostcode: this.state.addressPostcode,
      slug: this.state.slug,
      featured: this.state.featured,
      description: this.state.description,
      features: this.state.features,
      park: this.state.park,
      nightlife: this.state.nightlife,
      shopping: this.state.shopping,
      cityCentre: this.state.cityCentre,
      busStation: this.state.busStation,
      trainStation: this.state.trainStation,
      supermarket: this.state.supermarket,
      stadium: this.state.stadium,
      raceCourse: this.state.raceCourse
    };

    this.props.addProperty(propertyData);
  }

  render() {
    return (
      <div
        className="modal fade"
        id="addPropertyModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addPropertyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addPropertyLabel">
                Add a property
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="edit-section">
                <h3 className="heading">General Info</h3>

                <div className="row">
                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Property Name</h5>
                      <input
                        type="text"
                        name="name"
                        placeholder="10 Downing Street"
                        value={this.state.name}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Page Slug</h5>

                      <input
                        type="text"
                        name="slug"
                        placeholder="10-downing-street"
                        value={this.state.slug}
                        onChange={this.onChange}
                      />

                      <p className="description">
                        Page slugs <strong>can't</strong> contain any spaces,
                        this is the link that users will go to e.g.
                        /property/page-slug
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="field">
                      <h5 className="heading">Property Description</h5>

                      <textarea
                        className="w-100"
                        type="text"
                        name="description"
                        rows="5"
                        value={this.state.description}
                        onChange={this.onChange}
                        placeholder="Modestly sized property, with a couple of rooms..."
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="field">
                      <h5 className="heading">Property Features</h5>

                      <p className="description">
                        These are the property features that appear as bullet
                        points on the front end.{' '}
                        <strong>Separate each feature with a comma</strong>,
                        e.g. Close to town, Good night life, Free WiFi
                      </p>

                      <textarea
                        placeholder="Close to town, Good night life, Free WiFi"
                        className="w-100"
                        type="text"
                        name="features"
                        rows="3"
                        value={this.state.features}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="edit-section">
                <h3 className="heading">Address Info</h3>

                <div className="row">
                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Line 1</h5>
                      <input
                        type="text"
                        name="addressLine1"
                        value={this.state.addressLine1}
                        onChange={this.onChange}
                        placeholder="10 Downing Street"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">City</h5>
                      <input
                        type="text"
                        name="addressCity"
                        value={this.state.addressCity}
                        onChange={this.onChange}
                        placeholder="Westminster"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">County</h5>
                      <input
                        type="text"
                        name="addressCounty"
                        value={this.state.addressCounty}
                        onChange={this.onChange}
                        placeholder="London"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Postcode</h5>
                      <input
                        type="text"
                        name="addressPostcode"
                        value={this.state.addressPostcode}
                        onChange={this.onChange}
                        placeholder="SW1A 2AA"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="edit-section">
                <h3 className="heading">In the area</h3>

                <div className="row">
                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Park</h5>
                      <input
                        type="text"
                        name="park"
                        value={this.state.park}
                        onChange={this.onChange}
                        placeholder="Park - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Nightlife</h5>
                      <input
                        type="text"
                        name="nightlife"
                        value={this.state.nightlife}
                        onChange={this.onChange}
                        placeholder="Nightlife - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Shopping</h5>
                      <input
                        type="text"
                        name="shopping"
                        value={this.state.shopping}
                        onChange={this.onChange}
                        placeholder="Shopping - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Bus Station</h5>
                      <input
                        type="text"
                        name="busStation"
                        value={this.state.busStation}
                        onChange={this.onChange}
                        placeholder="Bus Station - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Train Station</h5>
                      <input
                        type="text"
                        name="trainStation"
                        value={this.state.trainStation}
                        onChange={this.onChange}
                        placeholder="Train Station - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Supermarket</h5>
                      <input
                        type="text"
                        name="supermarket"
                        value={this.state.supermarket}
                        onChange={this.onChange}
                        placeholder="Supermarket - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Stadium</h5>
                      <input
                        type="text"
                        name="stadium"
                        value={this.state.stadium}
                        onChange={this.onChange}
                        placeholder="Stadium - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Race Course</h5>
                      <input
                        type="text"
                        name="raceCourse"
                        value={this.state.raceCourse}
                        onChange={this.onChange}
                        placeholder="Race Course - 10 mins away"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">City Centre</h5>
                      <input
                        type="text"
                        name="cityCentre"
                        value={this.state.cityCentre}
                        onChange={this.onChange}
                        placeholder="City Centre - 10 mins away"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="edit-section">
                <h3 className="heading">Other Info</h3>

                <div className="row">
                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Featured?</h5>
                      <p className="description">
                        Please note, you should only have three featured
                        properties (only the first three featured properties
                        will appear). These appear on the homepage.
                      </p>
                      <select
                        name="featured"
                        value={this.state.featured}
                        onChange={this.onChange}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.onSubmit}
                className="btn green-button"
              >
                Add Property
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { addProperty }
)(AddPropertyModal);
