import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { getPropertyBySlug } from '../../actions/propertyActions';
import isEmpty from '../../validation/is-empty';
import { Redirect } from 'react-router-dom';
// import Spinner from '../common/Spinner';

// import components
import Header from './Header';
import Gallery from './Gallery';
import RightSidebar from './RightSidebar';
import PropertyInfo from './PropertyInfo';
import EnquiryModal from './EnquiryModal';
import Spinner from '../common/Spinner';

class Property extends Component {
  constructor() {
    super();

    this.state = {
      started: false
    };
  }

  componentDidMount() {
    this.props.getPropertyBySlug(this.props.match.params.slug);

    this.setState({ started: true });
  }

  render() {
    const { property, singleLoading } = this.props.properties;
    let galleryContent;
    let mainContent;

    if (singleLoading || isEmpty(property)) {
      galleryContent = null;
    } else {
      galleryContent = <Gallery property={property} />;
    }

    if (singleLoading || !this.state.started) {
      mainContent = <Spinner />;
    } else if (!singleLoading && isEmpty(property)) {
      mainContent = <Redirect to="/not-found" />;
    } else {
      mainContent = (
        <section className="property-page">
          <Helmet>
            <title>{property.name ? property.name : 'Property'}</title>
          </Helmet>

          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <Header property={property} />
              </div>
            </div>

            <div className="main-content">
              <div className="row">
                <div className="col-md-8">
                  {galleryContent}
                  <div className="cta-request">
                    <div className="call">
                      <p>
                        Call us on{' '}
                        <a href="tel: 01452886186">01452&nbsp;886186</a>
                      </p>
                    </div>

                    <button
                      type="button"
                      className="btn green-button"
                      data-toggle="modal"
                      data-target="#enquiryModal"
                    >
                      Request Details
                    </button>

                    <EnquiryModal />
                  </div>

                  <PropertyInfo property={property} loading={singleLoading} />

                  <div className="cta-request d-md-none">
                    <div className="call">
                      <p>
                        Call us on{' '}
                        <a href="tel: 01452886186">01452&nbsp;886186</a>
                      </p>
                    </div>

                    <button
                      type="button"
                      className="btn green-button"
                      data-toggle="modal"
                      data-target="#enquiryModal"
                    >
                      Request Details
                    </button>

                    <EnquiryModal />
                  </div>
                </div>

                <div className="col-md-4">
                  <RightSidebar property={property} loading={singleLoading} />
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }

    return mainContent;
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { getPropertyBySlug }
)(Property);
