import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { getSavedPropertiesCount } from '../../actions/propertyActions';
import '../../lib/hamburgers.min.css';

// import components
import UserBar from './UserBar';

class Header extends Component {
  constructor() {
    super();

    this.state = {
      menuClosed: true,
      width: 0,
      height: 0
    };

    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.props.getSavedPropertiesCount();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerheight });
  }

  onLogoutClick(e) {
    e.preventDefault();

    this.props.logoutUser();
    this.setState({ menuClosed: true });
  }

  onToggleClick(e) {
    e.preventDefault();

    this.state.menuClosed
      ? this.setState({ menuClosed: false })
      : this.setState({ menuClosed: true });
  }

  hideMenu(e) {
    this.setState({ menuClosed: true });
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { savedCount } = this.props.properties;
    let mainLinks, mobileLinks, userLinks;

    mainLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link" to="/accommodation">
            Accommodation
          </Link>
        </li>
        <li className="nav-item dropdown">
          <button
            className="nav-link dropdown-toggle btn btn-link"
            id="navbarDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            About
          </button>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="/about-us">
              About Us
            </Link>
            <div className="dropdown-divider" />
            <Link className="dropdown-item" to="/about-glos">
              About Gloucestershire
            </Link>
            <Link className="dropdown-item" to="/about-gloucester">
              About Gloucester
            </Link>
          </div>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="//blog.studentdigsgloucestershire.co.uk"
          >
            Blog
          </a>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">
            Contact Us
          </Link>
        </li>
      </ul>
    );

    if (isAuthenticated) {
      userLinks = (
        <React.Fragment>
          <li className="nav-item">
            <Link className="nav-link" to="/saved" onClick={this.hideMenu}>
              Saved Properties{' '}
              {savedCount > 0 && (
                <span className="badge badge-light">{savedCount}</span>
              )}
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/user-panel" onClick={this.hideMenu}>
              User Panel
            </Link>
          </li>

          <li className="nav-item">
            <button className="nav-link" onClick={this.onLogoutClick}>
              Log Out
            </button>
          </li>
        </React.Fragment>
      );
    } else {
      userLinks = (
        <React.Fragment>
          <li className="nav-item">
            <Link className="nav-link" to="/saved" onClick={this.hideMenu}>
              Saved Properties{' '}
              {savedCount > 0 && (
                <span className="badge badge-light">{savedCount}</span>
              )}
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/login" onClick={this.hideMenu}>
              Log In
            </Link>
          </li>
        </React.Fragment>
      );
    }

    mobileLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link
            className="nav-link"
            to="/accommodation"
            onClick={this.hideMenu}
          >
            Accommodation
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/about-us" onClick={this.hideMenu}>
            About Us
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/about-glos" onClick={this.hideMenu}>
            About Gloucestershire
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link"
            to="/about-gloucester"
            onClick={this.hideMenu}
          >
            About Gloucester
          </Link>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            href="//blog.studentdigsgloucestershire.co.uk"
          >
            Blog
          </a>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/contact" onClick={this.hideMenu}>
            Contact Us
          </Link>
        </li>

        <li className="nav-divider" />

        {userLinks}
      </ul>
    );

    return (
      <React.Fragment>
        <header className={this.state.width <= 768 ? 'sticky-top' : ''}>
          {this.state.width > 768 && <UserBar />}

          <div className="nav-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-3 col-7 logo my-auto">
                  <Link className="logo-link" to="/">
                    <img src={logo} alt="Student Digs Logo" />
                  </Link>
                </div>

                <div className="col-5 d-md-none toggle-wrapper text-right my-auto">
                  <button
                    onClick={this.onToggleClick}
                    className={`hamburger hamburger--collapse ${!this.state
                      .menuClosed && ' is-active'}`}
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                </div>

                <div className="col-lg-10 col-md-9 d-none d-md-block navigation my-auto">
                  <nav className="navbar navbar-expand-md navbar-dark">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      {mainLinks}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          className={
            this.state.menuClosed ? 'mobile-menu closed' : 'mobile-menu'
          }
        >
          <div className="mobile-nav-wrapper">{mobileLinks}</div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { logoutUser, getSavedPropertiesCount }
)(Header);
