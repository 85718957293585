import React, { Component } from 'react';
import queryString from 'query-string';
import isEmpty from '../../validation/is-empty';
import { Redirect } from 'react-router-dom';

export default class Secure extends Component {
  constructor() {
    super();

    this.state = {
      acsUrl: '',
      paReq: '',
      paymentID: '',
      transactionId: '',
      redirect: false
    };
  }

  componentDidMount() {
    const value = queryString.parse(this.props.location.search);

    this.setState({
      acsUrl: value.acsUrl,
      paReq: value.paReq ? value.paReq.split(' ').join('+') : '',
      paymentId: value.paymentId,
      transactionId: value.transactionId
    });

    if (isEmpty(value.paReq)) {
      this.setState({ redirect: true });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    return (
      <div className="container my-5">
        <form
          id="pa-form"
          method="post"
          action={this.state.acsUrl}
          ref="myForm"
        >
          <input type="hidden" name="PaReq" value={this.state.paReq} />
          <input
            type="hidden"
            name="TermUrl"
            value={`https://www.studentdigsgloucestershire.co.uk/api/payments/secure/${this.state.transactionId}/${this.state.paymentId}?PaReq=${this.state.paReq}&MD=${this.state.paymentId}`}
          />
          <input type="hidden" name="MD" value={this.state.paymentId} />

          <div>
            <p>
              Please click button below to authenicate your card with your bank.
            </p>
            <input className="btn green-button" type="submit" value="Go" />
          </div>
        </form>
      </div>
    );
  }
}
