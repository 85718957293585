// auth types
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const GET_USER = "GET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_DOCS = "EDIT_USER_DOCS";

// error types
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// property types
export const GET_FEATURED_PROPERTIES = "GET_FEATURED_PROPERTIES";
export const PROPERTIES_LOADING = "PROPERTIES_LOADING";
export const GET_ALL_PROPERTIES = "GET_ALL_PROPERTIES";
export const ADMIN_ALL_PROPERTIES = "ADMIN_ALL_PROPERTIES";
export const GET_PROPERTY = "GET_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const ADD_PROPERTY = "ADD_PROPERTY";
export const ADD_FLAT = "ADD_FLAT";
export const EDIT_FLAT = "EDIT_FLAT";
export const EDIT_ROOM = "EDIT_ROOM";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const DELETE_FLAT = "DELETE_FLAT";
export const DELETE_ROOM = "DELETE_ROOM";
export const CLEAR_PROPERTIES = "CLEAR_PROPERTIES";
export const REMOVE_TENANT = "REMOVE_TENANT";
export const ADD_TENANT = "ADD_TENANT";
export const MOVE_FUTURE_TENANT = "MOVE_FUTURE_TENANT";
export const GET_SAVED_PROPERTIES = "GET_SAVED_PROPERTIES";
export const GET_SAVED_PROPERTIES_COUNT = "GET_SAVED_PROPERTIES_COUNT";
export const SAVED_PROPERTIES_LOADING = "SAVED_PROPERTIES_LOADING";
export const SINGLE_PROPERTY_LOADING = "SINGLE_PROPERTY_LOADING";

// room types
export const ADD_ROOM = "ADD_ROOM";

// enquiry types
export const GET_ALL_ENQUIRIES = "GET_ALL_ENQUIRIES";
export const ENQUIRIES_LOADING = "ENQUIRIES_LOADING";
export const GET_ENQUIRY = "GET_ENQUIRY";
export const GET_TENANT_ENQUIRIES = "GET_TENANT_ENQUIRIES";
export const GET_TENANT_ENQUIRY = "GET_TENANT_ENQUIRY";
export const DELETE_ENQUIRY = "DELETE_ENQUIRY";
export const DELETE_TENANT_ENQUIRY = "DELETE_TENANT_ENQUIRY";
export const UPDATE_ENQUIRY_STATUS = "UPDATE_ENQUIRY_STATUS";

// resource types
export const GET_FOR_RESOURCES = "GET_FOR_RESOURCES";
export const GET_UPLOADED_RESOURCES = "GET_UPLOADED_RESOURCES";
export const RESOURCES_LOADING = "RESOURCES_LOADING";
export const UPLOAD_RESOURCE = "UPLOAD_RESOURCE";
export const ADMIN_UPLOAD_RESOURCE = "ADMIN_UPLOAD_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const CLEAR_ALL_RESOURCES = "CLEAR_ALL_RESOURCES";
export const UPLOADING_RESOURCE = "UPLOADING_RESOURCE";

// tenancy actions
export const GET_ALL_TENANTS = "GET_ALL_TENANTS";
export const TENANCY_LOADING = "TENANCY_LOADING";
export const GET_ACTIVE_TENANCIES = "GET_ACTIVE_TENANCIES";

// payment actions
export const GET_UNPAID_PAYMENTS = "GET_UNPAID_PAYMENTS";
export const GET_PAID_PAYMENTS = "GET_PAID_PAYMENTS";
export const GET_PROCESSING_PAYMENTS = "GET_PROCESSING_PAYMENTS";
export const PAYMENTS_LOADING = "SET_PAYMENTS_LOADING";
export const GET_ALL_USERS_PAYMENTS = "GET_ALL_USERS_PAYMENTS";
export const CREATE_NEW_PAYMENT = "CREATE_NEW_PAYMENT";
export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const GET_ALL_PAYMENTS = "GET_ALL_PAYMENTS";
export const GET_SINGLE_PAYMENT = "GET_SINGLE_PAYMENT";
export const CLEAR_USER_PAYMENTS = "CLEAR_USER_PAYMENTS";
export const CLEAR_SINGLE_PAYMENT = "CLEAR_SINGLE_PAYMENT";
export const GET_PAYMENTS_SUMMARY = "GET_PAYMENTS_SUMMARY";
