import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadResource } from '../../actions/resourcesActions';

class UploadResource extends Component {
  handleSelectedFile = event => {
    const selectedFile = event.target.files[0];
    const file = new FormData();

    file.append('file', selectedFile, selectedFile.name);
    this.props.uploadResource(file);
  };

  render() {
    const { errors } = this.props;
    const { uploading } = this.props.resources;

    return (
      <div className="upload-resource">
        <h3 className="heading">Upload a resource</h3>

        <p>
          Upload any documents you want the Student Digs team to see! Please
          note, there's a <strong>5mb file size limit</strong> on all uploads.
        </p>

        <div className="upload-btn-wrapper">
          <button className="btn">
            {uploading ? (
              <i className="fas fa-circle-notch fa-spin" />
            ) : (
              'Upload a file'
            )}
          </button>
          <input type="file" name="myfile" onChange={this.handleSelectedFile} />
        </div>

        {errors.uploadResource ? (
          <small className="text-danger">{errors.uploadResource}</small>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.resources,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { uploadResource }
)(UploadResource);
