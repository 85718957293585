import React, { Component } from "react";
import Moment from "react-moment";
import isEmpty from "../../validation/is-empty";
import { connect } from "react-redux";
import { archiveOtherTenant } from "../../actions/tenancyActions";

// import components
import Spinner from "../common/Spinner";

class TenantCard extends Component {
  constructor() {
    super();

    this.archiveTenant = this.archiveTenant.bind(this);
  }

  archiveTenant(e) {
    e.preventDefault();
    const { tenant } = this.props;

    this.props.archiveOtherTenant(tenant._id);
  }

  render() {
    const { tenant } = this.props;
    let tenantContent;

    if (!isEmpty(tenant)) {
      tenantContent = (
        <div className="tenant-card">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="field">
                <h5 className="heading">Name</h5>
                <p>{tenant.user ? tenant?.user?.name : null}</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="field">
                <h5 className="heading">Email</h5>
                <p>{tenant?.user ? tenant?.user?.email : null}</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="field">
                <h5 className="heading">User ID</h5>
                <p>{tenant?.user ? tenant?.user?._id : null}</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="field">
                <h5 className="heading">Start Date</h5>
                <p>
                  {tenant.startDate ? (
                    <Moment format="DD/MM/YYYY">{tenant.startDate}</Moment>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="field">
                <h5 className="heading">End Date</h5>
                <p>
                  {tenant.endDate ? (
                    <Moment format="DD/MM/YYYY">{tenant.endDate}</Moment>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="field">
                <h5 className="heading">Weekly Rent</h5>
                <p>{tenant.rentalValue ? tenant.rentalValue : "-"}</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="field">
                <button className="btn red-button" onClick={this.archiveTenant}>
                  Archive Tenant
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      tenantContent = <Spinner />;
    }

    return tenantContent;
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { archiveOtherTenant })(TenantCard);
