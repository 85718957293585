import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEnquiry, deleteEnquiry } from '../../actions/enquiryActions';
import { Redirect, Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';

// import navigation bar
import AdminNavigation from '../admin/AdminNavigation';
import EnquiryContent from './EnquiryContent';

class ViewEnquiry extends Component {
  constructor() {
    super();

    this.state = {
      deleted: false
    };

    this.onDelete = this.onDelete.bind(this);
  }

  onDelete(e) {
    e.preventDefault();

    this.props.deleteEnquiry(this.props.match.params.enquiryId);

    this.setState({ deleted: true });
  }

  componentDidMount() {
    this.props.getEnquiry(this.props.match.params.enquiryId);
  }

  render() {
    const { enquiry } = this.props.enquiries;
    const { deleted } = this.state;

    if (deleted) {
      return <Redirect to="/admin-panel/enquiries" />;
    }

    return (
      <section className="admin-panel">
        <div className="container">
          <AdminNavigation />
          <div className="panel-content">
            <h1 className="heading">
              View Enquiry{' '}
              {enquiry._id ? (
                <small>
                  <code>ID: {enquiry._id}</code>{' '}
                </small>
              ) : null}
            </h1>

            <div className="content">
              {!isEmpty(enquiry) && <EnquiryContent enquiry={enquiry} />}

              <div className="admin-section">
                <div className="header">
                  <h3 className="heading">Delete Enquiry</h3>
                </div>

                <p>
                  Please note, once an enquiry is deleted it cannot be
                  recovered!
                </p>

                <button onClick={this.onDelete} className="btn red-button">
                  Delete Enquiry
                </button>
              </div>

              <div className="footer">
                <Link to="/admin-panel/enquiries" className="btn green-button">
                  &larr; All Enquiries
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  enquiries: state.enquiries
});

export default connect(
  mapStateToProps,
  { getEnquiry, deleteEnquiry }
)(ViewEnquiry);
