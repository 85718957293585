import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  }

  render() {
    const { errors } = this.props;
    const { isAuthenticated } = this.props.auth;

    // if authenticated, redirect to previous path
    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <main className="login-page">
        <Helmet>
          <title>Login</title>
        </Helmet>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 mx-auto">
              <div className="login-box">
                <div className="header">
                  <h1 className="heading">Login to your account</h1>
                </div>

                <div className="content">
                  {errors.auth ? (
                    <p className="text-danger pb-3">{errors.auth}</p>
                  ) : null}

                  <form onSubmit={this.onSubmit}>
                    <div className="field-wrapper">
                      <div className="field">
                        <i className="fas fa-user" />
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChange}
                          placeholder="Email Address"
                        />
                      </div>
                      {errors.email ? (
                        <p className="text-danger">{errors.email}</p>
                      ) : null}
                    </div>

                    <div className="field-wrapper">
                      <div className="field">
                        <i className="fas fa-lock" />
                        <input
                          type="password"
                          name="password"
                          onChange={this.onChange}
                          placeholder="Password"
                        />
                      </div>
                      {errors.password ? (
                        <p className="text-danger">{errors.password}</p>
                      ) : null}
                    </div>

                    <button type="submit" className="btn green-button">
                      Login
                    </button>
                  </form>
                </div>
              </div>
              <div className="footer-box">
                <p>
                  Forgotten your password?{' '}
                  <Link to="/forgotten-password">Reset Password</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
