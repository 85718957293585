import {
  GET_ALL_USERS,
  USERS_LOADING,
  GET_USER,
  DELETE_USER,
  EDIT_USER,
  CLEAR_USER,
  EDIT_USER_DOCS
} from '../actions/types';

const initialState = {
  users: [],
  user: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case CLEAR_USER:
      return {
        ...state,
        user: {}
      };
    case USERS_LOADING:
      return {
        ...state,
        loading: true
      };
    case EDIT_USER:
      return {
        ...state,
        user: action.payload
      };
    case EDIT_USER_DOCS:
      return {
        ...state,
        user: action.payload
      };
    case DELETE_USER:
      return {
        ...state,
        user: {},
        users: state.users.filter(user => user?._id !== action.payload.user)
      };
    default:
      return state;
  }
}
