import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// import components
import Sidebar from './Sidebar';

class PanelPage extends Component {
  render() {
    return (
      <div
        className={
          this.props.className
            ? `user-panel ${this.props.className}`
            : 'user-panel'
        }
      >
        <Helmet>
          <title>User Panel</title>
        </Helmet>

        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Sidebar />
            </div>

            <div className="col-lg-9">
              <div className="panel-contents">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PanelPage;
