import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import isEmpty from "../../validation/is-empty";

class EnquiryModal extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      phone: "",
      room: "",
      message: "",
      source: "",
      errors: {},
      loading: false,
      success: false,
      captchaValue: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { property } = this.props.properties;

    if (isEmpty(this.state.captchaValue)) {
      return this.setState({
        errors: {
          captcha: "You must complete the captcha before sending an enquiry",
        },
      });
    }

    // set loading
    this.setState({ loading: true, errors: {} });

    const data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      room: this.state.room,
      message: this.state.message,
    };

    axios
      .post(`/api/contact/enquiry/${property._id}`, data)
      .then((success) => {
        this.setState({
          loading: false,
          success: true,
          name: "",
          phone: "",
          email: "",
          message: "",
          room: "",
          source: "",
          errors: {},
        });
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data,
          loading: false,
          success: false,
        });
      });
  }

  onCaptchaChange(value) {
    this.setState({ captchaValue: value, errors: { captcha: "" } });

    if (value === null) this.setState({ captchaValue: "" });
  }

  render() {
    const { flats } = this.props.properties.property;
    const { property } = this.props.properties;
    const { errors, loading, success } = this.state;

    let bodyContent;

    if (!success) {
      bodyContent = (
        <div className="row">
          <div className="col-md-6">
            <div className="field">
              <label>Name *</label>
              <input
                type="text"
                name="name"
                placeholder="Jane Doe"
                value={this.state.name}
                onChange={this.onChange}
                className={errors.name ? "field-error" : ""}
              />

              {errors.name ? <p className="error">{errors.name}</p> : null}
            </div>
          </div>

          <div className="col-md-6">
            <div className="field">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                placeholder="07123 456789"
                value={this.state.phone}
                onChange={this.onChange}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="field">
              <label>Email *</label>
              <input
                type="text"
                name="email"
                placeholder="jane.doe@gmail.com"
                value={this.state.email}
                onChange={this.onChange}
                className={errors.email ? "field-error" : ""}
              />

              {errors.email ? <p className="error">{errors.email}</p> : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="field">
              <label>Room</label>
              <select
                type="text"
                name="room"
                value={this.state.room}
                onChange={this.onChange}
              >
                <option value="" className="font-weight-bold">
                  Any Room
                </option>
                {flats &&
                  flats.map(({ flat }) => {
                    return flat.rooms.map(({ room }) => (
                      <option key={room?._id} value={room?._id}>
                        {flat?.name}, {room?.name}
                      </option>
                    ));
                  })}

                {property.individualRooms &&
                  property.individualRooms.map(({ room }) => {
                    return (
                      <option key={room?._id} value={room?._id}>
                        {room?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-12">
            <div className="field">
              <label>How did you find us?</label>
              <select
                name="source"
                value={this.state.source}
                onChange={this.onChange}
              >
                <option value="">Select an option</option>
                <option value="Recommended by a friend / family">
                  Recommended by a friend / family
                </option>
                <option value="Google search results">
                  Google search results
                </option>
                <option value="Via blog posts">Via blog posts</option>
                <option value="Social media">Social media</option>
              </select>

              {errors.source ? <p className="error">{errors.source}</p> : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="field">
              <label>Message *</label>
              <textarea
                name="message"
                id="enquiryMessage"
                value={this.state.message}
                onChange={this.onChange}
                rows="5"
                placeholder="I am interested in renting a room from September..."
                className={errors.message ? "field-error" : ""}
              />

              {errors.message ? (
                <p className="error">{errors.message}</p>
              ) : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="field">
              <ReCAPTCHA
                sitekey="6LfxzZ4UAAAAABAQJwDo8Kw70lf3zWNe5o1x36OR"
                onChange={this.onCaptchaChange}
              />
              {errors.captcha ? (
                <p className="error">{errors.captcha}</p>
              ) : null}
            </div>
          </div>

          <div className="col-md-12">
            <div className="field mb-0">
              <p>
                We typically reply via email, if our response appears delayed,
                please check that our emails aren't going through to your spam
                or junk folders.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      bodyContent = (
        <div className="confirmation">
          <h3 className="heading">Thank you for your enquiry!</h3>
          <p>We'll make sure we get back to you ASAP!</p>
        </div>
      );
    }

    return (
      <div
        className="modal fade enquiry-modal"
        id="enquiryModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="enquiryModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Request Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">{bodyContent}</div>

            {!success ? (
              <div className="modal-footer">
                {loading ? (
                  <button className="btn green-button">
                    <i className="fas fa-circle-notch fa-spin" />
                  </button>
                ) : (
                  <button className="btn green-button" onClick={this.onSubmit}>
                    Send Enquiry
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps)(EnquiryModal);
