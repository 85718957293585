import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllUsersPayments } from '../../actions/paymentActions';
import moment from 'moment';

// import components
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import CreatePayment from './CreatePayment';
import EditPayment from './EditPayment';
import Spinner from '../common/Spinner';

class UserPayments extends Component {
  componentDidMount() {
    this.props.getAllUsersPayments(this?.props?.user?._id);
  }

  render() {
    const { all, loading } = this.props.payments;
    const columns = [
      {
        Header: 'Date Created',
        id: 'date-created',
        accessor: d => moment(d.date).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'Title',
        accessor: 'title',
        minResizeWidth: 10
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: d => `£${d.value}`
      },
      {
        Header: 'Reference',
        accessor: 'reference',
        minResizeWidth: 10
      },
      {
        Header: 'Due Date',
        id: 'due-date',
        className: 'text-center',
        accessor: d =>
          d.dueDate ? moment(d.dueDate).format('DD/MM/YYYY') : '-',
        minResizeWidth: 10
      },
      {
        Header: 'Processing?',
        id: 'processing',
        className: 'text-center',
        accessor: d =>
          d.processing ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          ),
        minResizeWidth: 10
      },
      {
        Header: 'Paid?',
        id: 'paid',
        className: 'text-center',
        accessor: d =>
          d.paid ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          ),
        minResizeWidth: 10
      },
      {
        Header: 'Manage',
        minResizeWidth: 10,
        id: 'manage',
        className: 'text-center',
        accessor: d => {
          return <EditPayment payment={d} />;
        }
      }
    ];

    return (
      <div className="admin-section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-info">
              <h3 className="heading">User Payments</h3>
              <p>
                The following is where you can monitor and create user payments.
                These will appear on the users 'User Panel'. If a payment is
                marked as 'Processing' it normally means someone has tampered
                with the amounts, manually check the payments carefully and then
                mark them as paid if you find no problems.
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div className="section-content">
              {loading ? (
                <Spinner></Spinner>
              ) : (
                <ReactTable
                  data={all}
                  columns={columns}
                  pageSize={all.length}
                  showPaginationBottom={false}
                  filterable
                />
              )}
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <CreatePayment />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  payments: state.payments
});

export default connect(
  mapStateToProps,
  { getAllUsersPayments }
)(UserPayments);
