import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadImage, deleteImage } from '../../actions/propertyActions';

class ImageDetails extends Component {
  handleSelectedFile = event => {
    const selectedFile = event.target.files[0];
    const { property } = this.props;

    const img = new FormData();
    img.append('image', selectedFile, selectedFile.name);

    this.props.uploadImage(property._id, img);
  };

  deleteSelectedImage = mediaKey => {
    const { property } = this.props;

    this.props.deleteImage(property._id, mediaKey);
  };

  render() {
    const { property } = this.props;
    return (
      <div className="admin-section image-details">
        <div className="row">
          <div className="col-md-4">
            <div className="section-info">
              <h3 className="heading">Property Images</h3>
              <p>
                Here are the property images, the first two images will be the
                images featured in the property lists.{' '}
                <i>Recommended photo size is 1200x800px.</i>
              </p>

              <div className="new-media">
                <h3 className="subheading">Upload new images</h3>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  onChange={this.handleSelectedFile}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="section-content">
              <div className="row">
                {property.images.map(image => (
                  <div className="col-md-4 col-6" key={image.mediaKey}>
                    <div className="img-wrapper">
                      <button
                        className="delete-icon"
                        onClick={() => this.deleteSelectedImage(image.mediaKey)}
                      >
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x" />
                          <i className="fas fa-times fa-stack-1x fa-inverse" />
                        </span>
                      </button>
                      <img src={`/api/media/image/${image.mediaKey}`} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { uploadImage, deleteImage }
)(ImageDetails);
