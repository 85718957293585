import React from "react";
import isEmpty from "../../validation/is-empty";
import axios from "axios";

export default function FlatVideoEmbed({ flat, initialID }) {
  const [url, setUrl] = React.useState("");
  const [videoID, setVideoID] = React.useState("");
  const [videoError, setVideoError] = React.useState(false);
  const [resetCounter, setResetCounter] = React.useState(0);

  React.useEffect(() => {
    if (!isEmpty(initialID)) {
      const url = `https://www.youtube.com/watch?v=${initialID}`;
      setUrl(url);

      const id = getId(url);

      if (!isEmpty(id)) {
        setVideoID(id);
      } else {
        setVideoID("");
        setVideoError(true);
      }
    }
  }, [resetCounter]);

  const getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  const onURLChange = (e) => {
    e.preventDefault();
    setUrl(e.target.value);
    setVideoError(false);
    const id = getId(e.target.value);

    if (!isEmpty(id)) {
      setVideoID(id);
    } else {
      setVideoID("");
      setVideoError(true);
    }
  };

  const onUpdate = (e) => {
    e.preventDefault();

    axios
      .post(`/api/flats/flat-video/${flat._id}`, { videoID: videoID })
      .then((res) => {
        window.$(`#editFlatVideo${flat._id}`).modal("hide");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onReset = () => {
    window.$(`#editFlatVideo${flat._id}`).modal("hide");
    setResetCounter((prev) => prev + 1);
    setVideoError(false);
  };

  return (
    <>
      <button
        className="btn green-button ml-1"
        data-toggle="modal"
        data-target={`#editFlatVideo${flat._id}`}
      >
        Manage Flat Video
      </button>

      <div
        className="modal fade edit-flat-modal"
        id={`editFlatVideo${flat._id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editFlatVideoModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editFlatVideoModal">
                Manage flat video
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="edit-section">
                <div className="field">
                  <label>YouTube Video URL</label>

                  <p>
                    We suggest that you upload your videos on to YouTube as
                    "Unlisted", this means that people can only view the video
                    if it's embedded (like here) or if they have the direct URL.
                    Paste the URL to the YouTube video you would like to embed
                    into your flat media e.g. in the format{" "}
                    <strong>https://www.youtube.com/watch?v=wVYyDEsDFhE</strong>
                  </p>

                  <input type="text" value={url} onChange={onURLChange} />

                  {videoError && (
                    <p className="error text-danger">
                      Please check that your URL is correct and in the right
                      format - we couldn't find the video from your current URL.
                    </p>
                  )}
                </div>

                {!isEmpty(videoID) && (
                  <div className="video-wrapper">
                    <div>
                      <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoID}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button className="btn red-button" onClick={onReset}>
                Cancel & Close
              </button>

              <button
                type="button"
                className="btn green-button"
                onClick={onUpdate}
              >
                Update & Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
