import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUsersTenancies } from '../../actions/tenancyActions';
import moment from 'moment';

// import components
import ReactTable from 'react-table';
import 'react-table/react-table.css';

class UserTenancies extends Component {
  componentDidMount() {
    this.props.getUsersTenancies(this.props.userID);
  }

  render() {
    const { tenancies } = this.props.tenancy;

    const columns = [
      {
        Header: 'Date Created',
        id: 'date-created',
        accessor: d => moment(d.date).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'Room',
        accessor: 'room.name',
        minResizeWidth: 10
      },
      {
        Header: 'Flat',
        id: 'flat-name',
        accessor: d => (d.room.flat ? d.room.flat.name : '-'),
        minResizeWidth: 10
      },
      {
        Header: 'Property',
        accessor: 'room.property.name',
        minResizeWidth: 10
      },
      {
        Header: 'Start Date',
        id: 'start-date',
        accessor: d => moment(d.startDate).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'End Date',
        id: 'end-date',
        accessor: d => moment(d.endDate).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'Rental Value',
        accessor: 'rentalValue',
        minResizeWidth: 10,
        Cell: props => `£${props.value}`
      },
      {
        Header: 'Active?',
        accessor: 'active',
        className: 'text-center',
        minResizeWidth: 10,
        Cell: props =>
          props.value ? (
            <i className="fas fa-check text-success" />
          ) : (
            <i className="fas fa-times text-danger" />
          )
      }
    ];

    return (
      <div className="admin-section">
        <div className="row">
          <div className="col-md-12">
            <div className="section-info">
              <h3 className="heading">User tenancies</h3>
              <p>
                The following table contains all of the particular users
                tenancies, if the tenancy is not active, it means it's been
                archived.
              </p>
              <p>
                User tenancies must be created from the manage property pages!
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div className="section-content">
              {tenancies && (
                <ReactTable
                  data={tenancies}
                  columns={columns}
                  defaultPageSize={5}
                  filterable
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tenancy: state.tenancy
});

export default connect(
  mapStateToProps,
  { getUsersTenancies }
)(UserTenancies);
