import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPaymentsSummary } from '../../actions/paymentActions';
import { CSVLink } from 'react-csv';

// import components
import AdminPage from '../admin/AdminPage';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

class PaymentsSummary extends Component {
  constructor() {
    super();

    this.state = {
      year: new Date().getFullYear()
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.getPaymentsSummary(this.state.year);
  }

  render() {
    const { summary, loading } = this.props.payments;

    const columns = [
      {
        Header: 'User',
        id: 'user-name',
        accessor: 'name',
        minResizeWidth: 10
      },
      {
        Header: 'User Email',
        accessor: 'email',
        Cell: d => <a href={`mailto:${d.value}`}>{d.value}</a>,
        minResizeWidth: 10
      },
      {
        Header: 'Tenancy',
        id: 'tenancy',
        accessor: 'tenancyData',
        Cell: d => {
          let string = d.value.room;
          if (d.value.flat !== '-') string += `, ${d.value.flat}`;
          if (d.value.property !== '-') string += `, ${d.value.property}`;

          return string;
        }
      },
      {
        Header: 'Total Invoices',
        accessor: 'paymentsSummary.totalInvoices',
        className: 'text-center',
        minResizeWidth: 10
      },
      {
        Header: 'Paid Invoices',
        accessor: 'paymentsSummary.paidInvoices',
        className: 'text-center',
        minResizeWidth: 10
      },
      {
        Header: 'Unpaid Invoices',
        accessor: 'paymentsSummary.unpaidInvoices',
        className: 'text-center',
        minResizeWidth: 10
      },
      {
        Header: 'Total Amount',
        accessor: 'paymentsSummary.totalAmount',
        Cell: d => `£${Number(d.value).toFixed(2)}`,
        className: 'text-center',
        minResizeWidth: 10
      },
      {
        Header: 'Paid Amount',
        accessor: 'paymentsSummary.paidAmount',
        Cell: d => `£${Number(d.value).toFixed(2)}`,
        className: 'text-center',
        minResizeWidth: 10
      },
      {
        Header: 'Unpaid Amount',
        accessor: 'paymentsSummary.unpaidAmount',
        Cell: d => `£${Number(d.value).toFixed(2)}`,
        className: 'text-center',
        minResizeWidth: 10
      }
    ];

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null && typeof row[id] === 'string') {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }
    };

    const exportHeaders = [
      { label: 'User Name', key: 'name' },
      { label: 'User Email', key: 'email' },
      { label: 'User ID', key: '_id' },
      { label: 'Room', key: 'tenancyData.room' },
      { label: 'Flat', key: 'tenancyData.flat' },
      { label: 'Property', key: 'tenancyData.property' },
      { label: 'Total Invoices', key: 'paymentsSummary.totalInvoices' },
      { label: 'Paid Invoices', key: 'paymentsSummary.paidInvoices' },
      { label: 'Unpaid Invoices', key: 'paymentsSummary.unpaidInvoices' },
      { label: 'Total Amount', key: 'paymentsSummary.totalAmount' },
      { label: 'Paid Amount', key: 'paymentsSummary.paidAmount' },
      { label: 'Unpaid Amount', key: 'paymentsSummary.unpaidAmount' }
    ];

    return (
      <AdminPage title="Payment Summaries">
        <p>
          This page creates a summary based on payments for each user within a
          time frame. Insert the year below and payments from{' '}
          <strong>September</strong> of this year to{' '}
          <strong>September the following year</strong> are displayed. e.g. if
          you search for the year 2018, a payment summary from September 2018 -
          September 2019 will be generated.
        </p>
        <div className="date-selector">
          <label>Year Search</label>
          <input
            name="year"
            type="text"
            value={this.state.year}
            onChange={this.onChange}
          />
          <button className="btn green-button" onClick={this.onSubmit}>
            {loading ? <i className="fas fa-circle-notch fa-spin" /> : 'Search'}
          </button>
        </div>

        {summary.length > 0 && (
          <ReactTable
            data={summary}
            columns={columns}
            className="summary-table"
            defaultFilterMethod={filterCaseInsensitive}
            filterable
          />
        )}

        {summary.length > 0 && (
          <div className="page-footer text-right mt-3">
            <CSVLink
              data={summary}
              headers={exportHeaders}
              filename={`StudentDigs_payments_summary_${this.state.year}_${this
                .state.year + 1}.csv`}
              className="btn green-button"
            >
              Export as CSV
            </CSVLink>
          </div>
        )}
      </AdminPage>
    );
  }
}

const mapStateToProps = state => ({
  payments: state.payments
});

export default connect(
  mapStateToProps,
  { getPaymentsSummary }
)(PaymentsSummary);
