import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

class ForgottenPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      errors: {},
      success: false,
      loading: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ errors: {}, loading: true });

    const data = {
      email: this.state.email
    };

    axios
      .post('/api/auth/forgotten-password', data)
      .then(res => {
        this.setState({ loading: false });
        if (res.data.success) this.setState({ success: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  }

  render() {
    const { errors, success, loading } = this.state;
    let resetContent;

    if (!success) {
      resetContent = (
        <div className="login-box">
          <div className="header">
            <h1 className="heading">Need to reset your password?</h1>
            <p>
              Enter the email address linked to your account and we'll send a
              reset link to your email address.
            </p>
          </div>

          <div className="content">
            {errors.forgottenPassword ? (
              <p className="text-danger pb-3">{errors.forgottenPassword}</p>
            ) : null}

            <form onSubmit={this.onSubmit}>
              <div className="field-wrapper">
                <div className="field">
                  <i className="fas fa-user" />
                  <input
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    placeholder="Email Address"
                  />
                </div>
                {errors.email ? (
                  <p className="text-danger">{errors.email}</p>
                ) : null}
              </div>

              <button type="submit" className="btn green-button">
                {loading ? (
                  <i className="fas fa-circle-notch fa-spin" />
                ) : (
                  'Send Email'
                )}
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      resetContent = (
        <div className="login-box">
          <div className="header mb-0">
            <h1 className="heading">
              A link has been sent to your email address!
            </h1>
            <p>
              Check your inbox for a link to resetting your password! The link
              will expire when your password has been changed or if your
              password hasn't been reset within a month.
            </p>
            <p className="mb-0">
              Can't find the email? Check your spam and junk boxes!
            </p>
          </div>
        </div>
      );
    }

    return (
      <main className="login-page">
        <Helmet>
          <title>Forgotten Password</title>
        </Helmet>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10 mx-auto">{resetContent}</div>
          </div>
        </div>
      </main>
    );
  }
}

export default ForgottenPassword;
