import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editUser } from '../../actions/userActions';

// import components
import Modal from 'react-bootstrap/Modal';

class EditUser extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      email: '',
      phone: '',
      address: '',
      admin: false,
      showModal: false
    };

    this.onChange = this.onChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.onBooleanChange = this.onBooleanChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { user } = this.props.users;

    this.setState({
      name: user?.name ? user?.name : '',
      email: user?.email ? user?.email : '',
      phone: user?.phone ? user?.phone : '',
      address: user?.address ? user?.address : '',
      admin: user?.admin ? true : false
    });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleHide() {
    const { user } = this.props.users;

    this.setState({
      name: user?.name ? user?.name : '',
      email: user?.email ? user?.email : '',
      phone: user?.phone ? user?.phone : '',
      address: user?.address ? user?.address : '',
      admin: user?.admin ? true : false,
      showModal: false
    });
  }

  onBooleanChange(e) {
    e.preventDefault();

    e.target.value === 'true'
      ? this.setState({ [e.target.name]: true })
      : this.setState({ [e.target.name]: false });
  }

  onSubmit(e) {
    e.preventDefault();

    const data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      admin: this.state.admin,
      address: this.state.address
    };

    this.props.editUser(this?.props?.users?.user?._id, data);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.users.user !== nextProps.users.user) {
      this.setState({ showModal: false });
    }
  }

  render() {
    const { errors } = this.props;

    return (
      <React.Fragment>
        <button className="btn green-button" onClick={this.handleShow}>
          Edit Users Details
        </button>

        <Modal show={this.state.showModal} onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit user details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="field">
                    <h4 className="heading">Name</h4>
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                    />

                    {errors.name && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h4 className="heading">Email</h4>
                    <input
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                    />

                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="field">
                    <h4 className="heading">Address</h4>
                    <input
                      placeholder="Line 1, Line 2, Town, County, GL1 AAA"
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.onChange}
                    />

                    {errors.address && (
                      <small className="text-danger">{errors.address}</small>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h4 className="heading">Phone</h4>
                    <input
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.onChange}
                    />

                    {errors.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h4 className="heading">Admin</h4>
                    <select
                      name="admin"
                      onChange={this.onBooleanChange}
                      value={this.state.admin ? 'true' : 'false'}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>

                    {errors.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn green-button" onClick={this.onSubmit}>
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { editUser }
)(EditUser);
