import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import components
import AdminPage from './AdminPage';

class AdminHome extends Component {
  render() {
    return (
      <AdminPage title="Admin Panel">
        <div className="nav-blocks">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <Link className="nav-block" to="/admin-panel/properties">
                <i className="fas fa-home" />
                <div className="header">
                  <h3 className="heading">
                    Properties <span>&rarr;</span>
                  </h3>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link className="nav-block" to="/admin-panel/users">
                <i className="fas fa-users" />
                <div className="header">
                  <h3 className="heading">
                    Users <span>&rarr;</span>
                  </h3>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link className="nav-block" to="/admin-panel/enquiries">
                <i className="fas fa-home" />
                <div className="header">
                  <h3 className="heading">
                    Enquiries <span>&rarr;</span>
                  </h3>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link className="nav-block" to="/admin-panel/tenants">
                <i className="fas fa-bed" />
                <div className="header">
                  <h3 className="heading">
                    Tenants <span>&rarr;</span>
                  </h3>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link className="nav-block" to="/admin-panel/tenant-enquiries">
                <i className="fas fa-address-book" />
                <div className="header">
                  <h3 className="heading">
                    Tenant Enquiries <span>&rarr;</span>
                  </h3>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link className="nav-block" to="/admin-panel/payments">
                <i className="fas fa-wallet" />
                <div className="header">
                  <h3 className="heading">
                    Payments <span>&rarr;</span>
                  </h3>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link className="nav-block" to="/admin-panel/payments-summary">
                <i className="fas fa-list" />
                <div className="header">
                  <h3 className="heading">
                    Payments Summary <span>&rarr;</span>
                  </h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </AdminPage>
    );
  }
}

export default AdminHome;
