import React, { Component } from 'react';
import Moment from 'react-moment';
import { updateEnquiryStatus } from '../../actions/enquiryActions';
import { connect } from 'react-redux';

class EnquiryContent extends Component {
  constructor() {
    super();

    this.state = {
      editStatus: false,
      status: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ status: this.props.enquiry.status });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.updateEnquiryStatus(this.props.enquiry._id, {
      status: this.state.status
    });

    this.setState({ editStatus: false });
  }

  render() {
    const { enquiry } = this.props;

    return (
      <div className="admin-section">
        <div className="row">
          <div className="col-md-4">
            <div className="section-info">
              <h2 className="heading">Enquiry Details</h2>
              <p>
                These enquiry details have been sent to your email. This is just
                an alternative view.
              </p>
            </div>
          </div>

          <div className="col-md-8">
            <div className="section-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Name</h4>
                    <p>{enquiry.name}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Email</h4>
                    <p>
                      <a href={`mailto:${enquiry.email}`}>{enquiry.email}</a>
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Phone</h4>
                    <p>{enquiry.phone ? enquiry.phone : '-'}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Property</h4>
                    <p>{enquiry.property ? enquiry.property.name : '-'}</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Flat</h4>
                    <p>
                      {enquiry.room
                        ? `${
                            enquiry.room.flat
                              ? `${enquiry.room.flat.name}, `
                              : null
                          } ${enquiry?.room?.name}`
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Date</h4>
                    <p>
                      <Moment format="MMMM Do YYYY, h:mm a">
                        {enquiry.date}
                      </Moment>
                    </p>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="meta-section">
                    <h4>Message</h4>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{enquiry.message}</p>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="meta-section">
                    <h4>Status</h4>
                    {!this.state.editStatus && (
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        {enquiry.status}{' '}
                        <button
                          className="edit-link ml-3"
                          onClick={() => this.setState({ editStatus: true })}
                        >
                          Edit Status
                        </button>
                      </p>
                    )}

                    {this.state.editStatus && (
                      <div className="edit-status">
                        <select
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                        >
                          <option value="Awaiting contact">
                            Awaiting contact
                          </option>
                          <option value="Awaiting viewing">
                            Awaiting viewing
                          </option>
                          <option value="Contacted - awaiting response">
                            Contacted - awaiting response
                          </option>
                          <option value="Accepted - booked">
                            Accepted - booked
                          </option>
                          <option value="Declined – just enquiring">
                            Declined – just enquiring
                          </option>
                          <option value="Declined – didn’t like property">
                            Declined – didn’t like property
                          </option>
                          <option value="Declined – cost">
                            Declined – cost
                          </option>
                        </select>

                        <button
                          className="edit-link ml-3"
                          onClick={this.onSubmit}
                        >
                          Save Status
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { updateEnquiryStatus }
)(EnquiryContent);
