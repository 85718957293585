import React, { Component } from 'react';
import axios from 'axios';

// import components
import Modal from 'react-bootstrap/Modal';

class SetPassword extends Component {
  constructor() {
    super();

    this.state = {
      password: '',
      password2: '',
      success: false,
      loading: false,
      errors: {},
      showModal: false
    };

    this.onChange = this.onChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleHide() {
    this.setState({
      password: '',
      password2: '',
      success: false,
      errors: {},
      showModal: false
    });
  }

  onSubmit(e) {
    const { userID } = this.props;
    e.preventDefault();
    this.setState({ loading: true });

    const data = {
      password: this.state.password,
      password2: this.state.password2
    };

    axios
      .post(`/api/auth/set-password/${userID}`, data)
      .then(confirm => {
        console.log(confirm.data);
        this.setState({ success: true, loading: false });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  }

  render() {
    const { errors, success } = this.state;

    return (
      <React.Fragment>
        <button className="ml-2 btn green-button" onClick={this.handleShow}>
          Reset Password
        </button>

        <Modal show={this.state.showModal} onHide={this.handleHide} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Rest user password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!success ? (
              <div className="edit-section">
                <div className="row">
                  <div className="col-md-6">
                    <div className="field">
                      <h4 className="heading">New Password</h4>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChange}
                      />

                      {errors.password && (
                        <small className="text-danger">{errors.password}</small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h4 className="heading">Confirm Password</h4>
                      <input
                        type="password"
                        name="password2"
                        value={this.state.password2}
                        onChange={this.onChange}
                      />

                      {errors.password2 && (
                        <small className="text-danger">
                          {errors.password2}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="success">
                <p className="heading mb-0">
                  Password reset successful, the user can now log in with the
                  new password.
                </p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!success ? (
              <button className="btn green-button" onClick={this.onSubmit}>
                Change password
              </button>
            ) : (
              <button className="btn green-button" onClick={this.handleHide}>
                Close
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SetPassword;
