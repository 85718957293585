import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

class UnpaidPaymentCard extends Component {
  render() {
    const { payment } = this.props;
    return (
      <div className="payment-wrapper">
        <Link
          to={`/user-panel/pay/${payment._id}`}
          className="payment-card button-hover"
        >
          <div className="pc-header">
            <div className="amount-card">
              <h5 className="amount">£{payment.amount.toFixed(2)}</h5>
            </div>

            <h5 className="heading">{payment.title}</h5>
          </div>

          {payment.description && (
            <div className="attribute">
              <h6 className="heading">Description</h6>
              <p>{payment.description}</p>
            </div>
          )}

          {payment.reference && (
            <div className="attribute">
              <h6 className="heading">Reference</h6>
              <p>{payment.reference}</p>
            </div>
          )}

          {payment.dueDate && (
            <div className="attribute">
              <h6 className="heading">Due Date</h6>
              <p>
                <Moment format="DD/MM/YYYY">{payment.dueDate}</Moment>
              </p>
            </div>
          )}

          {payment.date && (
            <div className="attribute">
              <h6 className="heading">Date Created</h6>
              <p>
                <Moment format="DD/MM/YYYY">{payment.date}</Moment>
              </p>
            </div>
          )}

          <div className="footer">
            <h5>Pay Now &rarr;</h5>
          </div>
        </Link>
        {/* // </form> */}
      </div>
    );
  }
}

export default UnpaidPaymentCard;
