import React, { Component } from 'react';
import axios from 'axios';
import isEmpty from '../../validation/is-empty';
import countryList from 'country-list';

// import components
import PanelPage from './PanelPage';
import SageLogo from '../../img/sagepay-logo2.png';

let checkout;

export default class Pay extends Component {
  constructor() {
    super();

    this.state = {
      paymentError: false,
      keyValid: true,
      payment: {},
      merchantSessionKey: '',
      error: '',
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      country: '',
      paymentSent: false,
      paymentSuccess: false,
      paymentLoading: false,
      secure3d: false,
      acsUrl: '',
      paReq: '',
      transactionId: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    axios
      .get(
        `/api/payments/merchant-session/${this.props.match.params.paymentID}`
      )
      .then(res => {
        this.setState({
          merchantKey: res.data.merchantSessionKey,
          payment: res.data.payment
        });

        checkout = window.sagepayCheckout({
          merchantSessionKey: this.state.merchantKey,
          onTokenise: tokenisationResult => this.onTokenise(tokenisationResult)
        });

        checkout.form();
      })
      .catch(err => {
        console.log(err);
        this.setState({ paymentError: true });
      });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onTokenise(tokenisationResult) {
    this.setState({ paymentLoading: true });

    if (tokenisationResult.success) {
      this.setState({ error: '', keyValid: false });

      const data = {
        payment: this.props.match.params.paymentID,
        merchantSessionKey: this.state.merchantKey,
        cardIdentifier: tokenisationResult.cardIdentifier,
        customerFirstName: this.state.firstName,
        customerLastName: this.state.lastName,
        billingAddress: {
          address1: this.state.addressLine1,
          address2: this.state.addressLine2,
          city: this.state.city,
          postalCode: this.state.postalCode,
          country: this.state.country
        }
      };

      axios
        .post('/api/payments/confirm-sage', data)
        .then(res => {
          if (res.data.statusCode === '2007') {
            return this.setState({
              secure3d: true,
              acsUrl: res.data.acsUrl,
              paReq: res.data.paReq,
              transactionId: res.data.transactionId
            });
          }

          if (res.data.success) {
            this.setState({
              paymentSent: true,
              paymentSuccess: true,
              paymentLoading: false
            });
          } else if (!res.data.success) {
            this.setState({
              paymentSent: true,
              paymentSuccess: false,
              paymentLoading: false
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({
        keyValid: false,
        paymentLoading: false,
        error:
          'Authentication failure, please check that your card details are correct. If this problem persists, please contact the Student Digs team, via the contact page.'
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ error: false });
    const {
      firstName,
      lastName,
      addressLine1,
      city,
      postalCode,
      country
    } = this.state;

    if (
      isEmpty(firstName) ||
      isEmpty(lastName) ||
      isEmpty(addressLine1) ||
      isEmpty(city) ||
      isEmpty(postalCode) ||
      isEmpty(country)
    ) {
      return this.setState({
        error: 'You must fill out all of your personal information',
        paymentLoading: false
      });
    }

    if (this.state.keyValid) {
      return checkout.tokenise();
    } else if (!this.state.keyValid) {
      axios
        .get(
          `/api/payments/merchant-session/${this.props.match.params.paymentID}`
        )
        .then(res => {
          this.setState({
            merchantKey: res.data.merchantSessionKey,
            payment: res.data.payment
          });

          return checkout.tokenise({
            newMerchantSessionKey: this.state.merchantKey
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({ paymentError: true, paymentLoading: false });
        });
    }
  }

  render() {
    const { payment, paymentSuccess, paymentSent } = this.state;
    const countries = countryList.getData();

    if (this.state.paymentError) {
      return (
        <PanelPage title="Payments" className="user-payments">
          <div className="header">
            <h1 className="heading">Problem finding payment</h1>
          </div>

          <p>
            Either this payment has already been paid, been deleted or there was
            a problem finding it. If you're expecting to find an invoice to pay,
            please ensure you're logged in to your Student Digs account, if this
            doesn't help, please contact the Student Digs team via the contact
            page.
          </p>
        </PanelPage>
      );
    }

    if (paymentSuccess && paymentSent) {
      return (
        <PanelPage title="Payments" className="user-payments">
          <div className="header">
            <h1 className="heading">Payment successful!</h1>
          </div>

          <p>
            Your payment has been successfully received, if you have any further
            queries, please get in contact with the Student Digs team!
          </p>
        </PanelPage>
      );
    }

    if (paymentSent && !paymentSuccess) {
      return (
        <PanelPage title="Payments" className="user-payments">
          <div className="header">
            <h1 className="heading">Payment unsuccessful!</h1>
          </div>

          <p>
            Your payment was unfortunately unsuccessful, please review your
            details and try again. If the problem persists, please get in
            contact with the Student Digs team via the contact page.
          </p>

          <p>
            If you have previously attempted to confirm a payment but something
            went wrong, you may have to wait 15 minutes before trying again,
            this is a fraud prevention security feature.
          </p>

          <button
            onClick={() => window.location.reload()}
            className="btn green-button"
          >
            Retry
          </button>
        </PanelPage>
      );
    }

    return (
      <PanelPage title="Payments" className="pay-invoice">
        <div className="header">
          <h1 className="heading">Pay your invoice</h1>
        </div>

        <div className="invoice-information">
          <div className="info-section">
            <h5 className="heading">Payment Title</h5>
            <p>{payment.title}</p>
          </div>

          <div className="info-section">
            <h5 className="heading">Reference</h5>
            <p>{payment.reference}</p>
          </div>

          <div className="info-section">
            <h5 className="heading">Amount</h5>
            <p>£{!isEmpty(payment) && payment.amount.toFixed(2)}</p>
          </div>

          {!isEmpty(payment.description) && (
            <div className="info-section">
              <h5 className="heading">Description</h5>
              <p>{payment.description}</p>
            </div>
          )}
        </div>

        <div className="payment-details">
          <div className="sub-header">
            <h3 className="subheading">Your personal information</h3>
          </div>

          <form>
            <div className="personal-info">
              <div className="field">
                <label>Name</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name*"
                  required
                  value={this.state.firstName}
                  onChange={this.onChange}
                />
              </div>

              <div className="field">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name*"
                  required
                  value={this.state.lastName}
                  onChange={this.onChange}
                />
              </div>

              <div className="field">
                <label>Billing Address</label>
                <input
                  placeholder="Address Line 1*"
                  type="text"
                  name="addressLine1"
                  required
                  value={this.state.addressLine1}
                  onChange={this.onChange}
                />
              </div>

              <div className="field">
                <input
                  placeholder="Address Line 2"
                  type="text"
                  name="addressLine2"
                  value={this.state.addressLine2}
                  onChange={this.onChange}
                />
              </div>

              <div className="field">
                <input
                  placeholder="City*"
                  type="text"
                  name="city"
                  required
                  value={this.state.city}
                  onChange={this.onChange}
                />
              </div>

              <div className="field">
                <input
                  placeholder="Postal Code*"
                  type="text"
                  name="postalCode"
                  required
                  value={this.state.postalCode}
                  onChange={this.onChange}
                />
              </div>

              <div className="field">
                <select
                  placeholder="Country*"
                  type="text"
                  name="country"
                  required
                  value={this.state.country}
                  onChange={this.onChange}
                >
                  <option value="">Country*</option>
                  {countries.map(country => (
                    <option value={country.code} key={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sub-header">
              <h3 className="subheading">Your card details</h3>
              <p>
                Your card details are not stored or collected by Student Digs.
                Card details and payments are processed securely by Sage Pay.
              </p>
            </div>

            {!this.state.secure3d && <div id="sp-container"></div>}

            {this.state.error ? (
              <p className="text-danger">{this.state.error}</p>
            ) : null}

            {this.state.secure3d && (
              <iframe
                title="3D Secure"
                src={`/user-panel/secure?paReq=${this.state.paReq}&acsUrl=${this.state.acsUrl}&paymentId=${this.props.match.params.paymentID}&transactionId=${this.state.transactionId}`}
                width="100%"
                height="500px"
              ></iframe>
            )}

            <div className="submit-payment">
              <div className="button-wrapper">
                {!this.state.paymentLoading ? (
                  <button
                    type="submit"
                    className="btn green-button"
                    onClick={this.onSubmit}
                  >
                    Submit Payment
                  </button>
                ) : (
                  <button disabled className="btn green-button loading">
                    <i className="fas fa-circle-notch fa-spin" />
                  </button>
                )}
              </div>

              <div className="processed-by">
                <p>Payments by</p>
                <img src={SageLogo} alt="Sage Pay Logo" />
              </div>
            </div>
          </form>
        </div>
      </PanelPage>
    );
  }
}
