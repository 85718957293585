import React, { Component } from 'react';

// import components
import MapTab from './MapTab';
import DescriptionTab from './DescriptionTab';

class PropertyInfo extends Component {
  render() {
    const { property, loading } = this.props;
    return (
      <div className="property-info">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="description-tab"
              data-toggle="tab"
              href="#description"
              role="tab"
              aria-controls="description"
              aria-selected="true"
            >
              Description
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="map-tab"
              data-toggle="tab"
              href="#map"
              role="tab"
              aria-controls="map"
              aria-selected="false"
            >
              Map
            </a>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <DescriptionTab property={property} loading={loading} />

          <MapTab property={property} loading={loading} />
        </div>
      </div>
    );
  }
}

export default PropertyInfo;
