import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  getUnpaidPayments,
  getPaidPayments,
  getProcessingPayments
} from '../../actions/paymentActions';

// import components
import PanelPage from './PanelPage';
import UnpaidPaymentCard from './UnpaidPaymentCard';
import PaymentCard from './PaymentCard';
import Spinner from '../common/Spinner';
import isEmpty from '../../validation/is-empty';

class Payments extends Component {
  constructor() {
    super();

    this.state = {
      paidCount: 2,
      processingCount: 2
    };

    this.showMore = this.showMore.bind(this);
  }

  componentDidMount() {
    this.props.getUnpaidPayments();
    this.props.getPaidPayments();
    this.props.getProcessingPayments();
  }

  showMore(e) {
    e.preventDefault();

    console.log(e.target.name);

    this.setState({ [e.target.name]: this.state[e.target.name] + 2 });
  }

  render() {
    const { unpaid, paid, processing, loading } = this.props.payments;
    const { paidCount, processingCount } = this.state;
    let unpaidContent,
      paidContent,
      processingContent = null;

    if (!loading) {
      if (unpaid.length > 0) {
        unpaidContent = (
          <div className="payments-section">
            <h2 className="heading">Unpaid Invoices</h2>

            <p>
              Once we have received and processed payments your invoices will be
              moved to 'Paid'
            </p>

            {unpaid.map(payment => (
              <UnpaidPaymentCard key={payment._id} payment={payment} />
            ))}
          </div>
        );
      }

      if (paid.length > 0) {
        paidContent = (
          <div className="payments-section">
            <h2 className="heading">Paid Invoices</h2>

            {paid.slice(0, paidCount).map(payment => (
              <PaymentCard key={payment._id} payment={payment} />
            ))}

            {paidCount < paid.length && (
              <button
                name="paidCount"
                className="show-button"
                onClick={this.showMore}
              >
                Show More
              </button>
            )}
          </div>
        );
      }

      if (processing.length > 0) {
        processingContent = (
          <div className="payments-section">
            <h2 className="heading">Processing Invoices</h2>

            {processing.slice(0, processingCount).map(payment => (
              <PaymentCard key={payment._id} payment={payment} />
            ))}

            {processingCount < processing.length && (
              <button
                name="processingCount"
                className="show-button"
                onClick={this.showMore}
              >
                Show More
              </button>
            )}
          </div>
        );
      }
    }

    return (
      <PanelPage title="Payments" className="user-payments">
        <Helmet>
          <title>Payments</title>
        </Helmet>

        <div className="header">
          <h1 className="heading">Student Digs Payments</h1>
        </div>

        {loading && <Spinner />}
        {!loading && isEmpty(paid) && isEmpty(unpaid) && isEmpty(processing) && (
          <div className="no-content">
            <p> There are currently no payments to display</p>
          </div>
        )}
        {unpaidContent}
        {processingContent}
        {paidContent}
      </PanelPage>
    );
  }
}

const mapStateToProps = state => ({
  payments: state.payments
});

export default connect(
  mapStateToProps,
  { getUnpaidPayments, getPaidPayments, getProcessingPayments }
)(Payments);
