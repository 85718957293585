import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteRoom, editRoom } from "../../actions/propertyActions";
import { clearErrors } from "../../actions/errorActions";
import isEmpty from "../../validation/is-empty";

// import components
import ManageTenant from "./ManageTenant";
import ManageFutureTenant from "./ManageFutureTenant";
import ManageOtherTenants from "./ManageOtherTenants";

class RoomModal extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      price: "",
      type: "",
      available: true,
      futureAvailability: true,
      updated: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  componentDidMount() {
    const { room } = this.props;

    this.setState({
      name: room?.name,
      price: room?.price,
      type: room?.type,
      available: room?.available,
      futureAvailability: room?.futureAvailability,
    });
  }

  onChange(e) {
    e.preventDefault();

    if (e.target.name === "featured") {
      const isTrue = e.target.value === "true";

      this.setState({ featured: isTrue, updated: false });
    } else {
      this.setState({ [e.target.name]: e.target.value, updated: false });
    }
  }

  onDelete(e) {
    const { room } = this.props;
    e.preventDefault();

    this.props.clearErrors();
    this.props.deleteRoom(room?._id);

    window.$(`#id${room?._id}`).modal("hide");
  }

  onSubmit(e) {
    const { room } = this.props;
    e.preventDefault();

    this.setState({ updated: false });

    const roomData = {
      name: this.state.name,
      price: this.state.price,
      type: this.state.type,
      available: this.state.available,
      futureAvailability: this.state.futureAvailability,
    };

    this.props.editRoom(room?._id, roomData);
  }

  modalClose(e) {
    const { room } = this.props;

    window.$(`#id${room?._id}`).modal("hide");
    this.props.clearErrors();
  }

  componentWillReceiveProps(nextProps) {
    const { room } = this.props;
    if (room?.updated !== nextProps?.room?.updated) {
      this.setState({ updated: true });
      window.$(`#id${room?._id}`).modal("hide");
      this.props.clearErrors();
    }

    if (room?.tenant !== nextProps?.room?.tenant) {
      if (!isEmpty(nextProps?.room?.tenant)) {
        this.setState({ available: false });
      } else {
        this.setState({ available: true });
      }
    }

    if (room?.futureTenant !== nextProps?.room?.futureTenant) {
      if (!isEmpty(nextProps?.room?.futureTenant)) {
        this.setState({ futureAvailability: false });
      } else {
        this.setState({ futureAvailability: true });
      }
    }
  }

  render() {
    const { room } = this.props;

    return (
      <div
        className="modal fade"
        id={`id${room?._id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit {room?.name}{" "}
                <code className="small">Unique ID: {room?._id}</code>
              </h5>
              <button
                type="button"
                className="close"
                onClick={this.modalClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="edit-section">
                <h3 className="heading">General Info</h3>

                <div className="row">
                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Room Name</h5>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Price</h5>
                      <input
                        type="text"
                        name="price"
                        value={this.state.price}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Type</h5>
                      <input
                        type="text"
                        name="type"
                        value={this.state.type}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Currently Available?</h5>
                      <select
                        name="available"
                        value={this.state.available}
                        onChange={this.onChange}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Future Availability?</h5>
                      <select
                        name="futureAvailability"
                        value={this.state.futureAvailability}
                        onChange={this.onChange}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <ManageTenant
                tenant={this.props?.room?.tenant}
                room={this.props?.room}
              />

              <ManageFutureTenant
                tenant={this.props?.room?.futureTenant}
                room={this.props?.room}
              />

              <ManageOtherTenants
                tenants={this.props?.room?.otherTenants}
                room={this.props?.room}
              />

              <div className="edit-section">
                <h3 className="heading">Delete Room</h3>

                <p>
                  Deleting rooms is permanent and cannot be undone! Room data
                  cannot be recovered.
                </p>

                <button className="btn red-button" onClick={this.onDelete}>
                  Delete Room
                </button>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn green-button"
                onClick={this.onSubmit}
              >
                Update Room
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { deleteRoom, editRoom, clearErrors })(
  RoomModal
);
