import axios from "axios";

import {
  GET_FEATURED_PROPERTIES,
  PROPERTIES_LOADING,
  GET_ALL_PROPERTIES,
  GET_PROPERTY,
  UPDATE_PROPERTY,
  ADD_ROOM,
  UPLOAD_IMAGE,
  DELETE_IMAGE,
  ADD_PROPERTY,
  DELETE_PROPERTY,
  ADD_FLAT,
  EDIT_FLAT,
  EDIT_ROOM,
  DELETE_FLAT,
  DELETE_ROOM,
  GET_ERRORS,
  CLEAR_PROPERTIES,
  ADMIN_ALL_PROPERTIES,
  GET_SAVED_PROPERTIES,
  GET_SAVED_PROPERTIES_COUNT,
  SAVED_PROPERTIES_LOADING,
  CLEAR_ERRORS,
  SINGLE_PROPERTY_LOADING,
} from "./types";

// get featured properties
export const getFeaturedProperties = () => (dispatch) => {
  axios
    .get("/api/properties/featured")
    .then((res) => {
      dispatch({
        type: GET_FEATURED_PROPERTIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FEATURED_PROPERTIES,
        payload: [],
      });
    });
};

// get all properties
export const getAllProperties = (query) => (dispatch) => {
  dispatch(setPropertiesLoading());
  let url;

  if (query) {
    url = `/api/properties${query}`;
  } else {
    url = `/api/properties`;
  }

  axios
    .get(`${url}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_PROPERTIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ALL_PROPERTIES,
        payload: [],
      });
    });
};

// admin get all properties
export const adminGetAllProperties = () => (dispatch) => {
  dispatch(setPropertiesLoading());

  axios
    .get(`/api/properties/all`)
    .then((res) => {
      dispatch({
        type: ADMIN_ALL_PROPERTIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ADMIN_ALL_PROPERTIES,
        payload: [],
      });
    });
};

// add new property
export const addProperty = (propertyData) => (dispatch) => {
  axios
    .post("/api/properties/new", propertyData)
    .then((res) => {
      dispatch({
        type: ADD_PROPERTY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// add new flat
export const addFlat = (flatData) => (dispatch) => {
  axios
    .post(`/api/flats/new?property=${flatData.property}`, flatData)
    .then((res) => {
      dispatch({
        type: ADD_FLAT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// edit / update flats
export const editFlat = (flatId, flatData) => (dispatch) => {
  axios
    .post(`/api/flats/edit/${flatId}`, flatData)
    .then((res) => {
      dispatch({
        type: EDIT_FLAT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// edit / update room
export const editRoom = (roomId, roomData) => (dispatch) => {
  axios
    .post(`/api/rooms/edit/${roomId}`, roomData)
    .then((res) => {
      dispatch({
        type: EDIT_ROOM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// get single property
export const getProperty = (propertyId) => (dispatch) => {
  dispatch(setSinglePropertyLoading());
  dispatch({ type: CLEAR_ERRORS });

  axios
    .get(`/api/properties/single/${propertyId}`)
    .then((res) => {
      dispatch({
        type: GET_PROPERTY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PROPERTY,
        payload: {},
      });
    });
};

// get property by slug
export const getPropertyBySlug = (propertySlug) => (dispatch) => {
  dispatch(setSinglePropertyLoading());

  axios
    .get(`/api/properties/slug/${propertySlug}`)
    .then((res) => {
      dispatch({
        type: GET_PROPERTY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PROPERTY,
        payload: {},
      });
    });
};

// update property
export const updateProperty = (propertyId, propertyData) => (dispatch) => {
  axios
    .post(`/api/properties/edit/${propertyId}`, propertyData)
    .then((res) => {
      dispatch({
        type: UPDATE_PROPERTY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// add room
export const addRoomToProperty = (propertyId, flatId, roomData) => (
  dispatch
) => {
  axios
    .post(`/api/rooms/new?property=${propertyId}&flat=${flatId}`, roomData)
    .then((res) => {
      dispatch({
        type: ADD_ROOM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// add individual room
export const addIndividualRoomToProperty = (propertyID, roomData) => (
  dispatch
) => {
  axios
    .post(`/api/rooms/new?property=${propertyID}`, roomData)
    .then((res) => {
      dispatch({
        type: ADD_ROOM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// upload image
export const uploadImage = (propertyId, image) => (dispatch) => {
  axios
    .post(`/api/media/property-image/${propertyId}`, image)
    .then((res) => {
      dispatch({
        type: UPLOAD_IMAGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// delete image
export const deleteImage = (propertyId, mediaKey) => (dispatch) => {
  axios
    .delete(`/api/media/property-image/${propertyId}/${mediaKey}`)
    .then((res) => {
      dispatch({
        type: DELETE_IMAGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// delete property
export const deleteProperty = (propertyId) => (dispatch) => {
  if (window.confirm("Are you sure you want to delete this property?")) {
    axios.delete(`/api/properties/${propertyId}`).then((res) => {
      dispatch({
        type: DELETE_PROPERTY,
      });

      window.location.href = "/admin-panel/properties";
    });
  }
};

// delete flat
export const deleteFlat = (flatId) => (dispatch) => {
  if (window.confirm("Are you sure you want to delete this flat?")) {
    axios
      .delete(`/api/flats/${flatId}`)
      .then((res) => {
        window.$(`#editFlat${flatId}`).modal("hide");

        dispatch({
          type: DELETE_FLAT,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  }
};

// delete room
export const deleteRoom = (roomId) => (dispatch) => {
  if (window.confirm("Are you sure you want to delete this room?")) {
    axios
      .delete(`/api/rooms/${roomId}`)
      .then((res) => {
        dispatch({
          type: DELETE_ROOM,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  }
};

// update flat images
// export const updateFlatImages = (flatID, images) => (dispatch) => {
//   axios
//     .post(`/api/flats/flat-images/${flatID}`, images)
//     .then((res) => {
//       dispatch({
//         type: UPDATE_FLAT_IMAGES,
//         payload: images,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data,
//       });
//     });
// };

// save a property to local storage
export const savePropertyToLocalStorage = (propertyId) => (dispatch) => {
  if (localStorage.savedProperties) {
    // turn stored string in to an array
    const storedProperties = JSON.parse(
      localStorage.getItem("savedProperties")
    );

    if (!storedProperties.includes(propertyId)) {
      // push new property to array
      storedProperties.push(propertyId);
    }

    // save new array to local storage
    localStorage.setItem("savedProperties", JSON.stringify(storedProperties));
  } else {
    // create an empty array
    let storedProperties = [];

    // push the property to the empty array
    storedProperties.push(propertyId);

    // set local storage item after turning array into string
    localStorage.setItem("savedProperties", JSON.stringify(storedProperties));
  }

  dispatch(getSavedPropertiesCount());
};

// delete saved property
export const deleteSavedProperty = (propertyId) => (dispatch) => {
  // convert local storage string into an array
  const storedProperties = JSON.parse(localStorage.getItem("savedProperties"));

  // find remove index
  let removeIndex = storedProperties.indexOf(propertyId);

  // if remove index ecists, splice from array
  if (removeIndex !== -1) storedProperties.splice(removeIndex, 1);

  // return local storage
  localStorage.setItem("savedProperties", JSON.stringify(storedProperties));

  dispatch(getSavedProperties());
  dispatch(getSavedPropertiesCount());
};

// get properties saved in local storage
export const getSavedProperties = () => (dispatch) => {
  dispatch(setSavedPropertiesLoading());
  dispatch(getSavedPropertiesCount());

  // turn stored properties in to array of properties
  const storedProperties = JSON.parse(localStorage.getItem("savedProperties"));

  axios
    .post("/api/properties/saved-properties", { storedProperties })
    .then((res) => {
      dispatch({
        type: GET_SAVED_PROPERTIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SAVED_PROPERTIES,
        payload: [],
      });
    });
};

// get properties saved in local storage
export const getSavedPropertiesCount = () => (dispatch) => {
  // turn stored properties in to array of properties
  const storedProperties = JSON.parse(localStorage.getItem("savedProperties"));

  dispatch({
    type: GET_SAVED_PROPERTIES_COUNT,
    payload: storedProperties ? storedProperties.length : 0,
  });
};

// set properties loading
export const setPropertiesLoading = () => {
  return {
    type: PROPERTIES_LOADING,
  };
};

// set saved properties loading
export const setSavedPropertiesLoading = () => {
  return {
    type: SAVED_PROPERTIES_LOADING,
  };
};

// set properties loading
export const clearProperties = () => {
  return {
    type: CLEAR_PROPERTIES,
  };
};

// set single property loading
export const setSinglePropertyLoading = () => {
  return {
    type: SINGLE_PROPERTY_LOADING,
  };
};
