import axios from 'axios';

// import types
import {
  GET_ALL_ENQUIRIES,
  ENQUIRIES_LOADING,
  GET_ENQUIRY,
  GET_TENANT_ENQUIRIES,
  GET_TENANT_ENQUIRY,
  DELETE_TENANT_ENQUIRY,
  GET_ERRORS,
  DELETE_ENQUIRY,
  UPDATE_ENQUIRY_STATUS
} from './types';

// get all enquiries
export const getAllEnquiries = () => dispatch => {
  setEnquiriesLoading();

  axios
    .get(`/api/contact/enquiries`)
    .then(res => {
      dispatch({
        type: GET_ALL_ENQUIRIES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_ENQUIRIES,
        payload: []
      });
    });
};

// get single enquiry
export const getEnquiry = enquiryId => dispatch => {
  setEnquiriesLoading();

  axios
    .get(`/api/contact/enquiry/${enquiryId}`)
    .then(res => {
      dispatch({
        type: GET_ENQUIRY,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ENQUIRY,
        payload: {}
      });
    });
};

export const getTenantEnquiries = () => dispatch => {
  setEnquiriesLoading();

  axios
    .get('/api/contact/tenant-enquiries')
    .then(res => {
      dispatch({
        type: GET_TENANT_ENQUIRIES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_TENANT_ENQUIRIES,
        payload: []
      });
    });
};

export const getTenantEnquiry = enquiryID => dispatch => {
  setEnquiriesLoading();

  axios
    .get(`/api/contact/tenant-enquiry/${enquiryID}`)
    .then(res => {
      dispatch({
        type: GET_TENANT_ENQUIRY,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_TENANT_ENQUIRY,
        payload: {}
      });
    });
};

export const deleteTenantEnquiry = enquiryID => dispatch => {
  if (window.confirm('Are you sure you want to delete this enquiry?')) {
    axios
      .delete(`/api/contact/tenant-enquiry/${enquiryID}`)
      .then(res => {
        dispatch({
          type: DELETE_TENANT_ENQUIRY,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

export const deleteEnquiry = enquiryID => dispatch => {
  if (window.confirm('Are you sure you want to delete this enquiry?')) {
    axios
      .delete(`/api/contact/enquiry/${enquiryID}`)
      .then(res => {
        dispatch({
          type: DELETE_ENQUIRY,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

export const updateEnquiryStatus = (enquiryID, status) => dispatch => {
  console.log(status);
  axios
    .post(`/api/contact/status/${enquiryID}`, status)
    .then(res => {
      dispatch({ type: UPDATE_ENQUIRY_STATUS, payload: res.data });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const updateTenantEnquiryStatus = (enquiryID, status) => dispatch => {
  console.log(status);
  axios
    .post(`/api/contact/status/tenant/${enquiryID}`, status)
    .then(res => {
      dispatch({ type: UPDATE_ENQUIRY_STATUS, payload: res.data });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// set properties loading
export const setEnquiriesLoading = () => {
  return {
    type: ENQUIRIES_LOADING
  };
};
