import {
  GET_FOR_RESOURCES,
  GET_UPLOADED_RESOURCES,
  RESOURCES_LOADING,
  UPLOAD_RESOURCE,
  ADMIN_UPLOAD_RESOURCE,
  CLEAR_ALL_RESOURCES,
  UPLOADING_RESOURCE
} from '../actions/types';

const initialState = {
  uploadedResources: [],
  forResources: [],
  loading: false,
  uploading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FOR_RESOURCES:
      return {
        ...state,
        forResources: action.payload,
        loading: false
      };
    case GET_UPLOADED_RESOURCES:
      return {
        ...state,
        uploadedResources: action.payload,
        loading: false
      };
    case RESOURCES_LOADING:
      return {
        ...state,
        loading: true
      };
    case UPLOAD_RESOURCE:
      return {
        ...state,
        uploadedResources: [action.payload, ...state.uploadedResources],
        uploading: false
      };
    case UPLOADING_RESOURCE:
      return {
        ...state,
        uploading: true
      };
    case ADMIN_UPLOAD_RESOURCE:
      return {
        ...state,
        forResources: [...action.payload, ...state.forResources],
        loading: false
      };
    case CLEAR_ALL_RESOURCES:
      return {
        ...state,
        uploadedResources: [],
        forResources: []
      };
    default:
      return state;
  }
}
