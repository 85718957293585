import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  getForResources,
  getUploadedResources,
  deleteResource,
  clearAllResources
} from '../../actions/resourcesActions';
import axios from 'axios';
import { ReactComponent as FileIcon } from './file.svg';
import Moment from 'react-moment';

// import components
import PanelPage from './PanelPage';
import UploadResource from './UploadResource';
import isEmpty from '../../validation/is-empty';
import Spinner from '../common/Spinner';

class Resources extends Component {
  componentDidMount() {
    this.props.getForResources();
    this.props.getUploadedResources();
  }

  componentWillUnmount() {
    this.props.clearAllResources();
  }

  getDownloadLink(key) {
    axios.get(`/api/media/resource-url/${key}`).then(res => {
      window.open(res.data.url);
    });
  }

  deleteResource = resourceId => {
    this.props.deleteResource(resourceId);
  };

  render() {
    const { forResources, uploadedResources, loading } = this.props.resources;
    let forResourcesContent, uploadedResourcesContent;

    if (loading) {
      forResourcesContent = <Spinner />;
      uploadedResourcesContent = <Spinner />;
    }

    if (isEmpty(uploadedResources)) {
      uploadedResourcesContent = (
        <li className="no-content">No resources found...</li>
      );
    } else {
      uploadedResourcesContent = uploadedResources.map(resource => (
        <li key={resource._id}>
          <button
            className="delete-icon"
            title="Delete Resource"
            onClick={() => this.deleteResource(resource._id)}
          >
            <span className="fa-stack fa-2x">
              <i className="fas fa-circle fa-stack-2x" />
              <i className="fas fa-times fa-stack-1x fa-inverse" />
            </span>
          </button>
          <button
            title={resource.fileName}
            onClick={() => this.getDownloadLink(resource.key)}
          >
            <FileIcon />
            <p className="file-name">{resource.fileName}</p>
            <p className="date">
              <i className="far fa-clock" />
              <Moment format="DD/MM/YYYY">{resource.date}</Moment>
            </p>
          </button>
        </li>
      ));
    }

    if (isEmpty(forResources)) {
      forResourcesContent = (
        <li className="no-content">No resources found...</li>
      );
    } else {
      forResourcesContent = forResources.map(resource => (
        <li key={resource._id}>
          <button
            onClick={() => this.getDownloadLink(resource.key)}
            title={resource.name}
          >
            <FileIcon />
            <p className="file-name">{resource.fileName}</p>
            <p className="date">
              <i className="far fa-clock" />
              <Moment format="DD/MM/YYYY">{resource.date}</Moment>
            </p>
          </button>
        </li>
      ));
    }

    return (
      <PanelPage>
        <Helmet>
          <title>Resource Center</title>
        </Helmet>

        <div className="header">
          <h1 className="heading">Resource Center</h1>
        </div>

        <div className="resources for-resources">
          <h3 className="heading">Your Resources</h3>
          <p>
            These are resources that have been uploaded by the Student Digs team
            and may need reviewing!
          </p>

          <ul className="resource-list">{forResourcesContent}</ul>
        </div>

        <div className="resources upload-resources">
          <h3 className="heading">Your Uploaded Resources</h3>
          <p>
            These are the resources that have been uploaded by you for the
            Student Digs team to review!
          </p>

          <ul className="resource-list">{uploadedResourcesContent}</ul>
        </div>

        <UploadResource />
      </PanelPage>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.resources
});

export default connect(
  mapStateToProps,
  { getForResources, getUploadedResources, deleteResource, clearAllResources }
)(Resources);
