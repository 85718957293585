import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getSinglePayment,
  deletePayment,
  clearSinglePayment
} from '../../actions/paymentActions';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';

// import components
import AdminPage from '../admin/AdminPage';
import Spinner from '../common/Spinner';
import isEmpty from '../../validation/is-empty';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import EditPayment from '../admin-users/EditPayment';

class ManagePayment extends Component {
  constructor() {
    super();

    this.state = {
      deleted: false
    };

    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.props.getSinglePayment(this.props.match.params.paymentID);
  }

  componentWillUnmount() {
    this.props.clearSinglePayment();
  }

  onDelete(e) {
    e.preventDefault();

    this.props.deletePayment(this.props.match.params.paymentID);

    this.setState({ deleted: true });
  }

  render() {
    const payment = this.props.payments.single;
    const { loading } = this.props.payments;
    let paymentContent;

    if (this.state.deleted) {
      return <Redirect to="/admin-panel/payments" />;
    }

    if (loading) {
      paymentContent = <Spinner />;
    } else if (!loading && isEmpty(payment)) {
      paymentContent = (
        <p>
          Something's gone wrong, no payment info can be found with this ID.
        </p>
      );
    } else {
      paymentContent = (
        <React.Fragment>
          <div className="admin-section">
            <div className="row">
              <div className="col-md-4">
                <div className="section-info">
                  <h3 className="heading">Payment Information</h3>
                  <p>
                    The following is the payment information, the user can see
                    all of this information inside their user panel. It can be
                    modified if need be.
                  </p>
                </div>
              </div>

              <div className="col-md-8">
                <div className="section-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Payment For</h4>
                        <p title={payment?.user?.email}>{payment?.user?.name}</p>
                        <p>
                          <strong>ID</strong> {payment.user?._id}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Payment ID</h4>
                        <p>{payment._id}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Payment Title</h4>
                        <p>{payment.title}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Reference</h4>
                        <p>{payment.reference}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Amount</h4>
                        <p>£{payment.amount}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Date Created</h4>
                        <p>
                          <Moment format="DD/MM/YYYY">{payment.date}</Moment>
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="meta-section">
                        <h4>Description</h4>
                        <p>{payment.description}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>
                          Processing?{' '}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                If payments are automatically marked as
                                processing, this is usually because we think the
                                pricing has been tampered with, check that the
                                user has paid the correct amount and then
                                manually mark it as paid.
                              </Tooltip>
                            }
                          >
                            <i className="fas fa-info-circle" />
                          </OverlayTrigger>
                        </h4>
                        <p>{payment.processing ? 'Yes' : 'No'}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Paid?</h4>
                        <p>{payment.paid ? 'Yes' : 'No'}</p>
                      </div>
                    </div>

                    {payment.paid && (
                      <div className="col-md-6">
                        <div className="meta-section">
                          <h4>
                            Payment Date?{' '}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  If an administrator <strong>manually</strong>{' '}
                                  marked a payment as 'paid' there will be{' '}
                                  <strong>no</strong> payment date.
                                </Tooltip>
                              }
                            >
                              <i className="fas fa-info-circle" />
                            </OverlayTrigger>
                          </h4>
                          <p>
                            {payment.paymentDate ? (
                              <Moment format="DD/MM/YYYY">
                                {payment.paymentDate}
                              </Moment>
                            ) : (
                              '-'
                            )}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Last Updated / Edited</h4>
                        <p>
                          {payment.updated ? (
                            <Moment format="DD/MM/YYYY">
                              {payment.updated}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <EditPayment payment={payment} button={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-section">
            <div className="header">
              <h3 className="heading">Delete Payment</h3>
            </div>

            <p>
              Please note, deleting a payment is permanent and cannot be undone!
            </p>

            <button className="btn red-button" onClick={this.onDelete}>
              Delete Payment
            </button>
          </div>
        </React.Fragment>
      );
    }

    return <AdminPage title="Manage Payment">{paymentContent}</AdminPage>;
  }
}

const mapStateToProps = state => ({
  payments: state.payments
});

export default connect(
  mapStateToProps,
  { getSinglePayment, deletePayment, clearSinglePayment }
)(ManagePayment);
