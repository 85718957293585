import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTenantEnquiries } from '../../actions/enquiryActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

// import components
import AdminPage from '../admin/AdminPage';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Spinner from '../common/Spinner';

class ManageTenantEnquiries extends Component {
  componentWillMount() {
    this.props.getTenantEnquiries();
  }

  render() {
    const { enquiries, loading } = this.props.enquiries;
    let enquiryContent;

    const columns = [
      {
        Header: 'Date',
        id: 'created-date',
        accessor: d => moment(d.date).format('DD/MM/YYYY'),
        minResizeWidth: 10
      },
      {
        Header: 'User',
        id: 'from-user',
        accessor: 'user.name',
        minResizeWidth: 10
      },
      {
        Header: 'Email',
        id: 'from-email',
        accessor: 'user.email',
        minResizeWidth: 10
      },
      {
        Header: 'Phone',
        id: 'phone',
        accessor: d => (d.phone ? d.phone : '-'),
        minResizeWidth: 10
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        minResizeWidth: 10
      },
      {
        Header: 'Viewed Online?',
        id: 'read',
        accessor: d => (d.read ? 'Yes' : 'No'),
        className: 'text-center',
        Cell: d =>
          d.value === 'Yes' ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          ),
        minResizeWidth: 10
      },
      {
        Header: 'Status',
        accessor: 'status',
        minResizeWidth: 10
      },
      {
        Header: 'View',
        id: 'view',
        accessor: '_id',
        className: 'text-center',
        Cell: d => (
          <Link
            to={`/admin-panel/tenant-enquiry/${d.value}`}
            className="badge badge-pill"
          >
            View
          </Link>
        ),
        minResizeWidth: 10
      }
    ];

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null && typeof row[id] === 'string') {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }
    };

    const exportHeaders = [
      { label: 'Date Created', key: 'date' },
      { label: 'User Name', key: 'user.name' },
      { label: 'User Email', key: 'user.email' },
      { label: 'User Phone', key: 'phone' },
      { label: 'User ID', key: 'user._id' },
      { label: 'Reason', key: 'reason' },
      { label: 'Message', key: 'message' }
    ];

    if (loading) {
      enquiryContent = <Spinner />;
    } else {
      enquiryContent = (
        <ReactTable
          data={enquiries}
          columns={columns}
          defaultPageSize={10}
          defaultFilterMethod={filterCaseInsensitive}
          filterable
        />
      );
    }

    return (
      <AdminPage title="Manage Tenant Enquiries">
        <p>
          You can sort by column by just selecting the column headers. You can
          also sort by <strong>multiple columns</strong>, hold shift and select
          multiple headings. If you're looking for a specific entry you can
          search inside the white box under the column headings, please note
          this search is <strong>case sensitive.</strong>
        </p>

        {enquiryContent}

        {enquiries ? (
          <div className="page-footer text-right mt-3">
            <CSVLink
              data={enquiries}
              headers={exportHeaders}
              filename={`StudentDigs_tenant_enquiries.csv`}
              className="btn green-button"
            >
              Export as CSV
            </CSVLink>
          </div>
        ) : null}
      </AdminPage>
    );
  }
}

const mapStateToProps = state => ({
  enquiries: state.enquiries
});

export default connect(
  mapStateToProps,
  { getTenantEnquiries }
)(ManageTenantEnquiries);
