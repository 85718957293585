import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllPayments } from "../../actions/paymentActions";
import moment from "moment";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

// import components
import AdminPage from "../admin/AdminPage";
import ReactTable from "react-table";
import "react-table/react-table.css";

class AdminPayments extends Component {
  componentDidMount() {
    this.props.getAllPayments();
  }

  render() {
    const { all } = this.props.payments;

    const columns = [
      {
        Header: "Date Created",
        id: "date-created",
        className: "text-center",
        accessor: (d) => moment(d.date).format("DD/MM/YYYY"),
        minResizeWidth: 10,
      },
      {
        Header: "User",
        id: "user-name",
        accessor: (d) => d?.user?.name,
        Cell: (d) => <span title={d.original.user.email}>{d.value}</span>,
        minResizeWidth: 10,
      },
      {
        Header: "Amount",
        className: "text-center",
        accessor: "amount",
        Cell: (d) => `£${Number(d.value).toFixed(2)}`,
        minResizeWidth: 10,
      },
      {
        Header: "Reference",
        accessor: "reference",
        minResizeWidth: 10,
      },
      {
        Header: "Processing?",
        id: "processing",
        className: "text-center",
        accessor: (d) => (d.processing ? "Yes" : "No"),
        Cell: (d) =>
          d.value === "Yes" ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          ),
        minResizeWidth: 10,
      },
      {
        Header: "Paid?",
        id: "paid",
        className: "text-center",
        accessor: (d) => (d.paid ? "Yes" : "No"),
        Cell: (d) =>
          d.value === "Yes" ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          ),
        minResizeWidth: 10,
      },
      {
        Header: "Due Date",
        id: "due-date",
        className: "text-center",
        accessor: (d) =>
          d.dueDate ? moment(d.dueDate).format("DD/MM/YYYY") : "-",
        minResizeWidth: 10,
      },
      {
        Header: "Manage",
        accessor: "_id",
        className: "text-center",
        Cell: (d) => (
          <Link
            className="badge badge-pill"
            to={`/admin-panel/payment/${d.value}`}
          >
            Manage
          </Link>
        ),
      },
    ];

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null && typeof row[id] === "string") {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }
    };

    const exportHeaders = [
      { label: "Date Created", key: "date" },
      { label: "Payment ID", key: "_id" },
      { label: "User", key: "user.name" },
      { label: "User Email", key: "user.email" },
      { label: "User ID", key: "user._id" },
      { label: "Tenancy Property", key: "user?.tenancy?.room?.property?.name" },
      { label: "Tenancy Flat", key: "user?.tenancy?.room?.flat?.name" },
      { label: "Tenancy Room", key: "user?.tenancy?.room?.name" },
      { label: "Amount", key: "amount" },
      { label: "Reference", key: "reference" },
      { label: "Description", key: "description" },
      { label: "Processing", key: "processing" },
      { label: "Paid", key: "paid" },
      { label: "Due Date", key: "dueDate" },
    ];

    return (
      <AdminPage title="Manage Payments">
        <p>
          You can sort by column by just selecting the column headers. You can
          also sort by <strong>multiple columns</strong>, hold shift and select
          multiple headings. If you're looking for a specific entry you can
          search inside the white box under the column headings, please note
          this search is <strong>case sensitive.</strong>
        </p>

        <p>
          <strong>
            To set up a new payment, go to the users tab, select a user and
            scroll to the bottom to view and set up new payment
          </strong>
        </p>

        <ReactTable
          data={all}
          columns={columns}
          defaultPageSize={10}
          defaultFilterMethod={filterCaseInsensitive}
          filterable
        />

        {all ? (
          <div className="page-footer text-right mt-3">
            <CSVLink
              data={all}
              headers={exportHeaders}
              filename={`StudentDigs_payments.csv`}
              className="btn green-button"
            >
              Export as CSV
            </CSVLink>
          </div>
        ) : null}
      </AdminPage>
    );
  }
}

const mapStateToProps = (state) => ({
  payments: state.payments,
});

export default connect(mapStateToProps, { getAllPayments })(AdminPayments);
