import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addOtherTenant,
  archiveOtherTenant,
} from "../../actions/tenancyActions";
import isEmpty from "../../validation/is-empty";
import axios from "axios";

// import components
import TenantCard from "./TenantCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ManageOtherTenants extends Component {
  constructor() {
    super();

    this.state = {
      selectedUser: null,
      query: "",
      queryResults: [],
      startDate: new Date(),
      endDate: new Date(),
      rentalValue: 0,
    };

    this.onChange = this.onChange.bind(this);
    this.tenantSearch = this.tenantSearch.bind(this);
    this.selectTenant = this.selectTenant.bind(this);
    this.addOtherTenant = this.addOtherTenant.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    this.setState({ rentalValue: this?.props?.room?.price });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  tenantSearch(e) {
    this.setState({ [e.target.name]: e.target.value });

    axios
      .get(`/api/auth/users/future-tenant-search/${e.target.value}`)
      .then((data) => {
        this.setState({ queryResults: data.data });
      })
      .catch((err) => {
        this.setState({ queryResults: [] });
      });
  }

  selectTenant(user) {
    this.setState({ selectedUser: user, query: "", queryResults: [] });
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handleEndDateChange(date) {
    this.setState({ endDate: date });
  }

  addOtherTenant(e) {
    const { room } = this.props;
    const user = this.state.selectedUser;

    let tenancyInfo = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      rentalValue: this.state.rentalValue,
    };

    this.props.addOtherTenant(room?._id, user?._id, tenancyInfo);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tenants?.length !== nextProps.tenants?.length) {
      this.setState({
        selectedUser: "",
        startDate: new Date(),
        endDate: new Date(),
      });
    }
  }

  render() {
    const { tenants, errors } = this.props;
    const { selectedUser, queryResults, query } = this.state;
    let tenantContent;

    if (!isEmpty(tenants)) {
      tenantContent = (
        <React.Fragment>
          {tenants.map(({ tenant }) => (
            <TenantCard tenant={tenant} key={tenant._id} />
          ))}
        </React.Fragment>
      );
    }

    return (
      <div className="edit-section">
        <h3 className="heading">Manage Other Tenants</h3>
        <p>
          'Other Tenants' are tenants that don't fit in to the 'Current Tenant'
          or 'Future Tenant' brackets, perhaps they're just in for the summer or
          just a week etc.
        </p>
        {tenantContent}

        <div className="row">
          <div className="col-12">
            <div className="field mb-2">
              <h5 className="heading">Add 'Other Tenant'</h5>
            </div>
          </div>
          <div className="col-md-6">
            <div className="field">
              <h5 className="heading">Tenant Name</h5>
              {selectedUser ? (
                <div className="selected-user">
                  <div className="meta">
                    {selectedUser?.name}
                    <span className="d-block">{selectedUser.email}</span>
                  </div>

                  <button
                    className="delete-icon"
                    onClick={() => this.setState({ selectedUser: null })}
                  >
                    <span className="fa-stack">
                      <i className="fas fa-circle fa-stack-2x" />
                      <i className="fas fa-times fa-stack-1x fa-inverse" />
                    </span>
                  </button>
                </div>
              ) : (
                <React.Fragment>
                  <input
                    type="text"
                    name="query"
                    placeholder="Search by name"
                    onChange={this.tenantSearch}
                    autoComplete="off"
                  />

                  {query.length > 0 ? (
                    <div className="query-results">
                      <ul>
                        {queryResults.length > 0 ? (
                          queryResults.map((user) => (
                            <li key={user?._id}>
                              <button onClick={() => this.selectTenant(user)}>
                                {user?.name }
                                <span className="d-block">{user.email}</span>
                              </button>
                            </li>
                          ))
                        ) : (
                          <li className="p-3">No results found</li>
                        )}
                      </ul>
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="field">
              <h5 className="heading">Tenancy Start Date</h5>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="field">
              <h5 className="heading">Tenancy End Date</h5>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="field">
              <h5 className="heading">Weekly Rent</h5>
              <input
                type="text"
                name="rentalValue"
                value={this.state.rentalValue}
                onChange={this.onChange}
              />
            </div>
          </div>

          {errors.futureTenant ? (
            <div className="col-12">
              <div className="field">
                <p className="text-danger">{errors.futureTenant}</p>
              </div>
            </div>
          ) : null}

          <div className="col-12">
            <div className="field">
              <button
                className="btn green-button"
                disabled={isEmpty(this.state.selectedUser)}
                onClick={this.addOtherTenant}
              >
                Add Tenant
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { addOtherTenant, archiveOtherTenant })(
  ManageOtherTenants
);
