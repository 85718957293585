import axios from 'axios';

import {
  GET_FOR_RESOURCES,
  GET_UPLOADED_RESOURCES,
  RESOURCES_LOADING,
  UPLOAD_RESOURCE,
  GET_ERRORS,
  CLEAR_ERRORS,
  DELETE_RESOURCE,
  ADMIN_UPLOAD_RESOURCE,
  CLEAR_ALL_RESOURCES,
  UPLOADING_RESOURCE
} from './types';

// get for resources
export const getForResources = () => dispatch => {
  dispatch(setResourcesLoading());

  axios
    .get('/api/media/for-resources')
    .then(res => {
      dispatch({
        type: GET_FOR_RESOURCES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_FOR_RESOURCES,
        payload: []
      });
    });
};

export const getUserForResources = userId => dispatch => {
  dispatch(setResourcesLoading());

  axios
    .get(`/api/media/for-resources/user/${userId}`)
    .then(res => {
      dispatch({
        type: GET_FOR_RESOURCES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_FOR_RESOURCES,
        payload: []
      });
    });
};

// get uploaded resources
export const getUploadedResources = () => dispatch => {
  dispatch(setResourcesLoading());

  axios
    .get('/api/media/uploaded-resources')
    .then(res => {
      dispatch({
        type: GET_UPLOADED_RESOURCES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_UPLOADED_RESOURCES,
        payload: []
      });
    });
};

// get specified users uploaded resources
export const getUserUploadedResources = userId => dispatch => {
  dispatch(setResourcesLoading());

  axios
    .get(`/api/media/uploaded-resources/user/${userId}`)
    .then(res => {
      dispatch({
        type: GET_UPLOADED_RESOURCES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_UPLOADED_RESOURCES,
        payload: []
      });
    });
};

// upload a resource
export const uploadResource = file => dispatch => {
  dispatch(setUploadingResource());
  dispatch({ type: CLEAR_ERRORS });

  axios
    .post('/api/media/resources', file)
    .then(res => {
      dispatch({
        type: UPLOAD_RESOURCE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// upload a resource
export const adminUploadResource = (file, userId) => dispatch => {
  dispatch(setResourcesLoading());
  dispatch({ type: CLEAR_ERRORS });

  axios
    .post(`/api/media/resources/${userId}`, file)
    .then(res => {
      dispatch({
        type: ADMIN_UPLOAD_RESOURCE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// delete a resouce
export const deleteResource = resourceId => dispatch => {
  if (window.confirm('Are you sure you want to delete this resource?')) {
    axios.delete(`/api/media/resource/${resourceId}`).then(res => {
      dispatch({
        type: DELETE_RESOURCE
      });

      dispatch(getUploadedResources());
    });
  }
};

export const adminDeleteResource = (resourceId, userId) => dispatch => {
  if (window.confirm('Are you sure you want to delete this resource?')) {
    axios.delete(`/api/media/resource/${resourceId}`).then(res => {
      dispatch({
        type: DELETE_RESOURCE
      });

      dispatch(getUserUploadedResources(userId));
      dispatch(getUserForResources(userId));
    });
  }
};

// clear resources
export const clearAllResources = () => {
  return {
    type: CLEAR_ALL_RESOURCES
  };
};

// set resources loading
export const setResourcesLoading = () => {
  return {
    type: RESOURCES_LOADING
  };
};

// set resources loading
export const setUploadingResource = () => {
  return {
    type: UPLOADING_RESOURCE
  };
};
