import {
  GET_ALL_ENQUIRIES,
  ENQUIRIES_LOADING,
  GET_ENQUIRY,
  GET_TENANT_ENQUIRIES,
  GET_TENANT_ENQUIRY,
  DELETE_TENANT_ENQUIRY,
  DELETE_ENQUIRY,
  UPDATE_ENQUIRY_STATUS
} from '../actions/types';

const initialState = {
  enquiries: [],
  enquiry: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ENQUIRIES:
      return {
        ...state,
        enquiries: action.payload,
        loading: false
      };
    case GET_ENQUIRY:
      return {
        ...state,
        enquiry: action.payload,
        loading: false
      };
    case GET_TENANT_ENQUIRIES:
      return {
        ...state,
        enquiries: action.payload,
        loading: false
      };
    case GET_TENANT_ENQUIRY:
      return {
        ...state,
        enquiry: action.payload,
        loading: false
      };
    case DELETE_TENANT_ENQUIRY:
      return {
        ...state,
        enquiries: state.enquiries.filter(
          enquiry => enquiry._id !== action.payload.enquiry
        )
      };
    case DELETE_ENQUIRY:
      return {
        ...state,
        enquiries: state.enquiries.filter(
          enquiry => enquiry._id !== action.payload.enquiry
        )
      };
    case ENQUIRIES_LOADING:
      return {
        ...state,
        loading: true
      };
    case UPDATE_ENQUIRY_STATUS:
      return {
        ...state,
        enquiry: action.payload
      };
    default:
      return state;
  }
}
