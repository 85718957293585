import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

// import components
import PanelPage from './PanelPage';

class Contact extends Component {
  constructor() {
    super();

    this.state = {
      phone: '',
      message: '',
      reason: '',
      success: false,
      loading: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true, errors: {} });

    const data = {
      message: this.state.message,
      reason: this.state.reason,
      phone: this.state.phone
    };

    axios
      .post('/api/contact/tenant', data)
      .then(res => {
        this.setState({ loading: false, success: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  }

  render() {
    const { errors, loading, success } = this.state;
    let contactContent;

    if (!success) {
      contactContent = (
        <React.Fragment>
          <form onSubmit={this.onSubmit}>
            <div className="field">
              <label>Phone</label>
              <input
                placeholder="07234 56789"
                type="text"
                name="phone"
                onChange={this.onChange}
                value={this.state.phone}
              />
              <small>
                Phone number is optional, but if your enquiry is of high priorty
                we may want to get hold of you ASAP.
              </small>
            </div>
            <div className="field">
              <label>Reason for contact</label>
              <select
                name="reason"
                value={this.state.reason}
                onChange={this.onChange}
              >
                <option value="" className="font-weight-bold">
                  Select your reason for contact
                </option>
                <option value="General enquiry">General enquiry</option>
                <option value="Property damage">Property damage</option>
                <option value="Utilities issue">Utlilities issue</option>
                <option value="General maintenance">General maintanence</option>
                <option value="Cost / billing enquiry">
                  Cost / billing enquiry
                </option>
                <option value="Other reason">Other reason</option>
              </select>
            </div>

            <div className="field">
              <label>Message *</label>
              <textarea
                placeholder="I have an enquiry about..."
                name="message"
                value={this.state.message}
                onChange={this.onChange}
                rows="5"
              />

              {errors.message ? (
                <small className="text-danger">{errors.message}</small>
              ) : null}
            </div>

            <div className="field">
              <button type="submit" className="btn green-button">
                {loading ? (
                  <i className="fas fa-circle-notch fa-spin" />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </form>
        </React.Fragment>
      );
    } else {
      contactContent = (
        <div className="success-message">
          <h3 className="heading">
            Your enquiry has been sent, thanks for getting in touch!
          </h3>
          <p>
            We'll get back to you ASAP! We normally try to get in touch via
            email, please check your junk / spam boxes if you haven't heard from
            us. If the enquiry is a priority we may get back to you by phone.
          </p>
        </div>
      );
    }

    return (
      <PanelPage title="Tenant Contact">
        <Helmet>
          <title>Tenant Contact</title>
        </Helmet>

        <div className="header">
          <h1 className="heading">Tenant Contact</h1>
        </div>

        <div className="content">{contactContent}</div>
      </PanelPage>
    );
  }
}

export default Contact;
