import React, { Component } from 'react';
import { connect } from 'react-redux';
import { adminUploadResource } from '../../actions/resourcesActions';

class AdminUploadResource extends Component {
  handleSelectedFile = event => {
    const selectedFile = event.target.files[0];
    const selectedFiles = event.target.files;
    const file = new FormData();
    const { user } = this.props.users;

    console.log('hello');

    console.log(selectedFiles);

    for (const selectedFile of selectedFiles) {
      file.append('file', selectedFile);
    }

    // file.append('file', selectedFile);
    this.props.adminUploadResource(file, user?._id);
  };

  render() {
    const { loading } = this.props.resources;

    return (
      <div className="upload-resource">
        <h3 className="heading">Upload a resource</h3>

        <div className="upload-btn-wrapper">
          <button className="btn">
            {loading ? (
              <i className="fas fa-circle-notch fa-spin" />
            ) : (
              'Upload files'
            )}
          </button>

                   <input
            type="file"
            name="myfile"
            onClick={event => {
              event.target.value = null;
            }}
            onChange={this.handleSelectedFile}
            multiple
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.resources,
  users: state.users
});

export default connect(
  mapStateToProps,
  { adminUploadResource }
)(AdminUploadResource);
