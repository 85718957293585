import React, { Component } from "react";

export default class YoutubeIframe extends Component {
  render() {
    return (
      <div className="flat-video-wrapper">
        <div className="inner">
          <iframe
            title="cats"
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${this.props.videoID}`}
          />
        </div>
      </div>
    );
  }
}
