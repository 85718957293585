import React, { Component } from 'react';
import axios from 'axios';

// import components
import BlogPost from './BlogPost';
import Spinner from '../common/Spinner';

class BlogPosts extends Component {
  constructor() {
    super();

    this.state = {
      posts: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    axios
      .get('/api/media/blog-posts')
      .then(posts => {
        this.setState({ posts: posts.data, loading: false });
      })
      .catch(err => {
        this.setState({
          posts: [],
          loading: false
        });
      });
  }

  render() {
    const { posts, loading } = this.state;

    return (
      <div className="blog-feed">
        <div className="container">
          <div className="header">
            <h3 className="heading">Our Blog</h3>
            <hr />
          </div>

          <div className="row">
            {!loading ? (
              posts.map(post => (
                <div className="col-lg-3 col-sm-6" key={post.id}>
                  <BlogPost post={post} />
                </div>
              ))
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogPosts;
