import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  getAllProperties,
  clearProperties
} from '../../actions/propertyActions';
import isEmpty from '../../validation/is-empty';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';

// import components
import PropertyCard from './PropertyCard';
import Spinner from '../common/Spinner';
import PropertyFilter from './PropertyFilter';
import PropertiesSidebar from './PropertiesSidebar';

class Properties extends Component {
  constructor() {
    super();

    this.state = {
      noResults: false,
      backupProperties: []
    };

    this.getBackupProperties = this.getBackupProperties.bind(this);
  }

  componentDidMount() {
    this.props.clearProperties();
    this.props.getAllProperties(this.props.location.search);
  }

  componentWillReceiveProps(nextProps) {
    const { loading } = nextProps.properties;

    if (this.props.location.search !== nextProps.location.search) {
      this.props.getAllProperties(nextProps.location.search);
      this.setState({ noResults: false });
    }

    if (
      !loading &&
      isEmpty(nextProps.properties.properties) &&
      !this.state.noResults
    ) {
      this.getBackupProperties();
    }
  }

  getBackupProperties() {
    this.setState({ noResults: true });

    if (isEmpty(this.state.backupProperties)) {
      axios
        .get('/api/properties')
        .then(res => this.setState({ backupProperties: res.data }))
        .catch(err => this.setState({ backupProperties: [] }));
    }
  }

  render() {
    const { properties, loading } = this.props.properties;
    const { filterHidden } = this.props;
    const { noResults, backupProperties } = this.state;
    let propertyContent;

    if (loading) {
      propertyContent = (
        <div className="properties">
          <Spinner />
        </div>
      );
    } else if (noResults) {
      propertyContent = (
        <React.Fragment>
          <div className="no-content">
            <h3 className="heading">
              Whoops, i'm sorry, we couldn't find any properties matching your
              criteria
            </h3>

            <Link to="/accommodation" className="btn green-button">
              View all properties
            </Link>
          </div>

          <div className="properties no-content-feed">
            <div className="header">
              <h3 className="heading">Our Other Properties</h3>
              <hr />
            </div>

            {backupProperties.length > 0 ? (
              backupProperties.map(property => (
                <PropertyCard key={property._id} property={property} />
              ))
            ) : (
              <Spinner />
            )}
          </div>
        </React.Fragment>
      );
    } else {
      propertyContent = (
        <div className="properties">
          {properties.map(property => (
            <PropertyCard key={property._id} property={property} />
          ))}
        </div>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Accommodation</title>
        </Helmet>

        {!filterHidden ? <PropertyFilter /> : null}

        <div className="property-feed">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">{propertyContent}</div>
              <div className="col-lg-3 d-none d-lg-block">
                <PropertiesSidebar />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default withRouter(
  connect(
    mapStateToProps,
    { getAllProperties, clearProperties }
  )(Properties)
);
