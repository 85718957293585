import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActiveTenancies } from '../../actions/tenancyActions';

// import components
import PanelPage from './PanelPage';
import RoomCard from './RoomCard';

class UserHome extends Component {
  componentDidMount() {
    this.props.getActiveTenancies();
  }

  render() {
    const { user } = this.props.auth;
    const { tenancies } = this.props.tenancy;

    return (
      <PanelPage>
        <div className="header">
          <h1 className="heading">Welcome back, {user?.name }!</h1>
        </div>

        <div className="tenancy-section current">
          <div className="header">
            <h2 className="heading">Your Tenancies</h2>
          </div>

          {tenancies.length > 0 ? (
            tenancies.map(tenancy => (
              <RoomCard tenancy={tenancy} key={tenancy._id} />
            ))
          ) : (
            <div className="room-card">
              <div className="no-content">
                <p>No tenancies to show...</p>
              </div>
            </div>
          )}
        </div>
      </PanelPage>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  tenancy: state.tenancy
});

export default connect(
  mapStateToProps,
  { getActiveTenancies }
)(UserHome);
