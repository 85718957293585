import React, { Component } from "react";
import Spinner from "../common/Spinner";
import Moment from "react-moment";
import isEmpty from "../../validation/is-empty";

class RoomCard extends Component {
  render() {
    const { tenancy, loading } = this.props;
    const { room } = this.props.tenancy;
    let cardContent;

    if (loading) {
      cardContent = <Spinner />;
    } else if (isEmpty(room)) {
      cardContent = (
        <div className="room-card">
          <div className="no-content">
            <p>No tenancy to show...</p>
          </div>
        </div>
      );
    } else {
      cardContent = (
        <React.Fragment>
          <div className="header">
            <h2 className="heading">
              {room?.name}, {room?.flat ? `${room?.flat?.name}, ` : null}{" "}
              {room.property.name}
            </h2>
          </div>

          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="left-content">
                <div className="featured-images">
                  <div
                    className="image-block"
                    style={{
                      backgroundImage: room.property.images[0]
                        ? `url(/api/media/image/${room.property.images[0].mediaKey})`
                        : null,
                    }}
                  >
                    {/* apply bg image */}
                  </div>
                  <div
                    className="image-block"
                    style={{
                      backgroundImage: room.property.images[1]
                        ? `url(/api/media/image/${room.property.images[1].mediaKey})`
                        : null,
                    }}
                  >
                    {/* apply bg image */}
                  </div>
                </div>

                <div className="address">
                  <h3>
                    {room.property.address
                      ? `${room.property.address.line1}`
                      : null}
                    {room.property.address
                      ? `, ${room.property.address.city}`
                      : null}
                    {room.property.address
                      ? `, ${room.property.address.postcode}`
                      : null}
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="property-info">
                <ul className="meta-info">
                  <li>
                    <i className="far fa-calendar-alt fa-fw" />
                    from{" "}
                    {tenancy ? (
                      <Moment format="Do MMM YYYY">{tenancy.startDate}</Moment>
                    ) : (
                      "-"
                    )}
                  </li>
                  <li>
                    <i className="far fa-calendar-times fa-fw" />
                    until{" "}
                    {tenancy ? (
                      <Moment format="Do MMM YYYY">{tenancy.endDate}</Moment>
                    ) : (
                      "-"
                    )}
                  </li>
                  <li>
                    <i className="fas fa-tag fa-fw" />
                    {tenancy ? `£${tenancy.rentalValue} / week` : "-"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return <div className="room-card">{cardContent}</div>;
  }
}

export default RoomCard;
