import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editPayment, deletePayment } from '../../actions/paymentActions';
import isEmpty from '../../validation/is-empty';

// import components
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class EditPayment extends Component {
  constructor() {
    super();

    this.state = {
      show: false,
      id: '',
      title: '',
      description: '',
      reference: '',
      amount: '',
      dueDate: undefined,
      processing: false,
      paid: false
    };

    this.onChange = this.onChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  componentDidMount() {
    const { payment } = this.props;

    this.setState({
      id: payment._id,
      title: payment.title,
      description: payment.description,
      reference: payment.reference,
      amount: payment.amount,
      dueDate: !isEmpty(payment.dueDate)
        ? new Date(payment.dueDate)
        : undefined,
      processing: payment.processing,
      paid: payment.paid
    });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onDateChange(date) {
    this.setState({ dueDate: date });
  }

  onSelectChange(e) {
    e.preventDefault();

    e.target.value === 'true'
      ? this.setState({ [e.target.name]: true })
      : this.setState({ [e.target.name]: false });
  }

  handleShow(e) {
    this.setState({ show: true });
  }

  handleHide(e) {
    this.setState({ show: false });
  }

  onSubmit(e) {
    e.preventDefault();

    const data = {
      user: this?.props?.payment?.user?._id,
      title: this.state.title,
      description: this.state.description,
      reference: this.state.reference,
      amount: this.state.amount,
      dueDate: this.state.dueDate,
      processing: this.state.processing,
      paid: this.state.paid
    };

    this.props.editPayment(this.props.payment._id, data);
  }

  onDelete(e) {
    e.preventDefault();

    this.props.deletePayment(this.props.payment._id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.payments.all !== nextProps.payments.all) {
      this.setState({ show: false });
    }
  }

  render() {
    const { errors, button } = this.props;

    return (
      <React.Fragment>
        {button ? (
          <button className="btn green-button" onClick={this.handleShow}>
            Edit Payment
          </button>
        ) : (
          <button
            className="badge badge-pill border-0"
            onClick={this.handleShow}
            id={this.state.reference}
          >
            Manage
          </button>
        )}

        <Modal size="lg" show={this.state.show} onHide={this.handleHide}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-section">
              <div className="row">
                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Payment Title *</h5>
                    <input
                      type="text"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChange}
                      placeholder="Monthly Rent"
                    />

                    {errors.title && (
                      <small className="text-danger">{errors.title}</small>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Reference *</h5>

                    <input
                      type="text"
                      name="reference"
                      value={this.state.reference}
                      onChange={this.onChange}
                      placeholder="NAME/RENT5"
                    />

                    {errors.reference && (
                      <small className="text-danger">{errors.reference}</small>
                    )}

                    <p className="description">
                      Every reference must be unique.
                    </p>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="field">
                    <h5 className="heading">Description</h5>

                    <textarea
                      type="text"
                      name="description"
                      value={this.state.description}
                      onChange={this.onChange}
                      placeholder="Rent to cover June - August"
                    />

                    {errors.description && (
                      <small className="text-danger">
                        {errors.description}
                      </small>
                    )}

                    <p className="description">Optional payment description</p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Amount *</h5>

                    <input
                      placeholder="578"
                      type="text"
                      name="amount"
                      value={this.state.amount}
                      onChange={this.onChange}
                    />

                    {errors.amount && (
                      <small className="text-danger">{errors.amount}</small>
                    )}

                    <p className="description">
                      Amount in pounds, do <strong>not</strong> add the £
                      symbol.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Due Date</h5>

                    <DatePicker
                      selected={this.state.dueDate}
                      onChange={this.onDateChange}
                      dateFormat="dd/MM/yyyy"
                    />

                    {errors.dueDate && (
                      <small className="text-danger">{errors.dueDate}</small>
                    )}

                    <p className="description">
                      This can be left blank if there is no due date.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Processing</h5>

                    <select
                      name="processing"
                      onChange={this.onSelectChange}
                      value={this.state.processing ? 'true' : 'false'}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>

                    {errors.dueDate && (
                      <small className="text-danger">{errors.processing}</small>
                    )}

                    <p className="description">
                      Processing payments have usually been tampered with! You
                      need to check them and ensure that you've received correct
                      payment before marking them off as paid!
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="field">
                    <h5 className="heading">Paid</h5>

                    <select
                      name="paid"
                      onChange={this.onSelectChange}
                      value={this.state.paid ? 'true' : 'false'}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>

                    {errors.dueDate && (
                      <small className="text-danger">{errors.paid}</small>
                    )}

                    <p className="description">
                      If you have already received payment you can manually mark
                      this as paid, successful payments through the website
                      automatically set paid as true.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-section">
              <div className="header">
                <h3 className="heading">Delete Payment</h3>
              </div>

              <p>
                Please note, once a payment is deleted it is permanently removed
                from the database, it cannot be recovered!
              </p>

              <button onClick={this.onDelete} className="btn red-button">
                Delete Payment
              </button>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button onClick={this.onSubmit} className="btn green-button">
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  payments: state.payments,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { editPayment, deletePayment }
)(EditPayment);
