import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllUsers } from '../../actions/userActions';
import axios from 'axios';

class AddUserModal extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      phone: '',
      address: '',
      admin: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    if (e.target.name === 'featured') {
      const isTrue = e.target.value === 'true';

      this.setState({ featured: isTrue });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    axios
      .post(`/api/auth/create-user`, this.state)
      .then(newUser => {
        this.props.getAllUsers();
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  }

  componentWillReceiveProps(nextProps) {
    const { users } = this.props.users;
    if (users !== nextProps.users.user) {
      window.$('#addUserModal').modal('hide');

      this.setState({
        name: '',
        email: '',
        password: '',
        password2: '',
        phone: '',
        address: '',
        admin: false,
        errors: {}
      });
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div
        className="modal fade"
        id="addUserModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addUserModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addPropertyLabel">
                Add a user
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="edit-section">
                <form autoComplete="new-password">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Full Name</h5>
                        <input
                          placeholder="Jane Doe"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.onChange}
                          autoComplete="nope"
                        />
                        {errors.name ? (
                          <p className="text-danger">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Email Address</h5>
                        <input
                          type="text"
                          name="email"
                          placeholder="jane.doe@gmail.com"
                          value={this.state.email}
                          onChange={this.onChange}
                          autoComplete="nope"
                        />

                        {errors.email ? (
                          <p className="text-danger">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Phone</h5>
                        <input
                          placeholder="07123 456789"
                          type="text"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChange}
                          autoComplete="nope"
                        />

                        {errors.phone ? (
                          <p className="text-danger">{errors.phone}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field">
                        <h5 className="heading">Address</h5>
                        <input
                          type="text"
                          name="address"
                          placeholder="Line 1, Line 2, Town, County, GL1 AAA"
                          value={this.state.address}
                          onChange={this.onChange}
                          autoComplete="nope"
                        />

                        {errors.address ? (
                          <p className="text-danger">{errors.address}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Password</h5>

                        <input
                          className="w-100"
                          type="password"
                          name="password"
                          onChange={this.onChange}
                          autoComplete="nope"
                        />

                        {errors.password ? (
                          <p className="text-danger">{errors.password}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Confirm Password</h5>

                        <input
                          className="w-100"
                          type="password"
                          name="password2"
                          onChange={this.onChange}
                          autoComplete="nope"
                        />

                        {errors.password2 ? (
                          <p className="text-danger">{errors.password2}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field">
                        <h5 className="heading">Admin Access?</h5>

                        <select
                          name="admin"
                          value={this.state.admin}
                          onChange={this.onChange}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.onSubmit}
                className="btn green-button"
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users
});

export default connect(
  mapStateToProps,
  { getAllUsers }
)(AddUserModal);
