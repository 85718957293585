import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  getSavedProperties,
  deleteSavedProperty
} from '../../actions/propertyActions';
import { Link } from 'react-router-dom';

// import components
import PropertyCard from '../properties/PropertyCard';
import PropertiesSidebar from '../properties/PropertiesSidebar';
import Spinner from '../common/Spinner';

class SavedProperties extends Component {
  constructor() {
    super();

    this.deleteSavedProperty = this.deleteSavedProperty.bind(this);
  }

  componentDidMount() {
    this.props.getSavedProperties();
  }

  deleteSavedProperty(propertyId) {
    this.props.deleteSavedProperty(propertyId);
  }

  render() {
    const savedProperties = this.props.properties.saved;
    const { savedLoading } = this.props.properties;
    let propertyContent;

    if (savedLoading) {
      propertyContent = <Spinner />;
    } else if (savedProperties.length > 0) {
      propertyContent = savedProperties.map(property => (
        <PropertyCard property={property} key={property._id} />
      ));
    } else {
      propertyContent = (
        <div className="no-content">
          <h3 className="heading">
            You currently have no Saved Properties, Saved Properties are only
            available on the device you saved them on.
          </h3>

          <Link to="/accommodation" className="btn green-button">
            View accommodation
          </Link>
        </div>
      );
    }

    return (
      <section className="saved-properties">
        <Helmet>
          <title>Saved Properties</title>
        </Helmet>

        <div className="container">
          <div className="row narrow">
            <div className="col-lg-8 col-xl-9">
              <div className="properties">
                <div className="header">
                  <h1 className="heading">Saved Properties</h1>
                  <hr />
                </div>

                {propertyContent}
              </div>
            </div>

            <div className="col-lg-4 col-xl-3">
              <PropertiesSidebar />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { getSavedProperties, deleteSavedProperty }
)(SavedProperties);
