import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
  render() {
    return (
      <div className="user-sidebar">
        <h3 className="heading">User Panel</h3>

        <ul className="panel-links">
          <li className="panel-item">
            <Link to="/user-panel" className="panel-link">
              Panel Home
            </Link>
          </li>
          <li className="panel-item">
            <Link to="/user-panel/resources" className="panel-link">
              Resources
            </Link>
          </li>
          <li className="panel-item">
            <Link to="/user-panel/contact" className="panel-link">
              Tenant Contact
            </Link>
          </li>
          <li className="panel-item">
            <Link to="/user-panel/payments" className="panel-link">
              Payments
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default Sidebar;
