import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import {
  getTenantEnquiry,
  deleteTenantEnquiry,
  updateTenantEnquiryStatus
} from '../../actions/enquiryActions';
import { Link, Redirect } from 'react-router-dom';

// import components
import AdminPage from '../admin/AdminPage';
import isEmpty from '../../validation/is-empty';
import Spinner from '../common/Spinner';

class ViewTenantEnquiry extends Component {
  constructor() {
    super();

    this.state = {
      deleted: false,
      editStatus: false,
      status: ''
    };

    this.onDelete = this.onDelete.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getTenantEnquiry(this.props.match.params.enquiryID);
  }

  componentWillReceiveProps(nextProps) {
    const { enquiry } = nextProps.enquiries;

    if (!isEmpty(enquiry.status)) {
      this.setState({ status: enquiry.status });
    }
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.updateTenantEnquiryStatus(this.props.match.params.enquiryID, {
      status: this.state.status
    });

    this.setState({ editStatus: false });
  }

  onDelete(e) {
    e.preventDefault();

    this.props.deleteTenantEnquiry(this.props.match.params.enquiryID);

    this.setState({ deleted: true });
  }

  render() {
    const { enquiry, loading } = this.props.enquiries;
    const { deleted } = this.state;
    let enquiryContent;

    if (deleted) {
      return <Redirect to="/admin-panel/tenant-enquiries" />;
    }

    if (loading) {
      enquiryContent = <Spinner />;
    } else if (!loading && isEmpty(enquiry)) {
      enquiryContent = (
        <p>Something went wrong, we could not find an enquiry with that ID</p>
      );
    } else {
      enquiryContent = (
        <React.Fragment>
          <div className="admin-section">
            <div className="row">
              <div className="col-md-4">
                <div className="section-info">
                  <h2 className="heading">Tenant Enquiry</h2>
                  <p>
                    Tenant enquiries are also sent to the admin email address.
                    This is an alternative storage method.
                  </p>
                </div>
              </div>

              <div className="col-md-8">
                <div className="section-content">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="meta-section">
                        <h4>Date</h4>
                        <p>
                          <Moment format="DD/MM/YYYY">{enquiry.date}</Moment>
                        </p>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="meta-section">
                        <h4>User</h4>
                        <p>{enquiry?.user ? enquiry?.user?.name : '-'}</p>
                        <p>
                          {enquiry.user ? (
                            <a href={`mailto:${enquiry.user.email}`}>
                              {enquiry.user.email}
                            </a>
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="meta-section">
                        <h4>Phone</h4>
                        <p>{enquiry.phone ? enquiry.phone : '-'}</p>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="meta-section">
                        <h4>Reason</h4>
                        <p>{enquiry.reason ? enquiry.reason : '-'}</p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="meta-section">
                        <h4 className="message">Message</h4>
                        <p style={{ whiteSpace: 'pre-wrap' }}>
                          {enquiry.message}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="meta-section">
                        <h4>Status</h4>
                        {!this.state.editStatus && (
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.status}{' '}
                            <button
                              className="edit-link ml-3"
                              onClick={() =>
                                this.setState({ editStatus: true })
                              }
                            >
                              Edit Status
                            </button>
                          </p>
                        )}

                        {this.state.editStatus && (
                          <div className="edit-status">
                            <select
                              name="status"
                              value={this.state.status}
                              onChange={this.onChange}
                            >
                              <option value="Awaiting contact">
                                Awaiting contact
                              </option>
                              <option value="Contacted - awaiting response">
                                Contacted - awaiting response
                              </option>
                              <option value="Resolved">Resolved</option>
                              <option value="Unresolved">Unresolved</option>
                            </select>

                            <button
                              className="edit-link ml-3"
                              onClick={this.onSubmit}
                            >
                              Save Status
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-section">
            <div className="header">
              <h3 className="heading">Delete Enquiry</h3>
            </div>

            <p>Please note, deleted enquiries cannot be recovered!</p>

            <button className="btn red-button" onClick={this.onDelete}>
              Delete Enquiry
            </button>

            <div className="mt-3">
              <Link
                className="btn green-button"
                to="/admin-panel/tenant-enquiries"
              >
                &larr; Back to enquiries
              </Link>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <AdminPage title="Manage Tenant Enquiry">{enquiryContent}</AdminPage>
    );
  }
}

const mapStateToProps = state => ({
  enquiries: state.enquiries
});

export default connect(
  mapStateToProps,
  { getTenantEnquiry, deleteTenantEnquiry, updateTenantEnquiryStatus }
)(ViewTenantEnquiry);
