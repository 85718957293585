import React from 'react';
import bedroomImage from '../../img/bedroom.png';

export default function Hero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <img src={bedroomImage} alt="Student Bedroom" />
          </div>
        </div>
      </div>
    </section>
  );
}
