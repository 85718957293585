import axios from 'axios';

import {
  GET_ALL_TENANTS,
  TENANCY_LOADING,
  GET_ACTIVE_TENANCIES,
  ADD_TENANT,
  GET_ERRORS,
  CLEAR_ERRORS,
  REMOVE_TENANT,
  MOVE_FUTURE_TENANT
} from './types';

// get all tenants
export const getAllTenants = () => dispatch => {
  dispatch(setTenancyLoading());

  axios
    .get('/api/tenancy')
    .then(res => {
      dispatch({
        type: GET_ALL_TENANTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_TENANTS,
        payload: []
      });
    });
};

// get tenancies
export const getActiveTenancies = () => dispatch => {
  dispatch(setTenancyLoading());
  axios
    .get('/api/tenancy/current/active')
    .then(res => {
      dispatch({
        type: GET_ACTIVE_TENANCIES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ACTIVE_TENANCIES,
        payload: []
      });
    });
};

// get active tenancies by id
export const getUsersTenancies = userID => dispatch => {
  dispatch(setTenancyLoading());

  axios
    .get(`/api/tenancy/${userID}/all`)
    .then(res => {
      dispatch({
        type: GET_ACTIVE_TENANCIES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ACTIVE_TENANCIES,
        payload: []
      });
    });
};

// add tenant
export const addTenant = (roomId, userId, tenancyInfo) => dispatch => {
  dispatch({ type: CLEAR_ERRORS });

  if (
    window.confirm('Are you sure you want to add this tenant to the property?')
  ) {
    axios
      .post(`/api/tenancy/new/tenant/${roomId}/${userId}`, tenancyInfo)
      .then(res => {
        dispatch({
          type: ADD_TENANT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// add future tenant
export const addFutureTenant = (roomId, userId, tenancyInfo) => dispatch => {
  dispatch({ type: CLEAR_ERRORS });

  if (
    window.confirm('Are you sure you want to add this tenant to the property?')
  ) {
    axios
      .post(`/api/tenancy/new/future-tenant/${roomId}/${userId}`, tenancyInfo)
      .then(res => {
        dispatch({
          type: ADD_TENANT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// add 'other teannt'
export const addOtherTenant = (roomId, userId, tenancyInfo) => dispatch => {
  dispatch({ type: CLEAR_ERRORS });

  if (
    window.confirm('Are you sure you want to add this tenant to the property?')
  ) {
    axios
      .post(`/api/tenancy/new/other-tenant/${roomId}/${userId}`, tenancyInfo)
      .then(res => {
        dispatch({
          type: ADD_TENANT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// remove tenant
export const archiveTenant = (roomId, userId) => dispatch => {
  dispatch({ type: CLEAR_ERRORS });

  if (
    window.confirm(
      'Are you sure you want to remove this tenant from the property?'
    )
  ) {
    axios
      .post(`/api/tenancy/archive/tenant/${roomId}/${userId}`)
      .then(res => {
        dispatch({
          type: REMOVE_TENANT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// remove future tenant
export const archiveFutureTenant = (roomId, userId) => dispatch => {
  dispatch({ type: CLEAR_ERRORS });

  if (
    window.confirm(
      'Are you sure you want to remove this tenant from the property?'
    )
  ) {
    axios
      .post(`/api/tenancy/archive/future-tenant/${roomId}/${userId}`)
      .then(res => {
        dispatch({
          type: REMOVE_TENANT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// remove 'other tenant'
export const archiveOtherTenant = tenancyID => dispatch => {
  dispatch({ type: CLEAR_ERRORS });

  if (window.confirm('Are you sure you want to archive this tenant?')) {
    axios
      .post(`/api/tenancy/archive/other-tenant/${tenancyID}`)
      .then(res => {
        dispatch({
          type: REMOVE_TENANT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// move future tenant
export const moveFutureTenant = tenancyID => dispatch => {
  if (
    window.confirm(
      'Are you sure you want to make this tenant the current tenant?'
    )
  ) {
    axios
      .post(`/api/tenancy/move-future/${tenancyID}`)
      .then(res => {
        dispatch({
          type: MOVE_FUTURE_TENANT,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  }
};

// set properties loading
export const setTenancyLoading = () => {
  return {
    type: TENANCY_LOADING
  };
};
