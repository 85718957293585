import React, { Component } from "react";
import { Helmet } from "react-helmet";

// import components
import PropertyFilter from "./PropertyFilter";
import FeaturedProperties from "./FeaturedProperties";
import BlogPosts from "./BlogPosts";
import Hero from "../common/Hero";
// import VirusNotice from "../common/VirusNotice";

class Home extends Component {
  render() {
    return (
      <main className="homepage">
        <Helmet titleTemplate="%s">
          <title>
            Student Digs Gloucestershire | We take the fuss out of student
            accommodation
          </title>
        </Helmet>

        <Hero />

        {/* <VirusNotice /> */}

        <PropertyFilter />

        <FeaturedProperties />

        <BlogPosts />
      </main>
    );
  }
}

export default Home;
