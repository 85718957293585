import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png';

class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="container">
            <div className="inner-content">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Student Digs Logo" />
                </Link>
              </div>

              <div className="social">
                <ul className="icons">
                  <li>
                    <a
                      href="https://twitter.com/studentdigsglos"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter-square" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/StudentDigsGloucestershire/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                </ul>

                <h5 className="phone">
                  Call us on <a href="tel: 01452886186">01452&nbsp;886186</a>
                </h5>

                <Link to="/contact" className="btn green-button">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </footer>

        <div className="small-footer">
          <div className="container">
            <div className="content">
              <div className="links">
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy">Cookie Policy</Link>
                  </li>
                </ul>
              </div>

              <div className="brace">
                <p>
                  Website created by{' '}
                  <a
                    href="https://www.brace.co.uk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Brace Creative Agency
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
