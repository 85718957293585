import React, { Component } from 'react';
import { editFlat, deleteFlat } from '../../actions/propertyActions';
import { connect } from 'react-redux';

class EditFlatModal extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      updated: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    const { flat } = this.props;

    this.setState({
      name: flat.name
    });
  }

  onChange(e) {
    e.preventDefault();

    this.setState({ [e.target.name]: e.target.value, updated: false });
  }

  onSubmit(e) {
    const { flat } = this.props;
    e.preventDefault();

    this.setState({ updated: false });

    const flatData = {
      name: this.state.name
    };

    this.props.editFlat(flat._id, flatData);
  }

  onDelete(e) {
    const { flat } = this.props;
    e.preventDefault();

    this.props.deleteFlat(flat._id);
  }

  componentWillReceiveProps(nextProps) {
    const { flat } = this.props;

    if (this.props.properties.property !== nextProps.properties.property) {
      this.setState({ updated: true });
      window.$(`#editFlat${flat._id}`).modal('hide');
    }
  }

  render() {
    const { flat } = this.props;
    return (
      <div
        className="modal fade"
        id={`editFlat${flat._id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addFlatModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addFlatModal">
                Edit flat
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="edit-section">
                <h3 className="heading">General Info</h3>

                <div className="row">
                  <div className="col-md-6">
                    <div className="field">
                      <h5 className="heading">Flat Name</h5>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="edit-section">
                <h3 className="heading">Delete Flat</h3>

                <div className="row">
                  <div className="col-md-12">
                    <div className="field mb-0">
                      <p>
                        Please note: Deleting a flat is permanent and cannot be
                        undone. All rooms attached or associated with the flat
                        will also be deleted.
                      </p>
                      <button
                        className="btn red-button"
                        onClick={this.onDelete}
                      >
                        Delete Flat
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <p className="mb-0">
                {this.state.updated ? 'Flat updated' : null}
              </p>
              <button
                type="button"
                onClick={this.onSubmit}
                className="btn green-button"
              >
                Update Flat
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { editFlat, deleteFlat }
)(EditFlatModal);
