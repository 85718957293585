import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFeaturedProperties } from '../../actions/propertyActions';

// import components
import FeaturedProperty from './FeaturedProperty';
import Spinner from '../common/Spinner';

class FeaturedProperties extends Component {
  componentDidMount() {
    this.props.getFeaturedProperties();
  }

  render() {
    const { featured, loading } = this.props.properties;

    let featuredProperties;

    if (loading) {
      featuredProperties = <Spinner />;
    } else {
      featuredProperties = featured.map(property => (
        <FeaturedProperty key={property._id} property={property} />
      ));
    }

    return (
      <section className="featured-properties">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 properties">
              <div className="header">
                <h3 className="heading">Featured Properties</h3>
                <hr />
              </div>

              <div className="row">
                {featuredProperties}

                <div className="d-md-none col-sm-6">
                  <div className="cta">
                    <h3 className="heading">
                      A one stop solution to your student accommodation needs
                    </h3>

                    <p>
                      We take all the fuss out of student accommodation. We
                      develop our properties with the modern student in mind.
                    </p>

                    <h3 className="call-us">
                      Call us on{' '}
                      <a href="tel: 01452886186">01452&nbsp;886186</a>
                    </h3>

                    <Link to="/contact" className="btn green-button">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 d-none d-md-block">
              <div className="right-sidebar">
                <h3 className="heading">
                  A one stop solution to your student accommodation needs
                </h3>

                <p>
                  We take all the fuss out of student accommodation. We develop
                  our properties with the modern student in mind. We also work
                  with approachable landlords to take the hassle out of private
                  student rental and provide you with a home from home.
                </p>

                <h3 className="call-us">
                  Call us on <a href="tel: 01452886186">01452&nbsp;886186</a>
                </h3>

                <Link to="/contact" className="btn green-button">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { getFeaturedProperties }
)(FeaturedProperties);
