import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  savePropertyToLocalStorage,
  deleteSavedProperty
} from '../../actions/propertyActions';
import Spinner from '../common/Spinner';
import isEmpty from '../../validation/is-empty';

class SaveProperty extends Component {
  constructor() {
    super();

    this.state = {
      saved: false
    };

    this.saveProperty = this.saveProperty.bind(this);
    this.deleteSavedProperty = this.deleteSavedProperty.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.properties.property)) {
      // turn local storage into array
      const storedProperties = JSON.parse(
        localStorage.getItem('savedProperties')
      );

      if (
        !isEmpty(storedProperties) &&
        storedProperties.includes(nextProps.properties.property._id.toString())
      ) {
        this.setState({ saved: true });
      } else {
        this.setState({ saved: false });
      }
    }
  }

  saveProperty() {
    // save property to local storage
    const { property } = this.props.properties;

    // get properties
    this.props.savePropertyToLocalStorage(property._id);

    this.setState({ saved: true });
  }

  deleteSavedProperty() {
    // save property to local storage
    const { property } = this.props.properties;

    this.props.deleteSavedProperty(property._id);

    this.setState({ saved: false });
  }

  render() {
    const { property, loading } = this.props.properties;
    let buttonContent;

    if (loading || isEmpty(property)) {
      buttonContent = <Spinner />;
    } else {
      if (!this.state.saved) {
        buttonContent = (
          <button className="full" onClick={this.saveProperty}>
            Save Property
          </button>
        );
      } else {
        buttonContent = (
          <button className="full" onClick={this.deleteSavedProperty}>
            Delete Saved Property
          </button>
        );
      }
    }

    return buttonContent;
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { savePropertyToLocalStorage, deleteSavedProperty }
)(SaveProperty);
