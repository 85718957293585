import {
  GET_UNPAID_PAYMENTS,
  GET_PAID_PAYMENTS,
  GET_PROCESSING_PAYMENTS,
  PAYMENTS_LOADING,
  GET_ALL_USERS_PAYMENTS,
  CREATE_NEW_PAYMENT,
  EDIT_PAYMENT,
  DELETE_PAYMENT,
  GET_ALL_PAYMENTS,
  GET_SINGLE_PAYMENT,
  CLEAR_USER_PAYMENTS,
  CLEAR_SINGLE_PAYMENT,
  GET_PAYMENTS_SUMMARY
} from '../actions/types';

const initialState = {
  unpaid: [],
  paid: [],
  processing: [],
  all: [],
  single: {},
  summary: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENTS_SUMMARY:
      return {
        ...state,
        summary: action.payload,
        loading: false
      };
    case GET_UNPAID_PAYMENTS:
      return {
        ...state,
        unpaid: action.payload,
        loading: false
      };
    case GET_PAID_PAYMENTS:
      return {
        ...state,
        paid: action.payload,
        loading: false
      };
    case GET_PROCESSING_PAYMENTS:
      return {
        ...state,
        processing: action.payload,
        loading: false
      };
    case GET_ALL_USERS_PAYMENTS:
      return {
        ...state,
        all: action.payload,
        loading: false
      };
    case CLEAR_USER_PAYMENTS: {
      return null;
    }
    case CREATE_NEW_PAYMENT:
      return {
        ...state,
        all: [action.payload, ...state.all]
      };
    case EDIT_PAYMENT:
      return {
        ...state,
        all: state.all.map(payment =>
          payment._id === action.payload._id ? action.payload : payment
        ),
        single: action.payload
      };
    case DELETE_PAYMENT:
      return {
        ...state,
        all: state.all.filter(payment => payment._id !== action.payload.payment)
      };
    case GET_ALL_PAYMENTS:
      return {
        ...state,
        all: action.payload,
        loading: false
      };
    case GET_SINGLE_PAYMENT:
      return {
        ...state,
        single: action.payload,
        loading: false
      };
    case CLEAR_SINGLE_PAYMENT:
      return {
        ...state,
        single: {}
      };
    case PAYMENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
