import React from "react";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";
import isEmpty from "../../validation/is-empty";
import YoutubeIframe from "./YoutubeIframe";

export default function FlatImages({ images, videoID }) {
  const [show, setShow] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [vidID, setVidID] = React.useState("");
  const [galleryImages, setGalleryImages] = React.useState([]);
  const [mediaCount, setMediaCount] = React.useState(0);

  const getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  React.useState(() => {
    const url = `https://www.youtube.com/watch?v=${videoID}`;
    const id = getId(url);
    if (!isEmpty(images)) {
      const temp = images.map((image) => {
        return {
          caption: image.caption,
          src: `/api/media/image/${image.key}`,
        };
      });

      setGalleryImages(temp);
    }

    if (!isEmpty(id)) {
      setVidID(id);
      setGalleryImages((prev) => [<YoutubeIframe videoID={id} />, ...prev]);
    }
  }, []);

  return (
    <div className="flat-images-wrapper">
      <button
        className="btn green-button"
        onClick={(e) => {
          e.preventDefault();
          setShow(!show);
        }}
      >
        <i className="fa fa-camera"></i> View Flat Media ({galleryImages.length}
        )
      </button>

      {show && (
        <Lightbox
          mainSrc={
            !isEmpty(galleryImages[photoIndex].src)
              ? galleryImages[photoIndex].src
              : galleryImages[photoIndex]
          }
          nextSrc={
            !isEmpty(galleryImages[(photoIndex + 1) % galleryImages.length].src)
              ? galleryImages[(photoIndex + 1) % galleryImages.length].src
              : galleryImages[(photoIndex + 1) % galleryImages.length]
          }
          prevSrc={
            !isEmpty(
              galleryImages[
                (photoIndex + galleryImages.length - 1) % galleryImages.length
              ].src
            )
              ? galleryImages[
                  (photoIndex + galleryImages.length - 1) % galleryImages.length
                ].src
              : galleryImages[
                  (photoIndex + galleryImages.length - 1) % galleryImages.length
                ]
          }
          onCloseRequest={() => setShow(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (prev) => (prev + galleryImages.length - 1) % galleryImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (prev) => (prev + galleryImages.length + 1) % galleryImages.length
            )
          }
          enableZoom={false}
          imagePadding={30}
          imageCaption={
            !isEmpty(galleryImages[photoIndex].caption)
              ? galleryImages[photoIndex].caption
              : null
          }
        />
      )}
    </div>
  );
}
