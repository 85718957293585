import React, { Component } from 'react';
import { connect } from 'react-redux';

// import components
import EditDocs from './EditDocs';

class UserDocs extends Component {
  render() {
    const { user } = this.props.users;

    return (
      <div className="admin-section">
        <div className="row">
          <div className="col-md-4">
            <div className="section-info">
              <h3 className="heading">Received / Completed Docs</h3>
              <p>A checklist for a users received / completed documents</p>
            </div>
          </div>

          <div className="col-md-8">
            <div className="section-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Tenancy Agreement</h4>
                    <p>
                      {user.completedDocs.tenancyAgreement ? (
                        <span className="text-success">Complete</span>
                      ) : (
                        <span className="text-danger">Incomplete</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Standing Order Mandate</h4>
                    <p>
                      {user.completedDocs.standingOrderMandate ? (
                        <span className="text-success">Complete</span>
                      ) : (
                        <span className="text-danger">Incomplete</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Payment Schedule</h4>
                    <p>
                      {user.completedDocs.paymentSchedule ? (
                        <span className="text-success">Complete</span>
                      ) : (
                        <span className="text-danger">Incomplete</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Personal Information</h4>
                    <p>
                      {user.completedDocs.personalInformation ? (
                        <span className="text-success">Complete</span>
                      ) : (
                        <span className="text-danger">Incomplete</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>DPS</h4>
                    <p>
                      {user.completedDocs.dps ? (
                        <span className="text-success">Complete</span>
                      ) : (
                        <span className="text-danger">Incomplete</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="meta-section">
                    <h4>Passport Photos</h4>
                    <p>
                      {user.completedDocs.passportPhoto ? (
                        <span className="text-success">Complete</span>
                      ) : (
                        <span className="text-danger">Incomplete</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-md-12">
                  <EditDocs />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users
});

export default connect(mapStateToProps)(UserDocs);
