import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteUser } from '../../actions/userActions';
import { Redirect } from 'react-router-dom';

class DeleteUser extends Component {
  constructor() {
    super();

    this.state = {
      deleted: false
    };

    this.onDelete = this.onDelete.bind(this);
  }

  onDelete(e) {
    e.preventDefault();
    this.props.deleteUser(this?.props?.user?._id);
    this.setState({ deleted: true });
  }

  render() {
    const { deleted } = this.state;

    if (deleted) {
      return <Redirect to="/admin-panel/users" />;
    }

    return (
      <div className="admin-section">
        <div className="header">
          <h3 className="heading">Delete User</h3>

          <p>
            <strong>WARNING:</strong> When deleting users you will also be
            deleting all data that the user is connected to. This includes,
            tenancies, tenant enuiries, their attached payments and resources
            and some minor data points. Only delete a user if you're absolutely
            sure! None of the deleted data can be recovered.
          </p>

          <button className="btn red-button" onClick={this.onDelete}>
            Permanently Delete User
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  users: state.users
});

export default connect(
  mapStateToProps,
  { deleteUser }
)(DeleteUser);
