import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AdminNavigation extends Component {
  render() {
    return (
      <div className="admin-navigation">
        <Link className="heading" to="/admin-panel">
          Admin Panel
        </Link>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#adminNavbar"
            aria-controls="adminNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="adminNavbar">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/admin-panel/properties">
                  <i className="fas fa-home" />
                  Manage Properties
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin-panel/users">
                  <i className="fas fa-users" />
                  Users
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin-panel/enquiries">
                  <i className="fas fa-envelope" />
                  Enquiries
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin-panel/tenants">
                  <i className="fas fa-bed" />
                  Tenants
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin-panel/tenant-enquiries">
                  <i className="fas fa-address-book" />
                  Tenant Enquiries
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin-panel/payments">
                  <i className="fas fa-wallet" />
                  Payments
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin-panel/payments-summary">
                  <i className="fas fa-list" />
                  Payments Summary
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default AdminNavigation;
