import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getUserUploadedResources,
  getUserForResources,
  adminDeleteResource,
  clearAllResources
} from '../../actions/resourcesActions';
import Moment from 'react-moment';
import axios from 'axios';

// import components
import { ReactComponent as FileIcon } from '../user-panel/file.svg';
import AdminUploadResource from './AdminUploadResource';
import isEmpty from '../../validation/is-empty';

class UserResources extends Component {
  componentDidMount() {
    this.props.getUserUploadedResources(this?.props?.user?._id);
    this.props.getUserForResources(this?.props?.user?._id);
  }

  componentWillUnmount() {
    this.props.clearAllResources();
  }

  getDownloadLink(key) {
    axios.get(`/api/media/resource-url/${key}`).then(res => {
      window.open(res.data.url);
    });
  }

  deleteResource = resourceId => {
    this.props.adminDeleteResource(resourceId, this?.props?.user?._id);
  };

  render() {
    const { forResources, uploadedResources } = this.props.resources;
    const { user } = this.props;

    return (
      <div className="admin-section">
        <div className="row">
          <div className="col-md-4">
            <div className="section-info">
              <h3 className="heading">Resource Center</h3>
              <p>
                These are the resources for the user and uploaded by the user.
              </p>
            </div>
          </div>

          <div className="col-md-8">
            <div className="section-content">
              <div className="sub-section">
                <div className="header">
                  <h4 className="heading">{user?.name }'s Uploaded Resources</h4>
                  <p>
                    Resources uploaded by {user?.name }, these can be deleted if
                    they're no longed required.
                  </p>
                </div>

                <div className="resources">
                  <ul className="resource-list">
                    {uploadedResources && !isEmpty(uploadedResources) ? (
                      uploadedResources.map(resource => (
                        <li key={resource._id}>
                          <button
                            className="delete-icon"
                            title="Delete Resource"
                            onClick={() => this.deleteResource(resource._id)}
                          >
                            <span className="fa-stack fa-2x">
                              <i className="fas fa-circle fa-stack-2x" />
                              <i className="fas fa-times fa-stack-1x fa-inverse" />
                            </span>
                          </button>
                          <button
                            onClick={() => this.getDownloadLink(resource.key)}
                            title={resource.name}
                          >
                            <FileIcon />
                            <p className="file-name">{resource.fileName}</p>
                            <p className="date">
                              <i className="far fa-clock" />
                              <Moment format="DD/MM/YYYY">
                                {resource.date}
                              </Moment>
                            </p>
                          </button>
                        </li>
                      ))
                    ) : (
                      <li className="no-content">No resources found...</li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="sub-section">
                <div className="header">
                  <h4 className="heading">Resources For {user?.name }</h4>
                  <p>
                    Resources uploaded for {user?.name } by Student Digs admins.
                  </p>
                </div>

                <div className="resources">
                  <ul className="resource-list">
                    {forResources && !isEmpty(forResources) ? (
                      forResources.map(resource => (
                        <li key={resource._id}>
                          <button
                            className="delete-icon"
                            title="Delete Resource"
                            onClick={() => this.deleteResource(resource._id)}
                          >
                            <span className="fa-stack fa-2x">
                              <i className="fas fa-circle fa-stack-2x" />
                              <i className="fas fa-times fa-stack-1x fa-inverse" />
                            </span>
                          </button>
                          <button
                            onClick={() => this.getDownloadLink(resource.key)}
                            title={resource.name}
                          >
                            <FileIcon />
                            <p className="file-name">{resource.fileName}</p>
                            <p className="date">
                              <i className="far fa-clock" />
                              <Moment format="DD/MM/YYYY">
                                {resource.date}
                              </Moment>
                            </p>
                          </button>
                        </li>
                      ))
                    ) : (
                      <li className="no-content">No resources found...</li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="sub-section">
                <AdminUploadResource />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.resources
});

export default connect(
  mapStateToProps,
  {
    getUserUploadedResources,
    getUserForResources,
    adminDeleteResource,
    clearAllResources
  }
)(UserResources);
