import React, { Component } from 'react';
import Spinner from '../common/Spinner';
import isEmpty from '../../validation/is-empty';

// import componetns

class MapTab extends Component {
  render() {
    const { property, loading } = this.props;
    let tabContent;

    if (loading || isEmpty(property)) {
      tabContent = <Spinner />;
    } else if (!isEmpty(property.address)) {
      tabContent = (
        <iframe
          src={`https://maps.google.com/maps?q=${property.name +
            ' ' +
            property.address.postcode &&
            property.address.postcode}&z=16&output=embed`}
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen
          title={`${property.name} Location`}
        />
      );
    }

    return (
      <div
        className="tab-pane fade"
        id="map"
        role="tabpanel"
        aria-labelledby="map-tab"
      >
        {tabContent}
      </div>
    );
  }
}

export default MapTab;
