import React, { Component } from "react";

class BlogPost extends Component {
  render() {
    const { post } = this.props;
    const { source_url } = this.props.post._embedded["wp:featuredmedia"][0];

    return (
      <div className="blog-post">
        <a href={post.link}>
          <div className="post-box">
            <div className="thumbnail-wrapper">
              <div
                className="thumbnail"
                style={{ backgroundImage: `url(${source_url})` }}
              />
            </div>

            <div className="content">
              <h3 className="heading">
                {post.title ? post.title.rendered : null}
              </h3>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: post.excerpt ? post.excerpt.rendered : null,
                }}
              />
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default BlogPost;
